import {ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {DataService} from 'src/app/_services/data.service';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {Audio, Training} from '../../_business';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
// import {LanguageIdEnum} from "../../_business/language.enum";
import {RecordingTipsModalComponent} from "./recording-tips-modal/recording-tips-modal.component";
import {SkipVoiceModalComponent} from "./skip-voice-modal/skip-voice-modal.component";
import {LeavingModalComponent} from "./leaving-modal/leaving-modal.component";
import {UploadingErrorModalComponent} from "./uploading-error-modal/uploading-error-modal.component";

import Plyr from 'plyr';
import {fullscreenOptions} from "../../_helpers/lib";
import {FinishTrainingModalComponent} from "./finish-training-modal/finish-training-modal.component";
import {TrainingRecordings} from "../../_business/training-session.model";
import {Observable, Subject} from "rxjs";
import {ComponentCanDeactivate} from "../../_services/pending-changes.guard";
import {environment} from "../../../environments/environment";
import {MixpanelService} from "../../_services/mixpanel.service";

declare var MediaRecorder: any;


@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.css']
})
export class TrainingComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

  public error: string;
  public loading = true;
  public step = 0;
  // public step = 4;

  public mediaRecorder: any;
  public chunks = [];
  public audio;
  public blob;
  // public translates;
  public time = 0;
  private timer = 0;
  private timeLimit = 5;
  public stepText: string;
  public stepTime = 0;
  private stepTimer = 0;
  private stepInterval = 5000;
  private ratio = 1;
  public videoPlayer: Plyr;
  public videoDuration: number;
  public uploadError = false;
  private back = false;
  public riveLoaded = 0;

  // public langId = 0;
  public title: string;

  private au: HTMLAudioElement;
  public success: boolean = false;
  public playing: boolean = false;
  public playerWidth = 300;

  // private recordings: TrainingRecordings = {
  public recordings: TrainingRecordings = {
    before: null,
    after: null
  };

  private modalTips: NgbModalRef;
  private modalSkipVoice: NgbModalRef;
  private modalLeaving: NgbModalRef;
  private modalUploadError: NgbModalRef;
  private modalFinish: NgbModalRef;

  @Input() public training: Training;

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away

    if (this.step < this.training.steps.length + 3 && !this.back) {
      this.showModalLeaving();
      return this.modalLeaving.componentInstance.response.asObservable();
    }

    return true;
  }

  constructor(
    private router: Router,
    private dataService: DataService,
    private titleService: Title,
    private metaService: Meta,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    // private dom: DomSanitizer,
    private modalService: NgbModal,
    private translate: TranslateService,
    public _location: Location,
    private _mixpanelService: MixpanelService,
  ) {
    // this.langId = LanguageIdEnum[this.translate.currentLang];

    // this.translate.get(['Тренировка прервана. Результат будет потерян.']).subscribe(
    //   res => {
    //     this.translates = res;
    //   }
    // );
  }

  ngOnInit(): void {
    if (!localStorage.getItem('doNotShowTips'))
      this.modalTips = this.modalService.open(
        RecordingTipsModalComponent,
        // { centered: true }
        { windowClass: 'modal-pop', centered: true }
      );

    if (this.training) {
      this.setMeta();
    } else {
      this.dataService.getTrainingById(+this.route.snapshot.paramMap.get('id')).subscribe({
        next: (training) => {
          this.training = training;
          this.setMeta();

          // this.modalFinish = this.modalService.open(
          //   FinishTrainingModalComponent,
          //   fullscreenOptions
          // );
          // this.modalFinish.componentInstance.training = this.training;
          //
          // this.recordings.before = './assets/test/700KB.mp3';
          // this.recordings.after = './assets/test/700KB.mp3';
          // this.recordings.skipped = true;
          // this.modalFinish.componentInstance.recordings = this.recordings;
        },
        error: (err: Error) => {
          // go to 4o4
          this.router.navigate(['/notfound'])
        }
      })
        .add(
          () => this.loading = false
        )
    }

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        console.log('stream', stream);
        this.mediaRecorder = new MediaRecorder(stream);

        this.mediaRecorder.onstart = e => {
          console.log('onstart', e)
          // this.time = 0;

          if (this.timer)
            clearInterval(this.timer);

          this.timer = setInterval(() => {
            this.time++;
            this.cd.detectChanges();
          }, 1000);
        }
        this.mediaRecorder.onstop = e => {
          console.log('data available after MediaRecorder.stop() called.');

          // this.blob = new Blob(this.chunks, {type: 'audio/ogg; codecs=opus'});
          this.blob = new Blob(this.chunks, {type: 'audio/mp4'});
          this.chunks = [];
          this.audio = URL.createObjectURL(this.blob);

          // const audioURL = URL.createObjectURL(this.blob);
          // console.log(audioURL);
          // // audio.src = audioURL;
          // this.audio = this.dom.bypassSecurityTrustUrl(audioURL);

          clearInterval(this.timer);
          this.success = this.time >= this.timeLimit;

          this._mixpanelService.track(
            'Voice Recorded', {
              duration: this.time,
            }
          );

          console.log('recorder stopped');
          this.cd.detectChanges();
        };
        this.mediaRecorder.ondataavailable = e => {
          console.log('ondataavailable', e)
          this.chunks.push(e.data);
        };
      })
      .catch(() => {
        this.error = 'Error capturing audio. Check the microphone.';
      });
  }

  ngOnDestroy(): void {
    this.modalTips && this.modalTips.dismiss('close');
    this.modalSkipVoice && this.modalSkipVoice.dismiss('close');
    this.modalLeaving && this.modalLeaving.dismiss('close');
    this.modalUploadError && this.modalUploadError.dismiss('close');
    this.modalFinish && this.modalFinish.dismiss('close');

    this.timer && clearInterval(this.timer);
    this.onPlayerPause();
  }

  public setMeta() {
    this.titleService.setTitle(this.training.name);
    this.metaService.addTags([
      {name: 'description', content: this.training.short_description},
    ]);

    this._mixpanelService.track(
      'Training Opened',
      {
        'id' : this.training.id,
        'name' : this.training.name,
      }
    );
  }

  public onRecording() {
    this.audio = null;

    console.log('onRecording', this.mediaRecorder.state);

    if (this.mediaRecorder.state === 'recording')
      this.mediaRecorder.stop();
    else {
      this.time = 0;
      this.mediaRecorder.start();
    }

    this.cd.detectChanges();
    // console.log(this.mediaRecorder.state);
  }

  public onPlay() {
    this.playing = true;
    this.au = new Audio(this.audio);
    this.au.onended = e => {
      this.playing = false;
    };
    this.au.play();
    this.cd.detectChanges();
  }

  public onStop() {
    console.log('stop')
    this.au.pause();
    this.playing = false;
    this.cd.detectChanges();
  }

  // public onDelete() {
  //   this.audio = null;
  //   this.cd.detectChanges();
  // }

  public onStep(step: number, skip = false) {
    if (this.mediaRecorder.state === 'recording')
      this.mediaRecorder.stop();

    const callback = () => {
      this.step += step;
      // this.cd.detectChanges();

      console.log('step', this.step - this.training.steps.length)

      switch (this.step - this.training.steps.length) {
        case 0:
          this.title = 'Record voice before training';
          break;

        case 1:
          this.title = 'Watch and repeat';

          if (!this.training.movieUrl)
            this.dataService.getVideo(this.training.video_url).subscribe(
              (data) => {
                this.training.movieUrl = data.url;
                this.onResize();
              }
            );
          break;

        case 2:
          this.title = 'Record voice after training';
          break;

        case 3:
          // this.title = 'Results';

          // setTimeout(() => {
          //   this.modalFinish = this.modalService.open(
          //     FinishTrainingModalComponent,
          //     fullscreenOptions
          //   );
          //   this.modalFinish.componentInstance.training = this.training;
          //   this.modalFinish.componentInstance.recordings = this.recordings;

            // document.querySelectorAll('.modal.fade').forEach(item => item.classList.add('show'));
          // }, 1000)

          break;
      }

      if (skip) {
        this.audio = null;
        return;
      }

      this.cd.detectChanges();

      if ([1, 3].includes(this.step - this.training.steps.length))
        this.uploadAudio();
    };

    if (skip && [0, 2].includes(this.step - this.training.steps.length)) {
      this.modalSkipVoice = this.modalService.open(
        SkipVoiceModalComponent,
        {centered: true, size: 'sm'}
      );
      this.modalSkipVoice.componentInstance.response.subscribe(() => {
        this.recordings.skipped = true;
        callback();
      });

      return;
    }

    callback();
  }

  private uploadAudio() {
    if (environment.debug) {
      setTimeout(() => {
        const data = JSON.parse('{"id":"5159319","created":"Сегодня в 13:06","audio":"https:\/\/d374128ohh1zhz.cloudfront.net\/audios\/25\/5159319_73cdafb1-cc20-825e-80f7-e056d9546bb3.mp3","exercise_id":null,"training_id":"124","course_id":null,"intensive_id":null,"length":"00:05","uid":"b5d58024432b4014","created_original":"2024-06-12 13:06:30","image":"https:\/\/d374128ohh1zhz.cloudfront.net\/audios\/25\/5159319_silent.png","image_active":"https:\/\/d374128ohh1zhz.cloudfront.net\/audios\/25\/5159319_active.png","image_passive":"https:\/\/d374128ohh1zhz.cloudfront.net\/audios\/25\/5159319_passive.png"}') as Audio;

        if (this.step - this.training.steps.length <= 1)
          this.recordings.uploadedBefore = data;
        else
          this.recordings.uploadedAfter = data;

        if (this.audio) {
          // if (!this.audioBefore)
          if (this.step - this.training.steps.length <= 1) {
            this.recordings.before = this.audio;
            this.recordings.beforeDuration = this.time;
          } else {
            this.recordings.after = this.audio;
            this.recordings.afterDuration = this.time;
          }

          this.audio = null;

          this.recordings = {...this.recordings};
          this.cd.detectChanges();
        }
      }, 1000);
      return;
    }



    this.dataService.getUploadToken(this.training.id).subscribe(
      (data) => {
        // data.audio = this.audio;
        // data.audio = this.dom.bypassSecurityTrustUrl(this.audio);
        data.audio = this.blob;

        this.dataService.upload(data).subscribe({
          next: (data: Audio) => {
            if (this.step - this.training.steps.length <= 1)
              this.recordings.uploadedBefore = data;
            else
              this.recordings.uploadedAfter = data;
          },
          error: () => {
            this.modalUploadError = this.modalService.open(
              UploadingErrorModalComponent,
              {windowClass: 'modal-pop', centered: true, backdrop: 'static'}
            );

            this.modalUploadError.componentInstance.response.subscribe(() => this.uploadAudio());

            this.modalUploadError.result.then((data) => {
              },
              (error) => {
                // on error/dismiss
                if (+error <= 1) {
                  this.onBack(true);
                  // this.recordings.skipped = true;
                }
              }
            );

            document.querySelectorAll('.modal-backdrop.fade, .modal-pop.fade').forEach(item => item.classList.add('show'));

            this.videoPlayer && this.videoPlayer.pause();
            this.uploadError = true;
            // this.cd.detectChanges();
          }
        }).add(() => {
          if (this.audio) {
            // if (!this.audioBefore)
            if (this.step - this.training.steps.length <= 1) {
              this.recordings.before = this.audio;
              this.recordings.beforeDuration = this.time;
            } else {
              this.recordings.after = this.audio;
              this.recordings.afterDuration = this.time;
            }

            this.audio = null;

            if (this.modalFinish)
              this.modalFinish.componentInstance.recordings = this.recordings;

            this.recordings = {...this.recordings};
            this.cd.detectChanges();
          }
        });
      }
    );
  }

  onPlayerInit(e: any) {
    this.videoPlayer = e;
    this.videoPlayer.volume = 1;
  }

  onPlayerLoaded(e: any) {
    // console.log('onPlayerLoaded', e.detail?.plyr?.ratio, e)
    // this.uploadError && this.player.pause();

    const r = e.detail.plyr.ratio.split(':');
    this.ratio = +r[1] / + r[0];
    this.onResize();
    this.videoDuration = this.videoPlayer.duration;
  }

  onPlayerStart() {
    console.log('onPlayerStart', this.uploadError)

    if (this.uploadError) {
      this.videoPlayer.pause();
      this.uploadError = false;
    } else if (this.training.steps?.length) {
      this.stepText = this.training.steps[this.stepTime].text;
      this.cd.detectChanges();

      clearInterval(this.stepTimer);
      this.stepTimer = setInterval(() => {
        this.stepTime = (this.stepTime + 1) % this.training.steps.length;
        this.stepText = this.training.steps[this.stepTime].text;
        this.cd.detectChanges();
      }, this.stepInterval);
    }
  }

  onVideoTime() {
    this.cd.detectChanges();
  }

  onPlayerPause() {
    this.stepTimer && clearInterval(this.stepTimer);
  }

  onPlayerEnd(e: any) {
    console.log('onPlayerEnd');

    this.onPlayerPause();
    // this.player.destroy();

    this.onStep(1);
    this.cd.detectChanges();
  }

  onResize() {
    // console.log('onResize', e)

    // const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    this.playerWidth = (h - 148) / this.ratio;
    this.cd.detectChanges();
  }

  onBack(force = false) {
    const callback = () => {
      this.back = true;
      // this._location.back();
      this.router.navigate(['/lessons']);
    };

    if (force || this.step >= this.training.steps.length + 3)
      callback();
    else {
      this.showModalLeaving();
      this.modalLeaving.componentInstance.response.subscribe(callback);
    }
  }

  private showModalLeaving = () => {
    this.modalLeaving = this.modalService.open(
      LeavingModalComponent,
      {windowClass: 'modal-pop', centered: true}
    );
  };

  onLoadRive(index: number) {
    this.riveLoaded = index;
    this.cd.detectChanges();
  }

  onRepeat() {
    this.audio = null;
    this.cd.detectChanges();
  }
}
