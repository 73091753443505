import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import {environment} from "../../environments/environment";
import packageJson from "../../../package.json";
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "./auth.service";
import {BehaviorSubject, filter, take} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class MixpanelService {

  private _mixpanel: BehaviorSubject<mixpanel.Mixpanel> = new BehaviorSubject(null);

  constructor(
    private auth: AuthService,
    private translate: TranslateService,
  ) {
    this.mixpanelInit()
      .then((object) => {
        console.log('Mixpanel initialized...');
        this._mixpanel.next(object);

        object.register({
          version: packageJson.version,
          lang: this.translate.currentLang,
        });

        if (this.auth.user)
          this.setUser();
      })
      .catch(err => console.warn(err))
    ;
  }

  mixpanelInit() : Promise<mixpanel.Mixpanel> {
    return new Promise(function (resolve, reject) {
      try {
        if (typeof(mixpanel) == "undefined")
          throw new Error("Mixpanel not Instanced");

        mixpanel.init(
          environment.mixpanelToken,
          {
            track_pageview: true,
            debug: environment.debug,
            loaded: (mixpanel) => {
              // console.log('loaded');
              resolve(mixpanel);
            },
          }
        );
      } catch (ex) {
        console.warn("Mixpanel ::  Error:", ex);
        reject(ex);
      }
    });
  }

  public setUser() : void {
    const user = this.auth.user;

    const func = (mixPanel: mixpanel.Mixpanel) : void => {
      mixPanel.identify(user.uid);

      mixPanel.people.set({
        'premium' : user.premium,
        'email' : user.email,
        'localization' : this.translate.currentLang,
      });
    }
    this._callNativeMethod(func);
  }

  public reset() : void {
    const func = (mixPanel: mixpanel.Mixpanel) : void => {
      mixPanel.reset();
    }
    this._callNativeMethod(func);
  }

  public setUserProperty(parameters?:any) : void {
    const func = (mixPanel: mixpanel.Mixpanel) : void => {
      mixPanel.people.set(parameters);
    }
    this._callNativeMethod(func);
  }

  public track(name: string, parameter?: any) : void {
    console.log('track', name, parameter)
    const func = (mixPanel: mixpanel.Mixpanel) : void => {
      mixPanel.track(name, parameter)
    }
    this._callNativeMethod(func);
  }

  private _callNativeMethod(func: (mixPanel: mixpanel.Mixpanel) => void) : void {
    this._mixpanel
      .pipe(
        filter(mixPanel => mixPanel !== null),
        take(1)
      )
      .subscribe((mixPanel: mixpanel.Mixpanel) => {
        func(mixPanel);
      });
  }
}
