import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {Tag, Training, User} from '../../_business';
import {DataService} from '../../_services/data.service';
import {InstallModalComponent} from '../parts/install-modal/install-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {faHeart, faHistory} from "@fortawesome/free-solid-svg-icons";
import {AuthService} from "../../_services/auth.service";
import {toFirstUp} from "../../_helpers/lib";


@Component({
  selector: 'app-lessons',
  templateUrl: './lessons.component.html',
  styleUrls: ['./lessons.component.css']
})
export class LessonsComponent implements OnInit {

  public loading = true;
  public error;
  public trainings: Training[] = [];
  public tags: Tag[] = [];
  public dataList: Training[] = [];
  public selectedTagId = 0;
  public selectedTag: Tag = null;
  public user: User = null;
  public countFavorites = 0;

  protected readonly fasHeart = faHeart;
  protected readonly fasHistory = faHistory;

  constructor(
    private auth: AuthService,
    private dataService: DataService,
    private titleService: Title,
    private metaService: Meta,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    // private cd: ChangeDetectorRef,
  ) {
    this.translate.get(['Page.Trainings.metatitle', 'Page.Trainings.subtitle']).subscribe(
      res => {
        this.titleService.setTitle(res['Page.Trainings.metatitle']);

        this.metaService.addTags([
          {name: 'description', content: res['Page.Trainings.subtitle']},
        ]);
      }
    );
  }

  ngOnInit(): void {
    this.user = this.auth.user;

    // const modalRef = this.modalService.open(
    //   InstallModalComponent,
    //   { backdrop: 'static', centered: true }
    // );

    this.route.queryParams.subscribe(params => {
      if (params.tag) {
        this.selectedTagId = params.tag;
      }
    });

    this.fetchData();
  }

  public fetchData() {
    this.getTrainings();
    // this.getTags();
  }

  // public getTags() {
  //   // this.loading = true;
  //   this.dataService.getTags().subscribe(
  //     (tags) => {
  //       this.tags = tags;
  //
  //       if (!this.selectedTagId)
  //         this.selectedTagId = this.tags[0].id;
  //     }
  //   );
  // }

  public getTrainings() {
    this.loading = true;
    this.dataService.getTrainings().subscribe(
      (trainings: Training[]) => {
        this.trainings = trainings;

        for (const train of this.trainings) {
          for (let y = 0; y < train.tags.length; y++) {
            const tag = train.tags[y];

            if (!this.tags.find(x => x.id === tag.id)) {
              this.tags.push(tag);
            }
          }

          if (train.favorited)
            this.countFavorites++;
        }

        this.tags = this.tags.sort((a, b) => a.position - b.position);

        if (!this.selectedTagId && this.tags.length)
          this.selectedTagId = this.tags[0].id;

        this.onSelectTag(this.selectedTagId);
      }
    )
      .add(() => this.loading = false);
  }

  public onSelectTag(id = null) {
    if (!id) {
      this.selectedTag = null;
      this.dataList = this.trainings;
      return;
    }

    this.selectedTagId = id;

    if (id < 0) {
      this.dataList = this.trainings.filter(({favorited}) => favorited);
      return;
    }

    this.dataList = [];

    for (const train of this.trainings) {
      const tag = train.tags.find(y => y.id === id);

      if (tag) {
        this.selectedTag = tag;
        this.dataList.push(train);
      }
    }

    // this.cd.detectChanges();
  }

  public toIntall() {
    // this.router.navigate(['/install']);
    const modalRef = this.modalService.open(
      InstallModalComponent,
      { centered: true }
    );
  }

  protected readonly toFirstUp = toFirstUp;

  onFavorite(favorited: boolean) {
    if (favorited)
      this.countFavorites++;
    else
      this.countFavorites--;

    if (this.selectedTagId < 0 && !this.countFavorites)
      this.selectedTagId = this.tags[0].id;

    this.onSelectTag(this.selectedTagId);
  }
}
