import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CountdownConfig} from 'ngx-countdown';
import {AuthService} from '../../_services/auth.service';
import {DataService} from '../../_services/data.service';
import {User} from '../../_business';
import { ActivatedRoute } from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {MixpanelService} from "../../_services/mixpanel.service";

const CountdownTimeUnits: Array<[string, number]> = [
  ['Y', 1000 * 60 * 60 * 24 * 365], // years
  ['M', 1000 * 60 * 60 * 24 * 30], // months
  ['D', 1000 * 60 * 60 * 24], // days
  ['H', 1000 * 60 * 60], // hours
  ['m', 1000 * 60], // minutes
  ['s', 1000], // seconds
  ['S', 1], // million seconds
];


@Component({
  selector: 'app-paywall',
  templateUrl: './paywall.component.html',
  styleUrls: ['./paywall.component.css']
})
export class PaywallComponent implements OnInit {

  // public loading = true;
  public error: string;
  // public managerLink: string = null; // "https://stripe.com";
  public user: User = null;
  public loading = false;

  // public subsciptionType : 'monthly'|'yearly' = 'monthly';
  public type = 'paywall'; // 'paywall'|'offer'|'cancel'
  public desktop = false;

  txt = ['d', 'h', 'm', 's'];
  config: CountdownConfig = {
    leftTime: 60 * 60 * 32,
    format: 'D:H:m:s',
    formatDate: ({date, formatStr}) => {
      let duration = Number(date || 0);

      return CountdownTimeUnits.reduce((current, [name, unit]) => {
        if (current.indexOf(name) !== -1) {
          const v = Math.floor(duration / unit);
          duration -= v * unit;
          return current.replace(new RegExp(`${name}+`, 'g'), (match: string) => {
            return v.toString().padStart(match.length, '0');
          });
        }
        return current;
      }, formatStr);
    },
    prettyText: (text) => {
      return text
        .split(':')
        .map((v, i) => `${i > 0 ? ' : ' : ''}${v}${this.txt[i]}`)
        .join('');
    },
  };

  options = [0, 0, 0, 0];

  tarifList = [
    // {
    //     id: 0,
    //     monthly: true,
    //     price: null,
    //     popular: false,
    //     timer: null,// this.config,
    //     options: [1, 0, 0, 0],
    //     active: false,
    //     subtitle: null,
    // }
  ];

  constructor(
    private dataService: DataService,
    private auth: AuthService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private cd: ChangeDetectorRef,
    private _mixpanelService: MixpanelService,
  ) {
    console.log(this.route.snapshot.paramMap.get('type'));

    this.type = this.route.snapshot.paramMap.get('type');
  }

  public ngOnInit(): void {
    this.user = this.auth.user;

    if (!this.user.premium) {
      this.loading = true;

      this._mixpanelService.track('Paywall View');

      this.dataService.getPricelist().subscribe({
        next: (pricelist: any[]) => {
          this.tarifList = pricelist;
          this.onResize();

          // pricelist.forEach(
          //   el => {
          //     if (el.name == 'monthly') {
          //       this.tarifList[1] = {
          //         id: 37,
          //         monthly: true,
          //         price: +el.price + ' ' + el.currency,
          //         popular: false,
          //         timer: null,// this.config,
          //         options: [1, 1, 1, 1,],
          //         active: false,
          //         subtitle: null,
          //         };
          //     } else if (el.name == 'annual') {
          //       let monthPrice = el.price / 12;
          //       monthPrice = Math.round((monthPrice + Number.EPSILON) * 100) / 100;
          //       this.tarifList[2] = {
          //         id: 42,
          //         monthly: false,
          //         subtitle: '60%',
          //         price: +monthPrice + ' ' + el.currency,
          //         popular: true,
          //         timer: null,
          //         active: true,
          //         options: [1, 1, 1, 1],
          //       };
          //     }
          //
          //   }
          // )
        },
        error: (err) => console.log('pricelist err', err)
      }).add(() => this.loading = false);

    }
  }

  public handleEvent(event) {

  }

  public goToPayment(selectedItem = null) {
    if (!selectedItem)
      selectedItem = this.tarifList.find(({selected}) => selected);

    if (!selectedItem)
        return;

    this._mixpanelService.track(
      'Purchase', {
        id: selectedItem.id,
        Subscription: selectedItem.period
      }
    );

    this.dataService.getPaymentLink(selectedItem.id).subscribe({
      next: (paymentLink: any) => window.location.href = paymentLink.url,
      error: (err) => console.error('payment link err', err)
    });
  }

  public switchActive(tarif) {
    for (let x = 0; x < this.tarifList.length; x++) {
      this.tarifList[x].selected = false;
    }

    tarif.selected = true;
  }

  protected readonly Math = Math;

  onResize() {
    // console.log('onResize', e)

    const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    // const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    if (!this.desktop && w > 991) {
      this.desktop = true;

      const item = this.tarifList.find(({period}) => period == '3month');
      item && this.switchActive(item);
    }
    // else if (this.desktop) {
    //   this.desktop = false;
    // }

    this.cd.detectChanges();
  }
}
