import {EventEmitter, Injectable, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Injectable()
export class DataForm implements OnInit {

  dataForm: FormGroup;
  loading = false;
  error = '';
  success = false;
  isAddedValidators = true;
  validators: Record<string, any> = {};

  @Input() submitted = false;
  @Input() obj: any;
  // @Output() onResponse = new EventEmitter();

  constructor(
    public formBuilder: FormBuilder,
  ) { /* */
  }

  ngOnInit() {
    this.initForm();

    // if (this.dataForm) {
    //   this.subscribeForm();
    // }

  }


  // subscribeForm() {
  //   this.dataForm.valueChanges.subscribe(() => {
  //
  //     if (!this.isAddedValidators) {
  //       this.isAddedValidators = true;
  //       Object.keys(this.dataForm.controls).forEach(field => {
  //         const control = this.dataForm.get(field);
  //
  //         if (control instanceof FormControl) {
  //           this.updateControlValidators(field, control);
  //         } else if (control instanceof FormArray) {
  //         }
  //       });
  //     }
  //
  //     this.onResponse.emit({
  //       valid: this.dataForm.valid,
  //       data: this.dataForm.value
  //     });
  //   });
  // }

  updateControlValidators(field: string, control: FormControl) {
    const validators = this.validators[field] ? this.validators[field] : [Validators.required];

    if (validators.length) {
      control.setValidators(validators);
      control.updateValueAndValidity();
    }
  }

  initForm(): void {
    console.error('Implements initForm');
  }

  createItem(): FormGroup {
    console.error('Implements createItem');
    return this.formBuilder.group({});
  }

  get f() {
    return this.dataForm.controls;
  }

  onStartSubmit(): boolean {
    this.submitted = true;

    // stop here if form is invalid
    if (this.dataForm.invalid || this.loading) {
      return false;
    }

    this.loading = true;
    this.error = '';

    return true;
  }

  onStopSubmit() {
    this.submitted = false;
    this.loading = false;
  }

}
