  <app-loading *ngIf="loading"/>

  <div *ngIf="!loading">
    <h3 class="mb-0">
      <a routerLink="/lessons" class="float-end subtitle mt-2" style="font-size: 16px;">{{ 'See all' | translate }}</a>

      <img src="/assets/img/icons/union.svg" class="i" />
      {{ 'Personal plan' | translate }}
    </h3>
    <p class="subtitle">{{ subtitle | translate }}</p>

    <app-trainings [list]="plan"></app-trainings>
  </div>

  <div *ngIf="error" class="alert alert-danger">{{ error | translate }}</div>
