import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import * as Plyr from 'plyr';


declare global {
  interface Window {
    playerBefore: Plyr;
    playerAfter: Plyr;
  }
}
export {};

// declare global {
//   interface Window {
//     playerBefore: HTMLAudioElement;
//     playerAfter: HTMLAudioElement;
//   }
// }
// export {};


@Component({
  selector: 'app-audio-training',
  templateUrl: './audio-training.component.html',
  styleUrls: ['./audio-training.component.css']
})
export class AudioTrainingComponent implements OnInit, OnDestroy {

  @Input() public audio: any;
  @Input() public before = false;
  @Input() public duration: number;

  @Output() response = new EventEmitter();

  public player: Plyr;
  // public player: HTMLAudioElement;
  // public playing: boolean = false;

  constructor(
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    console.log('audio', this.audio)
  }

  ngOnDestroy() {
    window.playerBefore = null;
    window.playerAfter = null;
  }

  onPlayerInit(player: Plyr) {
    this.player = player;
    this.player.volume = 1;
    this.cd.detectChanges();

    if (this.before)
      window.playerBefore = this.player;
    else
      window.playerAfter = this.player;

    this.response && this.response.next(true);
  }

  onPlay() {
    this.player.play();
    // this.duration = 0;
    this.cd.detectChanges();

    if (this.before && window.playerAfter && !window.playerAfter.paused)
      window.playerAfter.pause();
    else if (!this.before && window.playerBefore && !window.playerBefore.paused)
      window.playerBefore.pause();
  }

  onTime() {
    this.cd.detectChanges();
  }

  onEnd() {
    this.duration = 0;
    this.cd.detectChanges();
  }





  //
  // onPlay() {
  //   const init = !this.player;
  //
  //   this.player = new Audio(this.audio);
  //
  //   // this.player.addEventListener("durationchange", function (e) {
  //   //   if (this.duration != Infinity) {
  //   //     var duration = this.duration
  //   //     next(duration);
  //   //   };
  //   // }, false);
  //   // this.player.ondurationchange = e => {
  //   //   console.log('duration', e);
  //   // };
  //   // this.player.load();
  //
  //   this.player.onended = e => {
  //     // this.playing = false;
  //     this.duration = this.player.currentTime;
  //     this.cd.detectChanges();
  //   };
  //   this.player.play().then(r => this.cd.detectChanges());
  //
  //   if (init) {
  //     if (this.before)
  //       window.playerBefore = this.player;
  //     else
  //       window.playerAfter = this.player;
  //
  //     this.response && this.response.next(true);
  //   } else {
  //     if (this.before && window.playerAfter && !window.playerAfter.paused)
  //       window.playerAfter.pause();
  //     else if (!this.before && window.playerBefore && !window.playerBefore.paused)
  //       window.playerBefore.pause();
  //   }
  // }
}
