import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataService} from '../../../_services/data.service';
import {InstallModalComponent} from '../../parts/install-modal/install-modal.component';
import {Training} from '../../../_business';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.css']
})
export class PlanComponent implements OnInit {

  public loading = true;
  public error: string;
  public plan: any[];

  @Input() public subtitle = 'Смотри и повторяй';
  @Output() response = new EventEmitter();

  constructor(
    private dataService: DataService,
    private modalService: NgbModal,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.dataService.getStatisticPlan().subscribe(
      (data: any[]) => {
        this.plan = data;
        this.loading = false;
        this.response.emit({ length: this.plan.length });
      },
      err => {
        this.error = err.error;
        this.loading = false;
      }
    );
  }

  public goToTraining(training: Training) {
    if (training.paid) {
      this.router.navigate(['/subscribe']);
    } else
      this.toIntall();
      // this.router.navigate(['/lessons', 'view', training.id]);
    // [routerLink]="['/lessons', 'view', train.id]" *ngIf="!train.paid"
  }

  public toIntall() {
    const modalRef = this.modalService.open(
      InstallModalComponent,
      {centered: true}
    );
  }

  public onFavourite(training: Training) {
    this.toIntall();
    // training.favorited = !training.favorited;
  }
}
