import {Component, Input, OnInit} from '@angular/core';
// import {Meta, Title} from '@angular/platform-browser';
import {DataService} from 'src/app/_services/data.service';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

import Highcharts from 'highcharts/es-modules/masters/highcharts.src';
// import * as Highcharts from 'highcharts';

import {TranslateService} from "@ngx-translate/core";
import {AnalysisStat} from "../../../_business/analysis-stat.model";

@Component({
  selector: 'app-progress-details-modal',
  templateUrl: './progress-details-modal.component.html',
  styleUrls: ['./progress-details-modal.component.css']
})
export class ProgressDetailsModalComponent implements OnInit {

  @Input() item: AnalysisStat;

  public movieUrl: string;
  public days: 10|30|90 = 10;
  public Highcharts: typeof Highcharts = Highcharts;

  constructor(
    private dataService: DataService,
    // private titleService: Title,
    // private metaService: Meta,
    public activeModal: NgbActiveModal,
    public translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    // this.titleService.setTitle(this.item.name);
    // this.metaService.addTags([
    //   {name: 'description', content: this.item.short_description},
    // ]);

    delete this.item.options.chart.width;
    delete this.item.options.chart.height;

    // this.getDetails();
    const chart = Highcharts.chart('plot', this.item.options);
  }

  getDetails() {
    this.dataService.getMemberAnalysisStatById(this.item.id, this.days).subscribe(
      (data) => {
        let d = [], names = [];

        if (data.results?.length) {
          for (const r of data.results) {
            names.push(r.x);
            d.push(r.y);
          }
        } else {
          d = Array(this.days).fill(0);

          const date = new Date();

          for (let i = 0; i < this.days; i++) {
            date.setDate(
              date.getDate() - 1
            );
            names.push(
              date.toLocaleDateString(this.translate.currentLang, {month: "short", day: "numeric"})
            );
          }

          names.reverse();
        }

        this.item.options = {...this.item.options};

        const xAxis = this.item.options.xAxis as any;
        xAxis.categories = names;
        xAxis.max = names.length - 1;

        // d[1] = 78;

        // this.item.proc = Math.max(...d);
        this.item.proc = d.reduce((a, b) => (a + b)) / d.length;

        if (this.item.options.series[0].type === 'area')
          this.item.options.series[0].data = d;

// console.log(this.item)

        // this.bars = data;
        // // this.totalExperience = data.length * 100;
        //
        // let amount = 0;
        // const series = this.chartOptionsPlot.series[0] as any;
        //
        // for (const obj of data) {
        //   this.totalExperience += +obj.total;
        //   amount += +obj.points;
        //   series.data.push(
        //     [ obj.name, +obj.points ]
        //   );
        // }
        //

        // series.data.push({
        //   name: this.translations.Осталось,
        //   className: 'other',
        //   y: (this.totalExperience - amount)
        // });
        //
        // series.name = this.translations.Прогресс;
        // this.chartOptionsPlot.title.text =
        //   '<tspan style="font-size: 64px; color: #fff;">' + amount + '</tspan><br>' +
        //   '<tspan dy="10px">' + this.translations.Опыт + '</tspan>';

        const chart = Highcharts.chart('plot', this.item.options);
      }
    );
  }

  onDays(item: 10|30|90) {
    if (this.days == item)
      return;

    this.days = item;
    this.getDetails();
  }
}
