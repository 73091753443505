import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NgOptimizedImage} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {RiveCanvas, RiveStateMachine} from "ng-rive";

@Component({
  selector: 'app-leaving-modal',
  templateUrl: './leaving-modal.component.html',
  standalone: true,
  imports: [
    NgOptimizedImage,
    TranslateModule,
    RiveCanvas,
    RiveStateMachine
  ],
  styleUrls: ['./leaving-modal.component.css']
})
export class LeavingModalComponent implements OnInit {

  @Output() response = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
  }

  onExit() {
    this.activeModal.dismiss('Cross click');
    this.response.next(true);
  }
}
