<div class="modal-header">
  <button class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body text-center py-0">
  <p>
    <app-icon icon="micro-off" size="lg" class="text-primary"/>
  </p>
  <pre class="p-0 mb-0" translate>
    Please note:

    if you skip the voice recording, we will not be able to provide you with voice analyses, personalized feedback, or a personal plan tailored to your goals.

    Are you sure you want to skip?
  </pre>
</div>
<div class="modal-footer gap-3 flex-nowrap">
  <button class="btn btn-md btn-default subtitle fw-normal w-50" (click)="activeModal.dismiss('Cross click')" translate>Cancel</button>
  <button class="btn btn-md btn-primary w-50" (click)="onSuccess()" translate>OK</button>
</div>
