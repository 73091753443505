import {AfterContentInit, Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[badge]'
})
export class BadgeDirective implements OnInit, AfterContentInit {

  @Input() level: 'low'|'high';

  constructor(private el: ElementRef) {
    // this.el.nativeElement.style.backgroundColor = 'yellow';
  }

  ngOnInit() {
    const el = this.el.nativeElement;
    el.classList.add('badge');
    el.classList.add('bg-secondary');

    if (!this.level)
      el.classList.add('subtitle');
  }

  ngAfterContentInit(): void {
    const el = this.el.nativeElement;
    const value = +el.innerHTML.replace('%', '');

    if (value > 0 && value < 100)
      el.innerHTML = '+' + el.innerHTML;

    if (this.level) {
      if (!value)
        el.classList.add('subtitle');
      else if (
        this.level === 'high' && value > 0 ||
        this.level === 'low' && value < 0
      )
        el.classList.add('text-success');
    }
  }

}
