<div class="container" *ngIf="loading">
  <img width="50" height="50" src="/assets/img/loader.gif" alt=""/>
</div>

<div class="container" *ngIf="result && !loading">
  <h2>{{ 'Your e-mail confirmed!' | translate }}</h2>
  <div class="form-block">
    <div class="header page-info">
      <p class="mb-4 subtitle">{{ 'Thank you!' | translate }}</p>
<!--      <p>{{ 'Page.Confirm.fraze1' | translate }}</p>
      <hr/>
      <p>{{ 'Page.Confirm.fraze2' | translate }}</p>
      <hr/>
      <p>{{ 'Page.Confirm.fraze3' | translate }}</p>
      <hr/>
      <p class="mb-4">{{ 'Page.Confirm.fraze4' | translate }}</p>-->
    </div>
    <p><a href="#" (click)="install()" class="btn btn-primary w-100">{{ 'Page.Confirm.button' | translate }}</a></p>
    <app-download></app-download>
  </div>
</div>

<div class="container" *ngIf="!result && !loading">
  <h2>{{ 'Page.Confirm.negativeTitle' | translate }}</h2>
  <div class="form-block">
    <div class="header page-info">
      <p>{{ 'Page.Confirm.negativeText' | translate }}</p>
    </div>
    <p><a href="#" (click)="install()" class="btn btn-primary w-100">{{ 'Page.Confirm.button' | translate }}</a></p>
    <app-download></app-download>
  </div>
</div>

<!--<div *ngIf="error" class="alert alert-danger">{{ error | translate }}</div>-->
