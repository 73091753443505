import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {DataForm} from '../../_helpers/dataForm';
import {AuthService} from '../../_services/auth.service';
import {DataService} from '../../_services/data.service';
import {Settings, User} from '../../_business';
import {LanguageEnum} from '../../_business/language.enum';
import {TranslateService} from '@ngx-translate/core';
import {environment} from "../../../environments/environment";
import {EmailNotificationSettings} from "../../_business/email-notification-settings.model";
import {Subscription} from "rxjs";
import {ConfirmModalComponent} from "../parts/confirm-modal/confirm-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent extends DataForm implements OnInit, OnDestroy {

  // public goalEnum = GoalEnum;
  // public genderEnum = GenderEnum;
  public languageEnum = LanguageEnum;
  public readonly WEB: string = environment.web;
  public user: User = null;
  private userSubscription: Subscription = null;

  dataFormNotify: FormGroup;
  loadingNotify = false;

  constructor(
    public translate: TranslateService,
    private dataService: DataService,
    private auth: AuthService,
    public formBuilder: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
  ) {
    super(formBuilder);
  }

  public initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        // email: [ null, [ Validators.required, Validators.email ]],
        name: '',
        // goal: '',
        // gender: '',
        // schedule_time: '',
        // notification_on: false
      });

    // this.dataForm.get('email').disable();

    this.dataFormNotify =
      this.formBuilder.group({
        marketing: false,
        product_updates: false,
      });
  }

  ngOnInit(): void {
    this.user = this.auth.user;
    this.userSubscription = this.auth.getCurrentUser().subscribe((user: User) => this.user = user);

    this.dataService.getSettings().subscribe({
      next: (settings: Settings) => {
        // settings.goal = settings.goal || '';
        // settings.gender = settings.gender || '';
        this.dataForm.patchValue(settings);

        this.dataForm.valueChanges.subscribe(() => {
          console.log('subscribe', this.dataForm.valid);
          if (this.dataForm.valid) {
            this.onSubmit();
          }
        });
      },
      error: err => {
        this.error = err.error;
      }
    });

    this.dataService.getEmailNotificationSettings().subscribe({
      next: (settings: EmailNotificationSettings) => {
        this.dataFormNotify.patchValue(settings);

        this.dataFormNotify.valueChanges.subscribe(() => {
          console.log('subscribe', this.dataFormNotify.valid);
          if (this.dataFormNotify.valid) {
            this.onSubmitNotify();
          }
        });
      },
      error: err => {
        this.error = err.error;
      }
    });

    super.ngOnInit();
  }

  public onSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    const value = this.dataForm.value;
    // value.goal = value.goal || null;
    // value.gender = value.gender || null;
    this.dataService
      .setSettings(value)
      .subscribe({
        next: (settings: Settings) => {
          this.onStopSubmit();
          this.success = true;
          this.auth.user = {...this.user, ...value};
        },
        error: err => {
          this.onStopSubmit();
          this.error = err.error;
        }
      });
  }

  public onSubmitNotify() {
    if (this.loadingNotify)
      return;

    this.loadingNotify = true;
    this.dataService
      .setEmailNotificationSettings(this.dataFormNotify.value)
      .subscribe({
        next: () => {
          this.loadingNotify = false;
        },
        error: err => {
          this.loadingNotify = false;
          this.error = err.error;
        }
      });
  }

  public changeLang(key) {
    console.log('changeLang', key);
    this.translate.use(key);
  }

  onLogout() {
    const modalConfirmRef = this.modalService.open(
      ConfirmModalComponent,
      // confirmOptions
      { size: 'sm', centered: true, backdrop: 'static' }
    );
    modalConfirmRef.componentInstance.icon = 'user';
    modalConfirmRef.componentInstance.message = 'Are you sure you want to Log Out?';
    modalConfirmRef.componentInstance.response.subscribe(($e) => {
      this.auth.logout();
      this.router.navigate(['/auth']);
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }
}
