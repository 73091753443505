import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DataService} from '../../_services/data.service';
import {Statistics} from '../../_business/statistics.model';

import Highcharts from 'highcharts/es-modules/masters/highcharts.src';
import 'highcharts/es-modules/masters/highcharts-more.src';
// import * as Highcharts from 'highcharts';
// import more from 'highcharts/highcharts-more';
// more(Highcharts);

@Component({
  selector: 'app-coach',
  templateUrl: './coach.component.html',
  styleUrls: ['./coach.component.css']
})
export class CoachComponent implements OnInit {

  private translations;
  private Highcharts: typeof Highcharts = Highcharts;

  // public loading = true;
  public error: string;

  public spider: Statistics[];

  // public chartOptionsSpider: Highcharts.Options = {
  public chartOptionsSpider: any = {
    accessibility: {
      enabled: false
    },
    chart: {
      width: 500,
      polar: true,
      type: 'line',
      backgroundColor: 'transparent',
      zoomType: 'xy',
      zoomKey: 'z',

      scrollablePlotArea: {
        minHeight: 500,
        minWidth: 500
      }
    },
    credits: {
      enabled: false
    },
    title: {
      text: '',
    },
    pane: {
      size: '90%'
    },
    xAxis: {
      labels: {
        reserveSpace: true,
        style: {
          fontSize:'14px',
          color: '#EEEEEE'
        }
      },
      categories: [],
      tickmarkPlacement: 'on',
      lineWidth: 0
    },
    yAxis: {
      gridLineInterpolation: 'polygon',
      tickInterval: 20,
      // minorTickInterval: 20,
      lineWidth: 0,
      min: 0,
      max: 100
    },
    legend: {
      enabled: false
    },
    series: [
      {
        color: '#4d5ebf',
        type: 'area',
        data: [],
        pointPlacement: 'on'
      }
    ],
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            align: 'center',
            verticalAlign: 'bottom'
          },
          pane: {
            size: '70%'
          }
        }
      }]
    }
  };

  constructor(
    private dataService: DataService,
    private translate: TranslateService,
  ) {
    this.translate.get(['Progress']).subscribe(translations => {
      console.log(translations);
      this.translations = translations;
    });
  }

  ngOnInit(): void {
    // const modalRef = this.modalService.open(
    //   InstallModalComponent,
    //   { backdrop: 'static', centered: true }
    // );

    this.dataService.getStatisticSpider().subscribe(
      (data: Statistics[]) => {
        this.spider = data;
        const series = this.chartOptionsSpider.series[0] as any;

        for (const obj of data) {
          this.chartOptionsSpider.xAxis.categories.push(obj.name);
          series.data.push(+obj.total);
        }

        series.name = this.translations.Progress;
        const chart = Highcharts.chart('spider', this.chartOptionsSpider);
      },
      err => {
        this.error = err.error;

      }
    );
  }
}
