import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {DataForm} from '../../_helpers/dataForm';
import {DataService} from '../../_services/data.service';
import {install} from "../../_helpers/lib";

@Component({
  selector: 'app-special',
  templateUrl: './special.component.html',
  styleUrls: ['./special.component.css']
})
export class SpecialComponent extends DataForm implements OnInit {

  public success: boolean = false;
  public cancel: boolean = false;
  public partner: string = null;

  public title = 'Page.Special.title';
  public fraze = 'Page.Special.fraze1';
  public placeholder: string = null;
  public submit = 'Page.Special.create';
  public cancelTxt: string = null;
  public price = null;
  public card = '/assets/img/card_en.png';

  constructor(
    public translate: TranslateService,
    private route: ActivatedRoute,
    private dataService: DataService,
    public formBuilder: FormBuilder,
    private titleService: Title,
  ) {
    super(formBuilder);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setMeta();
      this.card = event.lang == 'ru' ? '/assets/img/card_ru.png'   : '/assets/img/card_en.png';
    });

    this._parseParams();
    console.log(this.partner);
  }

  public initForm(): void {

    this.dataForm =
      this.formBuilder.group({
        email: [
          null, [
            Validators.required,
            Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,6}$'),
          ]],
      });

  }

  private _parseParams() {

    switch (this.route.snapshot.paramMap.get('paymentResult')) {
      case 'success' :
        this.success = true;
        break;
      case 'cancel' :
        this.cancel = true;
        break;
    }

    this.partner = this.route.snapshot.paramMap.get('partner');

    switch (this.partner) {
      case 'enotnevret' :
        this.title = 'Page.Special.title_enotnevret';
        break;

      case 'irenari' :
        this.title = 'Page.Special.title_irena';
        break;

      case 'vocalimage' :
        this.title = 'Page.Special.title_vocalimage';
        break;
      case 'rainbow' :
        this.title = 'Page.Special.title_rainbow';
        this.title = 'Page.Special.title_vocalimage';
        break;

      case 'ny2022' :

        this.title = 'Page.Special.title_2022';
        this.fraze = 'Page.Special.fraze_2022';
        this.placeholder = 'E-mail';
        this.submit = 'Page.Special.submit';
        this.cancelTxt = 'Page.Special.cancel_subscription';

        this.dataService.getPricelist().subscribe(
          (data) => {
            for (const obj of data) {
              if (obj.name === 'annual') {
                this.price = obj;
                break;
              }
            }
          }
        );

        break;
    }


  }

  public onRetry() {

    this.cancel = false;
    this.error = null;

    return;
  }

  public onSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    // this.error = 'as';
    // return;

    // check confirm

    const email = this.f.email.value.trim();

    if (email) {

      this.dataService
        .getPartnerOfferLink(this.partner, email, this.translate.currentLang).subscribe({
          next: (link: any) => {
            this.onStopSubmit();
            window.location.href = link.url;
          },
          error: err => {
            this.error = err.error;
            this.loading = false;
          }
        })
        .add(() => {

        });
    }
  }

  private setMeta() {

    this.translate.get('Page.Special.title').subscribe(
      (res: string) => {
        this.titleService.setTitle(res);
      }
    );

  }

  protected readonly install = install;
}
