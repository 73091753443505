import {Component, OnInit, ViewChild} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
// import {PlyrComponent} from 'ngx-plyr';
import Plyr from 'plyr';


import {Audio} from '../../_business';
import {DataService} from '../../_services/data.service';

@Component({
  selector: 'app-audio-share',
  templateUrl: './audio-share.component.html',
  styleUrls: ['./audio-share.component.css']
})
export class AudioShareComponent implements OnInit {

  public audio: Audio = null;
  public loading = true;
  private uid: string = null;
  public audioReady = false;

  // get the component instance to have access to plyr instance
  // @ViewChild(PlyrComponent, {static: true})
  // plyr: PlyrComponent;

  // or get it from plyrInit event
  player: Plyr;

  audioSources = [
    {
      src: null,//'https://cdn.plyr.io/static/demo/Kishi_Bashi_-_It_All_Began_With_a_Burst.mp3',
      type: 'audio/mp3',
    },
  ];


  constructor(
    public translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private titleService: Title,
    private metaService: Meta,
  ) {
  }

  ngOnInit(): void {

    this.uid = this.route.snapshot.paramMap.get('uid');
    this.getAudio();

  }

  public getAudio() {

    this.audio = null;
    this.loading = true;

    if (this.uid) {
      this.dataService.getAudioByUid(this.uid).subscribe((audio: Audio) => {
          this.audio = audio;
          // 'https://vocalimage-cdn.bessia.net/audios/14641/21902_6031381e19ab4.mp4';
          this.audioSources[0].src = audio.audio;
          this.audioReady = true;
        },
        (err) => {
          this.router.navigate(['/404']);
        })
        .add(() => this.loading = false);
    } else
      this.router.navigate(['/404']);
  }

  // public played(event: Plyr.PlyrEvent) {
  //   console.log('played', event);
  // }

}
