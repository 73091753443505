import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DataService} from '../../_services/data.service';

@Component({
  selector: 'app-special-offer-page',
  templateUrl: './special-offer-page.component.html',
  styleUrls: ['./special-offer-page.component.css']
})
export class SpecialOfferPageComponent implements OnInit {

  public result = false;
  public loading = true;

  constructor(
    public translate: TranslateService,
    private route: ActivatedRoute,
    private dataService: DataService,
  ) {
    this._parseParams();
  }

  ngOnInit(): void {
  }

  private _parseParams() {

    const memberId = this.route.snapshot.paramMap.get('memberId');
    const code = this.route.snapshot.paramMap.get('code');
    const coupon = this.route.snapshot.paramMap.get('coupon');

    if (memberId && code) {
      this.dataService.getSpecialOfferLink(memberId, code, coupon).subscribe(
        (paymentLink: any) => {
          window.location.href = paymentLink.url;
          this.result = true;
        },
        (err) => console.log(err)
      )
        .add(() => this.loading = false)
    } else
      this.loading = false;

  }
}
