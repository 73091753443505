<div class="container">

  <app-graphs-tooltip [item]="item"/>

  <!--  <button role="button" class="btn btn-lg btn-close" (click)="activeModal.dismiss('Cross click')"></button>-->
  <h1 class="d-flex align-items-center">
    <button class="btn p-0 me-3 subtitle" (click)="activeModal.dismiss('Cross click')"><app-icon icon="left"/></button>
    {{ item.name }}
  </h1>

  <div class="panel-info mb-4 d-lg-none">
    <strong>{{item.extended_title}}</strong>
    <small class="subheader">{{item.extended_description}}</small>
  </div>

  <div class="d-flex gap-3 mb-4">
    <button *ngFor="let item of [10, 30, 90]" class="btn btn-sm" [ngClass]="{'btn-light': days == item, 'btn-secondary': days != item}"
            (click)="onDays(item)"
    >
      {{ "Params.days" | translate: { number: item } }}
    </button>
  </div>

  <div class="d-lg-flex gap-lg-4">

    <div class="card w-100">
      <div class="card-body p-4">
        <label class=".h4">{{item.name}}</label>
        <p class="mb-3 d-flex align-items-center justify-content-between">
          <span class="note">{{item.description}}</span>
          <strong *ngIf="item.proc" class="small">
            <span class="me-2" [ngClass]="{'text-success': item.proc >= 75, 'text-warning': item.proc >= 50 && item.proc < 75, 'text-danger': item.proc < 50}">{{
              item.proc > 66 ? 'HIGH' : (item.proc >= 33 ? 'MEDIUM' : 'LOW')
              }}</span><span>{{item.proc | number: '1.0-0'}}%</span>
          </strong>
        </p>
        <div class="position-relative">
          <div *ngIf="!item.proc" class="panel panel-note" translate>
            Not enough data. Record your voice during the practice to enable progress analysis.
          </div>
          <div id="plot"></div>
    <!--      <highcharts-chart [Highcharts]="Highcharts" [options]="item.options"></highcharts-chart>-->
        </div>
      </div>
    </div>

    <div class="d-none d-lg-block">
      <div class="panel-info mw-2">
        <strong>{{item.extended_title}}</strong>
        <small class="subheader">{{item.extended_description}}</small>
      </div>
    </div>

  </div>

</div>
