import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {

  @Input() type: 'primary' | 'danger' = 'primary';
  @Input() icon: string;
  @Input() message: string;
  @Input() ok = 'OK';
  @Output() response = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
  }

  onSuccess() {
    this.activeModal.dismiss('Cross click');
    this.response.next(true);
  }
}
