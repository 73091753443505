import {BehaviorSubject, Observable} from 'rxjs';

export class StateService {

  private _activeItem: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor() {
  }

  public get activeItem() {
    return this._activeItem.value;
  }

  public set activeItem(activeItem: string) {
    if (activeItem != this.activeItem)
      this._activeItem.next(activeItem);
  }

  public getCurrentActiveItem(): Observable<string> {
    return this._activeItem.asObservable();
  }

}
