import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {User} from '../../_business';
import {AuthService} from '../../_services/auth.service';
import {interval} from 'rxjs';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit, OnDestroy {

    public loading: boolean = false;
    public error: boolean = false;
    public user: User = null;

    public premiumTimer;

    constructor(
        public translate: TranslateService,
        private route: ActivatedRoute,
        private auth: AuthService,
    ) {
        console.log(this.route.snapshot.paramMap.get('result'));

        this.user = this.auth.user;
        this.error = this.route.snapshot.paramMap.get('result') != 'success';

        if (!this.error && !this.user.premium) {
          this.loading = true;

          this.premiumTimer = interval(10000).subscribe(() => {
            this.auth.checkPremium().subscribe(
              (data) => {
                if (data.result) {
                  this.loading = false;
                  this.user.premium = data.result;
                  this.auth.user = this.user;
                  this.premiumTimer.unsubscribe();
                }
              },
              err => {
                console.error(err.error);
                this.error = err.error;
                this.loading = false;
              }
            );
          });
        }
    }


    ngOnInit(): void {
    }

    ngOnDestroy(): void {
      this.premiumTimer && this.premiumTimer.unsubscribe();
    }

}
