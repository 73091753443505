import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DataForm} from '../../_helpers/dataForm';
import {PasswordValidation} from '../../_helpers/passwordValidation';
import {AuthService} from '../../_services/auth.service';
import {install} from "../../_helpers/lib";

@Component({
  selector: 'app-restore',
  templateUrl: './restore.component.html',
  styleUrls: ['./restore.component.css']
})
export class RestoreComponent extends DataForm implements OnInit {

  passwordType = 'password';

  constructor(
    public translate: TranslateService,
    private route: ActivatedRoute,
    private auth: AuthService,
    public formBuilder: FormBuilder,
  ) {
    super(formBuilder);
  }

  public initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        password: [
          null, [
            Validators.required,
            // Validators.pattern('^[a-zA-Z0-9]+'),
            Validators.minLength(2)
          ]],
        confirm: [
          null, [
            Validators.required
          ]]
      }, {
        validator: PasswordValidation.matchPassword
      });
  }

  public onSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    const memberId = this.route.snapshot.paramMap.get('memberId');
    const code = this.route.snapshot.paramMap.get('code');
    const password = this.f.password.value;

    if (memberId && code && password) {
      this.auth
        .reset(memberId, password, code).subscribe(
        () => {
          this.onStopSubmit();
          this.success = true;
        },
        err => {
          this.error = err.error;
        }
      )
        .add(() => {
        });
    }
  }

  onViewPassword() {
    this.passwordType =
      this.passwordType === 'password'
        ? 'text'
        : 'password';
  }

  protected readonly install = install;
}
