import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Audio} from '../../../_business';

@Component({
  selector: 'app-audios-modal',
  templateUrl: './audios-modal.component.html',
  styleUrls: ['./audios-modal.component.css']
})
export class AudiosModalComponent implements OnInit {

  @Input() public name = 'Audio Recordings';
  @Input() public title: string;
  @Input() public list: Audio[];
  @Input() public loading = true;

  @Output() response = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
  }

}
