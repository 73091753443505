import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {Podcast, Training} from 'src/app/_business';
import {DataService} from 'src/app/_services/data.service';
import {isMobile} from "../../../_helpers/lib";
import {MixpanelService} from "../../../_services/mixpanel.service";
import Plyr from "plyr";

@Component({
  selector: 'app-podcast',
  templateUrl: './podcast.component.html',
  styleUrls: ['./podcast.component.css']
})
export class PodcastComponent implements OnInit {

  @Input() podcast: Podcast = null;
  @Output() response = new EventEmitter();

  public movieUrl: string;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private titleService: Title,
    private metaService: Meta,
    private _mixpanelService: MixpanelService,
  ) {
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.podcast.name);
    this.metaService.addTags([
      {name: 'description', content: this.podcast.short_description},
    ]);

    this._mixpanelService.track(
      'Podcast Opened',
      {
        'id' : this.podcast.id,
        'name' : this.podcast.name,
      }
    );

    this.dataService.getPodcastAudioUrl(this.podcast.id).subscribe(
      (data) => {
        this.movieUrl = data.url;
      }
    );
  }

  protected readonly isMobile = isMobile;

  onEnd(e: Plyr.PlyrEvent) {
    this._mixpanelService.track(
      'Podcast Finished',
      {
        'id' : this.podcast.id,
        'name' : this.podcast.name,
        'duration': e.detail.plyr.duration,
      }
    );
  }
}
