import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import Plyr from 'plyr';
import {NavigationEnd, Router} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.css']
})
export class VideoModalComponent implements OnInit {

  @Input() public movieUrl: string;
  @Input() public name = '';
  @Input() public poster = '';

  @Input() public messageClose: string = null;

  @Output() response = new EventEmitter();
  @Output() onEndResponse = new EventEmitter();

  private end = false;
  private eventsSubscription: Subscription = null;

  public playerWidth = 300;
  private ratio = 1;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.eventsSubscription = this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd)
          this.activeModal.close('Close click');
      });
  }

  public onEnd(event: Plyr.PlyrEvent) {
    console.log('onEnd', event);
    this.end = true;
    // this.onModalClosing();
    this.onEndResponse.next(event);
  }

  onModalClosing() {
    if (!this.messageClose || this.end || confirm(this.messageClose)) {
      this.response.next(this.end);
      this.activeModal.close('Close click');
    }
  }

  onPlayerLoaded(e: any) {
    // console.log('onPlayerLoaded', e.detail?.plyr?.ratio, e)
    // this.uploadError && this.player.pause();

    const r = e.detail.plyr.ratio.split(':');
    this.ratio = +r[1] / + r[0];
    this.onResize();
  }

  onResize() {
    // console.log('onResize', e)

    // const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    this.playerWidth = (h - 148) / this.ratio;
    this.cd.detectChanges();
  }

}
