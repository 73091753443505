import {Component} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {DataForm} from '../../_helpers/dataForm';
import {AuthService} from '../../_services/auth.service';
import {User} from '../../_business';
import {environment} from "../../../environments/environment";
import {MixpanelService} from "../../_services/mixpanel.service";

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent extends DataForm {

  public readonly onboardingLink = environment.onboardingLink;
  public provider: string;
  public passwordType = 'password';

  constructor(
    public auth: AuthService,
    public formBuilder: FormBuilder,
    private _mixpanelService: MixpanelService,
  ) {
    super(formBuilder);
  }

  public initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        email: [
          null, [
            Validators.required, Validators.email
          ]],
        password: [
          null, [
            Validators.required,
            // Validators.pattern('^[a-zA-Z0-9]+'),
            Validators.minLength(2)
          ]],
      });
  }

  public onSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    const user: User = {
      login: this.f.email.value,
      password: this.f.password.value
    };

    this.auth
      .signin(user).subscribe({
        next: () => {
          this._mixpanelService.setUser();
        },
        error: err => {
          this.onStopSubmit();

          switch (err.status) {
            case 401:
              err.error = 'Wrong login or password';
              break;

            // case 403:
            //   err.error = 'Недостаточно прав для выполнения операции';
            //   break;
          }

          console.error(err);
          this.error = err.error;
        }
      });
  }

  public onViewPassword() {
    this.passwordType =
      this.passwordType === 'password'
        ? 'text'
        : 'password';
  }

  public goto(provider) {
    if (this.provider) {
      return;
    }

    this.provider = provider;
    this.auth.oauth(provider).subscribe(() => this._mixpanelService.setUser());
  }
}
