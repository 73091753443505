import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {DataService} from '../../_services/data.service';
import {Podcast, User} from '../../_business';
import {Location} from '@angular/common';
import {masonryOptions} from "../../_helpers/lib";
import {AuthService} from "../../_services/auth.service";


@Component({
  selector: 'app-podcasts',
  templateUrl: './podcasts.component.html',
  styleUrls: ['./podcasts.component.css']
})
export class PodcastsComponent implements OnInit {

  private lastScroll = 0;

  public loading = true;
  public error;
  public podcasts: Podcast[] = [];
  public selectedPodcast: Podcast = null;
  public id: number;
  public user: User = null;

  constructor(
    private dataService: DataService,
    private titleService: Title,
    private translate: TranslateService,
    private router: Router,
    private _location: Location,
    private auth: AuthService,
  ) {
    this.translate.get(['Podcasts']).subscribe(
      res => {
        console.log(res);
        this.titleService.setTitle(res.Podcasts);
      }
    );
  }

  ngOnInit(): void {
    this.user = this.auth.user;

    if (this.id) {
      this.dataService.getPodcastById(this.id).subscribe(
        (data) => {
          this.selectedPodcast = data;
        }
      )
        .add(() => this.loading = false);
    } else {
      this.dataService.getPodcasts().subscribe(
        (data: Podcast[]) => {
          this.podcasts = data;
        }
      )
        .add(() => this.loading = false);
    }
  }

  public onShowPop(podcast: Podcast) {
    if (podcast.paid)
      this.router.navigate(['/subscribe']);
    else {
      this.selectedPodcast = podcast;
      this.lastScroll = window.pageYOffset;
      window.scroll(0,0);
    }
  }

  public onClosePop() {
    if (this.id)
      this._location.back();
    else {
      this.selectedPodcast = null;
      window.scroll(0, this.lastScroll);
    }
  }

  protected readonly masonryOptions = masonryOptions;
}
