<div class="container">
  <h2>{{ 'Support Center' | translate }}</h2>
  <p class="subtitle">{{ 'Be free to contact us!' | translate }}</p>

  <div class="form-block">
    <p>
      <a href="mailto:info@vocalimage.app" class="btn btn-secondary w-100 text-start">
        <svg-icon src="/assets/img/icons/mail.svg" class="i"></svg-icon>
        E-mail
      </a>
    </p>

    <p>
      <a href="https://t.me/nick_vocalimage" class="btn btn-secondary w-100 text-start">
        <svg-icon src="/assets/img/icons/telegram.svg" class="i"></svg-icon>
        {{ 'Telegram' | translate }}
      </a>
    </p>

    <p>
      <a href="https://www.vocalimage.app/faq" class="btn btn-secondary w-100 text-start">
        <svg-icon src="/assets/img/icons/support.svg" class="i" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon>
        {{ 'Q&A' | translate }}
      </a>
    </p>
  </div>
</div>
