import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {install} from "../../../_helpers/lib";
import {NgOptimizedImage} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-modal',
  templateUrl: './app-modal.component.html',
  standalone: true,
  imports: [
    NgOptimizedImage,
    TranslateModule
  ],
  styleUrls: ['./app-modal.component.css']
})
export class AppModalComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
  }

  protected readonly install = install;

  onContinue() {
    this.activeModal.dismiss('Cross click');
    localStorage.setItem('app-modal', '1');
  }
}
