<!--<div class="d-flex gap-3 align-items-center">
  <button *ngIf="!player || player.paused else play" class="btn btn-warning p-3 fs-0" (click)="onPlay()"><app-icon icon="sound" size="md"/></button>

  <ng-template #play>
    <button class="btn p-0 fs-0" (click)="player.pause()">
      <canvas width="52" height="52" riv="audio_yellow_button" style="pointer-events: none">
        <riv-state-machine name="audio_button" play>
          <riv-input name="sound_playing" [value]="true"></riv-input>
        </riv-state-machine>
      </canvas>
    </button>
  </ng-template>

  <div class="w-100">
    <p class="mb-2 d-flex justify-content-between align-items-center">
      <span class="h2 mb-0" translate>{{before ? 'Before Training' : 'After Training'}}</span>
      <small class="subtitle">{{(duration - player?.currentTime) * 1000 | date:'mm:ss':'UTC'}}</small>
    </p>

    <div class="progress progress-sm">
      <div class="progress-bar bg-warning" role="progressbar" [style.width]="player?.currentTime / (duration / 100) + '%'"></div>
    </div>
  </div>
</div>-->




<div class="d-flex gap-3 align-items-center">
  <button *ngIf="player?.paused" class="btn btn-warning p-3 fs-0" (click)="onPlay()"><app-icon icon="sound" size="md"/></button>

  <button *ngIf="!player?.paused" class="btn p-0 fs-0" (click)="player.pause()">
    <canvas width="52" height="52" riv="audio_yellow_button" style="pointer-events: none">
      <riv-state-machine name="audio_button" play>
        <riv-input name="sound_playing" [value]="true"></riv-input>
      </riv-state-machine>
    </canvas>
  </button>

  <div class="w-100">
    <p class="mb-2 d-flex justify-content-between align-items-center">
      <span class="h2 mb-0" translate>{{before ? 'Before Training' : 'After Training'}}</span>
      <small class="subtitle">{{((player?.duration || duration) - player?.currentTime) * 1000 | date:'mm:ss':'UTC'}}</small>
    </p>

    <div [plyrSources]="[{ src: audio, type: 'audio/mp4', provider: 'html5' }]" plyr plyrTitle="Audio" plyrType="audio" [plyrPlaysInline]="true" [plyrCrossOrigin]="true"
         [plyrOptions]="{controls: []}" (plyrInit)="onPlayerInit($event)" (plyrTimeUpdate)="onTime()" (plyrEnded)="onEnd()"
    ></div>

    <div class="progress progress-sm">
      <div class="progress-bar bg-warning" role="progressbar" [style.width]="player?.currentTime / ((player?.duration || duration) / 100) + '%'"></div>
    </div>
  </div>
</div>
