<div class="container">
  <a href="#" class="btn btn-gradient mb-3" style="float: right; padding: 8px 8px 8px 15px;" (click)="install()">
    <span translate style="margin-right: 50px;">Training in App</span>
    <span class="btn btn-light" translate>Download</span>
  </a>

  <h2 [innerHTML]="'<span class=\'subtitle\'>Your</span> Activity' | translate"></h2>
  <div class="clear"></div>

  <div class="card page mb-3">
    <div class="card-body">
      <h4 translate>
        <span class="float-end">
          <svg-icon src="/assets/img/icons/clip.svg" class="i" style="color: #4D5EBF;"></svg-icon>{{ specialCount }}
        </span>
        Especially for you
      </h4>
      <div class="row">

        <div *ngIf="videoTutorial" class="col" (click)="onShowMoviePop()">
          <a class="btn btn-gradient w-100">
            <svg-icon src="/assets/img/icons/right.svg" class="right"></svg-icon>
            <span class="btn btn-light"><svg-icon src="/assets/img/icons/play.svg"></svg-icon></span>
            <span class="title" translate>Watch video guide</span>
          </a>
        </div>

        <div *ngIf="plan" class="col">
          <a routerLink="/trainer" class="btn btn-gradient w-100">
            <svg-icon src="/assets/img/icons/right.svg" class="right"></svg-icon>
            <span class="btn btn-light"><svg-icon src="/assets/img/icons/plan.svg"></svg-icon></span>
            <span class="title" translate>Your personal training plan</span>
          </a>
        </div>

        <div *ngIf="intensiveCurrent" class="col">
          <a routerLink="/courses" [queryParams]="{id: intensiveCurrent.id}" class="btn btn-gradient w-100">
            <svg-icon src="/assets/img/icons/right.svg" class="right"></svg-icon>
            <span class="btn btn-light"><svg-icon src="/assets/img/icons/courses.svg"></svg-icon></span>
            <span class="title" translate [translateParams]="{value: intensiveCurrent.viewed + '/' + intensiveCurrent.total}">Your current course</span>
          </a>
        </div>

        <div class="col">
          <a routerLink="/lectures" class="btn btn-gradient w-100">
            <svg-icon src="/assets/img/icons/right.svg" class="right"></svg-icon>
            <span class="btn btn-light"><svg-icon src="/assets/img/icons/course.svg"></svg-icon></span>
            <span class="title" translate>Watch talks</span>
          </a>
        </div>

        <div class="col">
          <a routerLink="/podcasts" class="btn btn-gradient w-100">
            <svg-icon src="/assets/img/icons/right.svg" class="right"></svg-icon>
            <span class="btn btn-light"><svg-icon src="/assets/img/icons/audio.svg"></svg-icon></span>
            <span class="title" translate>Listen to podcasts</span>
          </a>
        </div>

      </div>
    </div>
  </div>

  <div *ngIf="events.length" class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 mb-3">
    <ng-container *ngFor="let event of events">
      <div class="card" *ngIf="getEventItem(event); let item" [ngClass]="{'paid': item.paid}">
        <a class="card-link" href="javascript:void(0)" (click)="goToEvent(event, item)"></a>
        <div class="card-title">
          <p class="subtitle">
            <svg-icon src="/assets/img/icons/fire.svg" class="i" [svgStyle]="{ 'fill': (item.paid ? '#E26564' : '#4d5ebf') }"></svg-icon>{{ item.title | translate }}
          </p>
          <div class="overflow">{{ item.name }}</div>
        </div>
        <img class="card-img" [attr.src]="item.images.main.url" alt="" width="570" height="320" />
        <div class="card-img-overlay">
          <p class="card-text">{{ item.short_description }}</p>
          <a *ngIf="item.btn" href="javascript:void(0)" class="btn" [ngClass]="{'btn-primary': !item.paid, 'btn-danger': item.paid}">
            <svg-icon *ngIf="item.paid" src="/assets/img/icons/lock.svg" class="i"></svg-icon><svg-icon *ngIf="!item.paid" src="/assets/img/icons/play.svg" class="i"></svg-icon>{{ item.btn | translate }}
          </a>
        </div>
      </div>
    </ng-container>
  </div>

  <app-plan [subtitle]="'Your goal: Career and sales'" (response)="plan = $event.length"></app-plan>

  <h3>
    <img src="/assets/img/icons/union.svg" class="i" />
    {{ 'Achievements' | translate }}
  </h3>
<!--  <p class="subtitle">{{ 'Unlock all rewards' | translate }}</p>-->

  <div class="card d-inline-block" *ngFor="let item of achieves" [ngClass]="{'active': item.active}">
    <a class="card-link" href="javascript:void(0)" (click)="showAchieve(item)"></a>
    <img class="card-img" [attr.src]="'/assets/img/achieves/' + (item.active ? 'active/' : '') + item.id + '.svg'" alt="" width="120" height="138" />
    <div class="card-img-overlay header">
      <p class="card-text text-center">{{ item.name }}</p>
    </div>
  </div>

<!--  <div class="container page-content" *ngIf="loading">
    <img width="50" height="50" src="/assets/img/loader.gif" alt=""/>
  </div>

  <p *ngIf="!loading && !managerLink">
    <a href="javascript:void(0)" (click)="toPaywall()" class="btn btn-primary">{{ 'Page.Home.subscribe' | translate }}</a>
  </p>

  <p *ngIf="!loading && managerLink">
    <a href="javascript:void(0)" (click)="toManager()" class="btn btn-primary">{{ 'Page.Home.manager' | translate }}</a>
  </p>-->
</div>
