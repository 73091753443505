<div class="modal-header">
<!--  <h3 class="modal-title">{{ achieve.name }}</h3>-->
  <button class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body" [style.backgroundImage]="'url(/assets/img/achieves/' + (achieve.active ? 'active/' : '') + achieve.id + '.svg)'">
  <pre class="p-0 mb-0 small">{{ achieve.description }}</pre>
</div>
<div class="modal-footer d-block">
  <a (click)="activeModal.dismiss('Cross click')" class="btn btn-primary w-100" translate>Got it</a>
</div>
