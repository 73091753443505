<div class="container">

  <button role="button" class="btn btn-secondary btn-close" (click)="response.emit('close')"></button>

  <h2>{{ intensive.name }}</h2>

  <p>
    <svg-icon src="/assets/img/icons/course.svg" class="i" [svgStyle]="{ width: 24, height: 24 }"></svg-icon>{{ intensive.training_count | i18nPlural: {'=0': 'Params.lectures.zero', '=1': 'Params.lectures.one', 'few': 'Params.lectures.few', 'other': 'Params.lectures.other'} | translate:{number: intensive.training_count} }}
  </p>

  <pre class="p-0 mb-4 small">{{ intensive.description }}</pre>

  <div *ngIf="movieUrl" class="mb-4" style="max-width: 33.33%; min-width: 400px">
    <div plyr [plyrSources]="[{ src: movieUrl, type: 'video/mp4' }]" [plyrTitle]="intensive.name"
         [plyrPoster]="intensive.images.video.url" [plyrPlaysInline]="true"></div>
  </div>

  <app-trainings [list]="intensive.trainings"></app-trainings>

</div>
