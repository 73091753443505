<div class="container">
  <h2>{{ 'Statistics' | translate }}</h2>

  <app-loading *ngIf="loading"/>

  <div class="row align-items-center">
    <div class="col-auto">
      <div id="pie"></div>
    </div>
    <div class="col">
      <div class="form-block">

        <div *ngFor="let obj of bars; index as i" class="item my-3">
          <div class="txt subtitle">{{ obj.name | translate }}</div>
          <div class="procent subtitle">{{ obj.points }}</div>
          <div class="progress">
            <div class="progress-bar" role="progressbar" [style.width]="obj.points / ((totalExperience / bars.length) / obj.total) + '%'" [style.background-color]="chartOptionsPie.plotOptions.pie.colors[i]"></div>
          </div>
        </div>

      </div>
    </div>
  </div>

<!--  <div id="bar"></div>-->

  <div *ngIf="error" class="alert alert-danger">{{ error | translate }}</div>
</div>
