// use ngx-plyr
import {Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import Plyr from 'plyr';

@Directive({
  selector: '[plyr]',
  standalone: true
})
export class PlyrDirective implements OnInit, OnDestroy {

  @Input() plyrCrossOrigin = false;
  @Input() plyrPlaysInline = false;

  @Input() plyrSources: any = null;
  // https://cdn.plyr.io/static/demo/Kishi_Bashi_-_It_All_Began_With_a_Burst.mp3

  @Input() plyrTitle: string;
  @Input() plyrType: "audio" | "video";
  @Input() plyrPoster: string;

  @Input() plyrOptions: any = {};

  @Output() plyrEnded = new EventEmitter();
  @Output() plyrInit = new EventEmitter();
  @Output() plyrPlay = new EventEmitter();
  @Output() plyrTimeUpdate = new EventEmitter();
  @Output() plyrPause = new EventEmitter();
  @Output() plyrLoadedData = new EventEmitter();

  player: Plyr;

  constructor(private el: ElementRef) {
    // this.el.nativeElement.style.backgroundColor = 'yellow';
  }

  ngOnInit(): void {
    if (this.plyrSources?.type)
      this.plyrType = 'audio';

    let sources: any[];

    if (Array.isArray(this.plyrSources))
      sources = this.plyrSources;
    else if (this.plyrSources?.src)
      sources = [this.plyrSources];
    else
      sources = [{src: this.plyrSources}];

    if (!this.plyrType) {
      if (sources[0].type && sources[0].type.includes('video'))
        this.plyrType = 'video';
      else
        this.plyrType = 'audio';
    }

    const params = [];
    this.plyrPoster && params.push('poster=' + this.plyrPoster);
    this.plyrCrossOrigin && params.push('crossorigin');
    this.plyrPlaysInline && params.push('playsinline');
    this.plyrOptions.autoplay && params.push('autoplay')

    // type="audio/mp3"
    this.el.nativeElement.insertAdjacentHTML(
    'beforeend',
      `<${this.plyrType} ${params.join(' ')}>
        ${
          sources.map(({src, type}) => `<source src="${src}"${type ? ' type=' + type : ''}>`)
        }
      </${this.plyrType}>`
    );

    this.player = new Plyr(this.el.nativeElement.childNodes[0], {
      // controls: ['progress', 'current-time', 'play', 'rewind', 'fast-forward', 'settings'],
      ...this.plyrOptions,
    });

    this.plyrInit.emit(this.player);

    // this.player.on('ready', (e) => {
    //   this.plyrInit.emit(e);
    // });

    this.player.on('play', (e) => this.plyrPlay.emit(e));
    this.player.on('loadeddata', (e) => this.plyrLoadedData.emit(e));
    this.player.on('timeupdate', (e) => this.plyrTimeUpdate.emit(e));
    this.player.on('pause', (e) => this.plyrPause.emit(e));
    this.player.on('ended', (e) => this.plyrEnded.emit(e));
  }

  ngOnDestroy(): void {
    if (this.player) {
      this.player.pause();
      this.player.destroy();
    }
  }

}
