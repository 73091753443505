<div *ngIf="!selectedCourse" class="container">
  <app-tip-load code="courses"/>

  <h1 class="d-none d-lg-block" translate>Talks</h1>

  <app-explore-tabs class="d-lg-none"/>

  <app-loading *ngIf="loading"/>

  <ngx-masonry [options]="masonryOptions" ordered="true">
    <div *ngFor="let item of courses" ngxMasonryItem class="card grid-item" [ngClass]="{'paid': item.paid}" role="button" (click)="onShowPop(item)">
      <div class="card-title">
        <p class="subheader fw-normal" style="font-size: 14px;">
          <app-icon icon="papers" class="i"/>{{
            item.training_count
              | i18nPlural: {'=0': 'Params.lectures.zero', '=1': 'Params.lectures.one', 'few': 'Params.lectures.few', 'other': 'Params.lectures.other'}
              | translate:{number: item.training_count}
          }}
        </p>
        <h2 class="overflow">{{ item.name }}</h2>
      </div>

      <button *ngIf="!user.premium && item.paid" routerLink="/subscribe" class="btn btn-xss btn-premium card-sign" translate>Premium</button>

      <div class="card-body pt-0">
        <img class="card-img" [attr.src]="item.images.main.url" alt="" width="570" height="320" />
        <p class="card-text subheader">{{ item.short_description }}</p>
        <button class="btn btn-md w-100 btn-primary">
<!--          <app-icon *ngIf="item.paid" icon="lock" class="i"></app-icon>-->
          {{ 'Continue' | translate }}
        </button>
      </div>
    </div>
  </ngx-masonry>


  <!--
  <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 mb-3">
    <div class="card" *ngFor="let item of courses" [ngClass]="{'paid': item.paid}" role="button" (click)="onShowPop(item)">
      <div class="card-title">
        <p class="subheader small">
          <app-icon icon="papers" class="i"/>{{
            item.training_count
              | i18nPlural: {'=0': 'Params.lectures.zero', '=1': 'Params.lectures.one', 'few': 'Params.lectures.few', 'other': 'Params.lectures.other'}
              | translate:{number: item.training_count}
          }}
        </p>
        <h2 class="overflow">{{ item.name }}</h2>
      </div>
      <div class="card-body">
        <img class="card-img" [attr.src]="item.images.main.url" alt="" width="570" height="320" />
        <p class="card-text subheader">{{ item.short_description }}</p>
        <button class="btn btn-md w-100" [ngClass]="{'btn-primary': !item.paid, 'btn-danger': item.paid}">
          <svg-icon *ngIf="item.paid" src="/assets/img/icons/lock.svg" class="i"></svg-icon>{{ 'Continue' | translate }}
        </button>
      </div>
    </div>
  </div>-->

  <div *ngIf="error" class="alert alert-danger">{{ error | translate }}</div>
</div>

<app-lecture *ngIf="selectedCourse" [course]="selectedCourse" (response)="onClosePop()"></app-lecture>
