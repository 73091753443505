<h2>{{ 'Courses' | translate }}</h2>

<nav class="navbar navbar-expand-lg tags mb-4" ngbDropdown>
  <div class="navbar-toggler p-0 w-100">
    <button class="btn btn-secondary w-100 text-start" id="dropdownTags" ngbDropdownToggle>
      {{ title | translate }}
    </button>
  </div>
  <div class="collapse navbar-collapse w-100" ngbDropdownMenu aria-labelledby="dropdownTags">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a routerLink="/courses" routerLinkActive="active" ngbDropdownItem>{{ 'Training programs' | translate }}</a>
      </li>
      <li class="nav-item">
        <a routerLink="/lectures" routerLinkActive="active" ngbDropdownItem>{{ 'Talks' | translate }}</a>
      </li>
      <li class="nav-item">
        <a routerLink="/podcasts" routerLinkActive="active" ngbDropdownItem>{{ 'Podcasts' | translate }}</a>
      </li>
    </ul>
  </div>
</nav>
