<div class="modal-body">
  <div class="text-center">
    <img src="/assets/img/icons/recirding_tips.svg" width="128" height="100" alt="" class="mb-4"/>
    <h2 translate>For optimal results, make sure:</h2>
  </div>

  <div class="panel mb-3">
    <app-icon icon="notifications-no" width="24" height="24"/>
    <span class="subheader" translate>You are in a quiet room</span>
  </div>
  <div class="panel mb-3">
    <app-icon icon="podcast" width="24" height="24"/>
    <span class="subheader" translate>Your microphone is enabled</span>
  </div>
  <div class="panel mb-4">
    <app-icon icon="wifi" width="24" height="24"/>
    <span class="subheader" translate>Your internet connection is stable</span>
  </div>

  <div class="form-check mb-4 text-start">
    <input id="doNotShowTips" type="checkbox" class="form-check-input" (change)="onCheck($event)"/>
    <label for="doNotShowTips" class="form-check-label subtitle" translate>Do not show again</label>
  </div>

  <button class="btn btn-warning w-100" (click)="activeModal.dismiss('Cross click')" translate>Got it</button>
</div>
