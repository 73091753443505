<div class="container">
  <h2>{{ 'Notifications' | translate }}</h2>

  <div class="container page-content" *ngIf="loading">
    <img width="50" height="50" src="/assets/img/loader.gif" alt=""/>
  </div>

  <div *ngFor="let message of messageList" class="alert alert-message mb-2" [ngClass]="{ 'read': !message.read, 'special': message.special }">
    <a *ngIf="message.user_message_type != 'void'" class="card-link" href="javascript:void(0)" (click)="onMessage(message)"></a>
    <strong>{{ message.name }}</strong>
    <p class="mb-1">{{ message.content }}</p>
    <a *ngIf="message.btn" href="{{ message.url || 'javascript:void(0)' }}" class="btn btn-light compact">{{ message.btn }}</a>
  </div>

</div>
