<div class="container page-content partner-{{partner}}">
  <img *ngIf="loading && !error" width="50" height="50" src="../assets/img/loader.gif" alt=""/>

  <div *ngIf="success">
    <h3 class="mb-4">{{ 'Page.Special.success' | translate }}</h3>
    <div class="header page-info">
      <p>{{ 'Page.Special.fraze2' | translate }}</p>
      <div class="mb-3 ii">
        <a href="#" (click)="install()" class="btn btn-primary d-block">{{ 'Page.ToApp.button' | translate }}</a>
        <br/>
        <div>
          <app-download></app-download>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="cancel">
    <h3 class="mb-4">{{ 'Page.Special.cancel' | translate }}</h3>
    <div class="header page-info">
      <p>{{ 'Page.Special.fraze3' | translate }}</p>
      <p>
        <button (click)="onRetry()" [disabled]="loading" type="button" class="btn btn-primary w-100">
          {{ 'Page.Special.retry' | translate }}
          <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
      </p>
    </div>
  </div>

  <div *ngIf="!success && !cancel && !loading && !error">
    <div *ngIf="partner=='ny2022'" class="ny2022">
      <img src="/assets/img/bg/yearly.png" alt="" class="bg d-none d-lg-inline" />
      <div class="position-relative">
        <img [src]="card" alt="" style="width: 100%; margin-left: -4px;" />

        <div *ngIf="price" class="alert alert-light">
          <div class="tooltip-arrow top"></div>
          <div class="name">{{'Christmas Special'}}</div>
          <div class="price">
            <s>{{ price.price | currency: price.currency }}</s>
            <div>{{ +price.price - (+price.price / 100) * 25 | currency: price.currency }}</div>
          </div>
          <div class="clear"></div>
        </div>

      </div>
    </div>

    <div class="overflow-hidden">
      <h3 class="mb-4" [innerHTML]="title | translate"></h3>
      <div class="header page-info">
        <p>{{ fraze | translate }}</p>
      </div>

      <div class="form-block">
        <form [formGroup]="dataForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="email" *ngIf="!placeholder">{{ 'Page.Special.email' | translate }}</label>
            <div class="position-relative">
              <input id="email" formControlName="email" maxlength="64" [attr.placeholder]="placeholder"
                     [ngClass]="{ 'is-invalid': submitted && f.email.errors }" class="form-control" autocomplete="email"/>
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              </div>
            </div>
          </div>

          <p>
            <button [disabled]="loading" type="submit" class="btn btn-primary w-100">
              {{ submit | translate }}
              <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>
          </p>
        </form>
        <p class="note" [innerHTML]="cancelTxt | translate"></p>
      </div>
    </div>
  </div>

  <div *ngIf="error">
    <h3 class="mb-4">{{ 'Page.Special.negativeTitle' | translate }}</h3>
    <div class="header page-info">
      <p>{{ 'Page.Special.negativeText' | translate }}</p>
      <p>
        <button (click)="onRetry()" [disabled]="loading" type="button" class="btn btn-primary w-100">
          {{ 'Page.Special.retry' | translate }}
          <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
      </p>
    </div>
  </div>

</div>
