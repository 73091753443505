import {Component, OnInit, ViewChild} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Training} from 'src/app/_business';
import {DataService} from 'src/app/_services/data.service';

// import {PlyrComponent} from 'ngx-plyr';
import * as Plyr from 'plyr';
import {faHeart} from "@fortawesome/free-regular-svg-icons";
import {faHeart as fasHeart} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-lesson',
  templateUrl: './lesson.component.html',
  styleUrls: ['./lesson.component.css']
})
export class LessonComponent implements OnInit {

  public loading = false;
  private id: number = null;
  public training: Training = null;
  public columns = 5;
  public videoReady = false;

  protected readonly farHeart = faHeart;
  protected readonly fasHeart = fasHeart;

  // @ViewChild(PlyrComponent, {static: true})
  // plyr: PlyrComponent;
  player: Plyr;


  videoSources: Plyr.Source[] = [
    {
      src: null,// 'https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-576p.mp4',
      type: 'video/mp4',
    },
  ];

  // training.images.video.url
  poster = null;// 'https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-HD.jpg';

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private titleService: Title,
    private metaService: Meta,
    private translate: TranslateService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = +params.get('id');
      this.getTraining();
    });

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.id)
        this.getTraining();
    });

  }

  public getTraining() {
    this.training = null;
    this.loading = true;
    this.videoReady = false;

    this.dataService.getTrainingById(this.id).subscribe(
      (training) => {

        this.poster = training.images.video.url;
        this.training = training;


        this.dataService.getTrainingVideo(this.training.id).subscribe(
          (data) => {
            this.videoSources[0].src = data.url;
            this.videoReady = true;
          }
        );


        this.titleService.setTitle(this.training.name);
        this.metaService.addTags([
          {name: 'description', content: this.training.short_description},
        ]);

        setTimeout(function () {
          window.dispatchEvent(new Event('resize')); // for sliders
        });

      },
      (err: Error) => {
        // go to 4o4
        this.router.navigate(['/notfound'])
      }
    )
      .add(
        () => this.loading = false
      )
  }


  public onResize(event) {
    if (event.target.innerWidth > 991) {
      this.columns = 5;
    } else {
      this.columns = 2;
    }
  }

  public played(event: Plyr.PlyrEvent) {


    // console.log('played', event);
  }

  public onFavourite() {

    this._toIntall();
    // training.favorited = !training.favorited;

  }

  public goToTraining(training: Training) {
    if (training.paid)
      this._toIntall();
    else
      this.router.navigate(['/lessons', 'view', training.id]);
    // [routerLink]="['/lessons', 'view', train.id]" *ngIf="!train.paid"
  }

  private _toIntall() {
    this.router.navigate(['/install']);
  }

  /*
  public play(): void {
console.log('PLAY');
      this.player.play(); // or this.plyr.player.play()
  }

  public pause(): void {
      this.player.pause(); // or this.plyr.player.play()
  }

  public stop(): void {
      this.player.stop(); // or this.plyr.player.stop()
  }
  */
}
