import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../_services/auth.service';
import {install} from "../../_helpers/lib";

@Component({
  selector: 'app-confirm-page',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {

  public result = false;
  public loading = true;

  constructor(
    public translate: TranslateService,
    private route: ActivatedRoute,
    private auth: AuthService,
  ) {
  }

  ngOnInit(): void {
    this._parseParams();
  }

  private _parseParams() {

    const memberId = this.route.snapshot.paramMap.get('memberId');
    const code = this.route.snapshot.paramMap.get('code');

    if (memberId && code) {
      this.auth.confirm(memberId, code).subscribe(() => {
        this.result = true;
      }).add(() => this.loading = false);
    }
  }

  protected readonly install = install;
}
