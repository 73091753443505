<header class="container py-0">
  <nav class="navbar navbar-expand-lg navbar-dark primary-color">
    <div class="btn-group me-auto" style="margin-left: -14px" ngbDropdown>
      <button class="btn dropdown-toggle note lang" id="dropdownLang" ngbDropdownToggle>{{ translate.currentLang }}</button>
      <div aria-labelledby="dropdownLang" ngbDropdownMenu>
        <ng-container *ngFor="let language of languageEnum | keyvalue">
          <a *ngIf="language.key != translate.currentLang" class="lang" ngbDropdownItem (click)="changeLang(language.key)">
            {{ language.key }}
          </a>
        </ng-container>
      </div>
    </div>

    <a class="navbar-brand mx-auto py-0" routerLink="/"><img priority ngSrc="/assets/img/logo.png" alt="Vocal Image" class="logo" height="40" width="144"/></a>

    <a href="{{WEB}}" class="btn btn-secondary btn-close mt-0 ms-auto d-md-none"></a>
<!--    <div class="btn-group ms-auto opacity-0 d-md-none" style="margin-right: -14px">
      <button class="btn dropdown-toggle note lang">{{ translate.currentLang }}</button>
    </div>-->
  </nav>
</header>
