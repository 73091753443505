import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-explore-tabs',
  templateUrl: './explore-tabs.component.html',
  styleUrls: ['./explore-tabs.component.css']
})
export class ExploreTabsComponent implements OnInit{

  constructor(
    public translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
  }


}
