import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-recording-tips-modal',
  templateUrl: './recording-tips-modal.component.html',
  styleUrls: ['./recording-tips-modal.component.css']
})
export class RecordingTipsModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
  }

  protected readonly localStorage = localStorage;

  onCheck(e: any) {
    if (e.target.checked)
      localStorage.setItem('doNotShowTips', '1')
    else
      localStorage.removeItem('doNotShowTips')
  }
}
