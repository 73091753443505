<aside>
  <div>
    <p class="mb-6">
      <a routerLink="/"><img src="/assets/img/logo.png" alt="Vocal Image" class="logo" width="144" height="40" /></a>
    </p>
    <p class="mb-6">
      <button class="btn btn-sm btn-primary" (click)="install()">{{'Download App'|translate}}</button>
    </p>
  </div>

  <nav class="navbar">
    <ul class="nav navbar-nav">
      <ng-container *ngIf="user">
<!--        <li class="nav-item">
          <a class="nav-link" routerLink="/home" routerLinkActive="active">
            <svg-icon src="/assets/img/icons/home.svg" class="i"></svg-icon>
            {{ 'Главная' | translate }}
          </a>
        </li>-->
        <li class="nav-item subtitle" translate>Explore</li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/lessons" routerLinkActive="active">
            <app-icon icon="trainings" class="i"/>
            {{ 'Trainings' | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/lectures" routerLinkActive="active">
            <app-icon icon="talk" class="i"/>
            {{ 'Talks' | translate }}
          </a>
        </li>
        <li *ngIf="['en', 'es', 'ru', 'uk'].includes(translate.currentLang)" class="nav-item">
          <a class="nav-link" routerLink="/podcasts" routerLinkActive="active">
            <app-icon icon="podcast" class="i"/>
            {{ 'Podcasts' | translate }}
          </a>
        </li>

        <li class="nav-item subtitle" translate>Profile</li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/profile" [ngClass]="{'active': ['profile', 'audio'].includes(area)}">
            <app-icon icon="diary" class="i"/>
            {{ 'My progress' | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/subscribe" routerLinkActive="active">
            <app-icon icon="star" class="i"/>
            {{ 'Membership' | translate }}
          </a>
        </li>

<!--        <li class="nav-item">
          <a class="nav-link" routerLink="/courses" [ngClass]="{'active': ['courses', 'podcasts', 'lectures'].includes(area)}">
            <svg-icon src="/assets/img/icons/courses.svg" class="i"></svg-icon>
            {{ 'Courses' | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/trainer" routerLinkActive="active">
            <svg-icon src="/assets/img/icons/coach.svg" class="i"></svg-icon>
            {{ 'My coach' | translate }}
          </a>
        </li>
        <li class="nav-item subtitle">
          {{ 'Personal' | translate }}
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/subscribe" routerLinkActive="active">
            <svg-icon src="/assets/img/icons/star.svg" class="i" [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"></svg-icon>
            {{ 'Premium' | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/audio" routerLinkActive="active">
            <svg-icon src="/assets/img/icons/audio.svg" class="i"></svg-icon>
            {{ 'Audio' | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/statistics" routerLinkActive="active">
            <svg-icon src="/assets/img/icons/statistics.svg" class="i"></svg-icon>
            {{ 'Statistics' | translate }}
          </a>
        </li>-->
        <li class="nav-item">
          <a class="nav-link" routerLink="/settings" routerLinkActive="active">
            <app-icon icon="settings" class="i"/>
            {{ 'Settings' | translate }}
          </a>
        </li>
      </ng-container>

      <li class="nav-item subtitle">
        {{ 'Any questions?' | translate }}
      </li>
      <li class="nav-item">
        <a class="nav-link" [attr.href]="helpLink" target="_blank">
          <app-icon icon="support" class="i"/>
          {{ 'Support' | translate }}
        </a>
      </li>
    </ul>
  </nav>
</aside>
