<div class="container">
  <button role="button" class="btn btn-lg btn-close" aria-label="Close" (click)="onModalClosing()"></button>
  <h1>{{ name | translate }}</h1>

  <div *ngIf="movieUrl" plyr [plyrTitle]="name" [plyrPlaysInline]="true" [plyrOptions]="{autoplay: true}"
       (window:resize)="onResize()" [style.max-width]="playerWidth + 'px'"
       [plyrPoster]="poster" [plyrSources]="[{ src: movieUrl, type: 'video/mp4' }]"
       (plyrEnded)="onEnd($event)" (plyrLoadedData)="onPlayerLoaded($event)"
  ></div>
</div>
