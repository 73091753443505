import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {AnalysisStat} from "../../../_business/analysis-stat.model";

@Component({
  selector: 'app-graphs-tooltip',
  templateUrl: './graphs-tooltip.component.html',
})
export class GraphsTooltipComponent implements OnInit {

  @Input() public item: AnalysisStat;

  public levelColors = ['success', 'warning', 'danger'];
  // background: linear-gradient(90deg, rgba(43, 50, 66, 0) 0%, rgba(70, 236, 166, 0.4) 100%);
  // background: linear-gradient(90deg, rgba(43, 50, 66, 0) 0%, rgba(255, 209, 90, 0.4) 100%);
  // background: linear-gradient(90deg, rgba(43, 50, 66, 0) 0%, rgba(226, 101, 100, 0.4) 100%);

  public translates: any;

  constructor(
    public translate: TranslateService,
  ) {
    this.translate.get([
      'Page.Profile.graphs_tooltip.levels',
      // 'Page.Profile.graphs_tooltip.top',
      // 'Page.Profile.graphs_tooltip.top_item'
    ]).subscribe(res => this.translates = res);
  }

  ngOnInit(): void {
    // if (this.item)
    //   this.translates['Page.Profile.graphs_tooltip.top_item'] = this.translates['Page.Profile.graphs_tooltip.top_item'].replace(/{item}/g, this.item);
  }

  public toFloor(i: number) {
    return Math.floor(100 - (i + 1) * 100 / this.translates['Page.Profile.graphs_tooltip.levels'].length);
  }
}
