import {Component, OnInit} from '@angular/core';
import {DataService} from '../../_services/data.service';
import {Message} from '../../_business/message.model';
import {timer} from 'rxjs';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {
  public loading = true;
  public messageList: Message[];

  constructor(
    public dataService: DataService,
    public router: Router,
    public modalService: NgbModal,
  ) {

  }

  ngOnInit(): void {
    this.dataService.getUserMessages().subscribe(
      (data: Message[]) => {
        this.messageList = data;
        this.loading = false;

        // for (const message of this.messageList) {
        //   message.read = false;
        // }

        const secondsCounter = timer(3000);
        secondsCounter.subscribe(n => {
          for (const message of this.messageList) {
            if (!message.read) {
              this.dataService.markAsReadUserMessage(message.id).subscribe(
                (data) => {
                  message.read = true;
                }
              );
            }
          }
        });
      }
    );
  }

  public onMessage(message: Message) {
    let url = null;
    let queryParams = {};

    switch (message.user_message_type) {
      case 'link':
        url = [message.url];
        break;

      case 'intensive':
        url = ['/courses'];
        queryParams = { id: message.intensive.id };
        break;

      case 'course':
        url = ['/lectures'];
        queryParams = { id: message.course.id };
        break;

      case 'training':
        url = ['/training', message.training.id];
        // const modalRef = this.modalService.open(
        //   InstallModalComponent,
        //   { centered: true }
        // );
        break;

      case 'podcast':
        url = ['/podcasts'];
        queryParams = { id: message.podcast.id };
        break;
    }

    if (url)
      this.router.navigate(url, { queryParams });
  }
}
