<div class="container">
<!--  <img *ngIf="loading && !error" width="50" height="50" src="/assets/img/loader.gif" alt=""/>-->

  <div class="text-center text-md-start mb-6">
    <h1 class="mb-2">{{ isTranslated ? ('Account Log in' | translate) : 'Log in' }}</h1>
    <p class="subheader">{{ isTranslated ? ('And return to your trainings' | translate) : 'And return to your trainings' }}</p>
  </div>

  <div class="row">
    <div class="col-lg form-block">
      <form [formGroup]="dataForm" (ngSubmit)="onSubmit()">
        <div class="form-group mb-2">
          <input type="email" formControlName="email" maxlength="64" class="form-control" autocomplete="email" placeholder="E-mail" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">{{ 'Validator.require' | translate }}</div>
            <div *ngIf="f.email.errors.email">{{ 'Check the spelling of your email address' | translate }}</div>
          </div>
        </div>

        <div class="form-group mb-md-6">
          <div class="position-relative">
            <app-icon [icon]="passwordType === 'password' ? 'eye' : 'eye-not'" class="eye text-primary" (click)="onViewPassword()"/>
            <input [attr.type]="passwordType" formControlName="password" maxlength="64" class="form-control" autocomplete="current-password" placeholder="{{ 'Password' | translate }}" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">{{ 'Validator.require' | translate }}</div>
              <div *ngIf="f.password.errors.pattern">{{ 'Validator.passwordLatin' | translate }}</div>
              <div *ngIf="f.password.errors.minlength">{{ 'Validator.passwordMin' | translate }}</div>
            </div>
          </div>
        </div>

        <p class="text-center small mb-md-6">
          <a routerLink="/reset" class="marked-4">
            {{ isTranslated ? ('Forgot your password?' | translate) : 'Forgot your password?' }}
          </a>
        </p>

        <p class="mb-md-6">
          <button [disabled]="loading" type="submit" class="btn btn-primary w-100">
            {{ isTranslated ? ('Log In' | translate) : 'Log In' }}
            <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
        </p>
      </form>

      <p class="text-center mb-6 mb-lg-0">
        <small>
          <span class="subtitle">
            {{isTranslated ? ('I don’t have an account?'|translate) : 'I don’t have an account?'}}
          </span>&nbsp;
          <a target="_blank" [attr.href]="onboardingLink" class="link subheader">
            {{isTranslated ? ('Create an account'|translate) : 'Create an account'}}
          </a>
        </small>
      </p>

      <div *ngIf="error" class="alert alert-danger">{{ error | translate }}</div>
    </div>

    <div class="col-lg text-center mb-6 mb-lg-0 delimiter">
      <div class="d-flex h-100 flex-lg-column">
        <div class="line"></div>
        <small class="or note">{{isTranslated ? ('or'|translate) : 'or'}}</small>
        <div class="line"></div>
      </div>
    </div>

    <div class="col-lg form-block">
      <div class="form-group mb-2">
        <a href="javascript:void(0)" class="btn btn-secondary w-100" (click)="goto('apple')">
          <img src="/assets/img/icons/apple.svg" alt="" width="14" height="16" class="i" style="position: relative; top: -2px;" />
          {{isTranslated ? ('Continue with Apple'|translate) : 'Continue with Apple'}}
          <span *ngIf="provider == 'apple'" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </a>
      </div>

      <div class="form-group mb-0">
        <a href="javascript:void(0)" class="btn btn-light w-100" (click)="goto('google')">
          <img src="/assets/img/icons/google.svg" alt="" width="16" height="16" class="i" />
          {{isTranslated ? ('Continue with Google'|translate) : 'Continue with Google'}}
          <span *ngIf="provider == 'google'" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </a>
      </div>
    </div>
  </div>
</div>
