<app-loading *ngIf="loading"/>

<div *ngFor="let audio of list; index as i">

  <p class="fw-bold">{{ audio.created }}</p>

  <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 mb-3">

    <div class="card"><div class="card-body">
      <div class="d-flex justify-content-between" [style.margin-bottom]="!audio.name ? '8px' : ''">
        <span class="subtitle">{{ audio.name || title }}</span>
        <button *ngIf="audio.name" class="btn btn-right link-primary" (click)="onRemove(audio, i)"><app-icon icon="delete"/></button>
      </div>
      <div class="row mini align-items-end aplay">
        <div class="col">
          <img [src]="audio.image_passive" alt="" class="w-100" style="margin-bottom: 8px;"/><br/>
          <div [plyrSources]="audio.audio" plyr plyrTitle="Audio" plyrType="audio" [plyrPlaysInline]="true" [plyrCrossOrigin]="true"></div>
        </div>
        <div class="col-auto pb-1">
          <span class="time header note">{{ audio.length }}</span>
        </div>
      </div>
    </div></div>

  </div>

</div>

<p class="text-center" *ngIf="(!list || !list.length) && !loading">{{ 'No audio' | translate }}</p>
