<div class="container">
  <div class="float-end d-flex gap-3 d-lg-none" style="margin-top: -6px;">
    <a class="btn btn-secondary btn-md subtitle fw-normal" [attr.href]="helpLink" target="_blank">
      <app-icon icon="support" class="i"/>
      {{ 'Support' | translate }}
    </a>
    <a routerLink="/settings" class="btn btn-secondary btn-md subtitle" aria-label="Settings">
      <app-icon icon="settings"/>
    </a>
  </div>

  <h1>
    <span class="d-none d-lg-inline-block" translate>My progress</span>
    <span class="d-lg-none" translate>Profile</span>
  </h1>

  <div class="row align-items-center mb-4 mb-lg-6">
    <div class="col-sm mb-4 mb-sm-0">
      <div class="d-flex align-items-center gap-4">
        <a routerLink="/subscribe"><img [src]="'/assets/img/pic/my-progress' + (user.premium ? '-premium' : '') + '.png'" width="71" height="63"/></a>
        <div>
          <p class="small mb-1" style="line-height: 16px;">
            <a *ngIf="!user.premium" routerLink="/subscribe" class="text-success small" translate>Free</a>
            <strong *ngIf="user.premium" class="text-premium small" translate>Premium</strong>
          </p>
          <p *ngIf="user.name" class="mb-1 fw-bold">{{user.name}}</p>
          <p class="small subheader mb-0">
            <app-icon icon="mail" size="sm" class="i"/>{{user.email}}
          </p>
        </div>
      </div>
    </div>
    <div class="col-sm text-sm-end">
      <a routerLink="/audio" class="btn btn-secondary subtitle fw-normal">
        <span class="i" translate>Audio Recordings</span>
        <app-icon icon="right"/>
      </a>
    </div>
  </div>

  <h2 class="h1 mb-4" translate>Achievements</h2>

  <app-loading *ngIf="loading"/>

  <div class="scrollable mb-4 mb-lg-6 fs-0">
    <div class="d-inline-block">
      <div class="d-flex gap-3">
        <div class="card" *ngFor="let item of achieves">
          <a class="card-link" href="#" (click)="showAchieve(item)"></a>
          <div class="card-body p-0" [ngClass]="{'active': item.active}">
            <img class="card-img mb-0" [attr.src]="'/assets/img/achieves/' + (item.active ? 'active/' : '') + item.id + '.svg'" alt="" width="100" height="115" style="min-width: 100px" />
            <div class="card-img-overlay header">
              <p class="card-text text-center text-nowrap" style="text-overflow: inherit;" [ngClass]="{'subtitle': !item.active}"><small>{{ item.name }}</small></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-graphs-tooltip/>

  <h2 class="h1 mb-4" translate>My progress</h2>
  <div #graphs class="scrollable" (scroll)="onScroll($event)" (window:resize)="onResize()">
    <button *ngIf="scroll > 0" class="btn d-none d-lg-flex align-items-lg-center justify-content-lg-end rotate-180"
            style="border-radius: 0; position: absolute; z-index: 2; height: 264px; width: 152px; background: linear-gradient(270deg, #01030B 0%, rgba(1, 3, 11, 0) 100%);"
            (click)="onScrollButton(-1)"
    ><app-icon icon="right-lg" size="lg"></app-icon></button>

    <button *ngIf="scroll + scrollWidth < scrollInnerWidth" class="btn d-none d-lg-flex align-items-lg-center justify-content-lg-end"
            style="border-radius: 0; position: absolute; z-index: 2; height: 264px; right: 0; width: 152px; background: linear-gradient(270deg, #01030B 0%, rgba(1, 3, 11, 0) 100%);"
            (click)="onScrollButton(1)"
    ><app-icon icon="right-lg" size="lg"></app-icon></button>

    <div class="d-inline-block">
<!--      <div #plots id="plots" class="d-flex gap-3"></div>-->
      <div class="d-flex gap-3">
        <div *ngFor="let item of chartList" class="card" role="button" (click)="showProgressDetailsModal(item)">
          <div class="card-body p-4">
            <label class=".h4 d-flex align-items-center justify-content-between" role="button">
              <strong>{{item.name}}</strong>
              <app-icon *ngIf="!item.premium || user.premium else noPremium" icon="right" style="color: var(--primary-110)"/>
              <ng-template #noPremium>
                <button routerLink="/subscribe" class="btn btn-xs btn-premium" translate>Premium</button>
              </ng-template>
            </label>
            <p class="mb-3 d-flex align-items-center justify-content-between">
              <span class="note">{{item.description}}</span>
              <strong *ngIf="item.proc" class="small">
                <span class="me-2" [ngClass]="{'text-success': item.proc >= 75, 'text-warning': item.proc >= 50 && item.proc < 75, 'text-danger': item.proc < 50}">{{
                  item.proc > 66 ? 'HIGH' : (item.proc >= 33 ? 'MEDIUM' : 'LOW')
                  }}</span><span>{{item.proc | number:'1.0-0'}}%</span>
              </strong>
            </p>
            <div class="position-relative">
              <div *ngIf="!item.proc" class="panel panel-note">
                {{(!item.premium || user.premium ? 'Not enough data. Record your voice during the practice to enable progress analysis.' : 'Please activate Premium to get access to progress analysis.') | translate}}
              </div>
              <highcharts-chart [Highcharts]="Highcharts" [options]="item.options"></highcharts-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<!--
  <p *ngIf="!loading && !managerLink">
    <a href="javascript:void(0)" (click)="toPaywall()" class="btn btn-primary">{{ 'Page.Home.subscribe' | translate }}</a>
  </p>

  <p *ngIf="!loading && managerLink">
    <a href="javascript:void(0)" (click)="toManager()" class="btn btn-primary">{{ 'Page.Home.manager' | translate }}</a>
  </p>-->
</div>
