<button tabindex="-1" class="btn btn-sm subtitle" [ngClass]="class" [ngbTooltip]="loadTooltip" [placement]="placement" (shown)="onTip()">
  <app-icon icon="question" size="md"/>
</button>

<ng-template #loadTooltip>
<!--  <div [innerHTML]="'Page.Trainings.ai_tooltip'|translate|nl2br"></div>-->

  <app-loading *ngIf="showLoading else output"/>

  <ng-template #output>
    <div [innerHTML]="tipText|nl2br"></div>
    <ng-content/>
  </ng-template>
</ng-template>
