<div class="modal-header">
  <h3 class="modal-title">{{ 'App install required' | translate }}</h3>
  <button class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <p class="mb-1">
    {{ 'This function is only available in the mobile application.' | translate }}
  </p>
</div>
<div class="modal-footer d-block">
  <a href="#" (click)="install()" class="btn btn-primary d-block">{{ 'Install' | translate }}</a>
  <br />
  <div>
    <app-download></app-download>
  </div>
</div>
