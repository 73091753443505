import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-icon',
  template: `<svg [attr.width]="width" [attr.height]="height" class="si si-{{icon}}{{!width ? ' si-' + size : ''}}" [style.transform]="'rotate(' + rotate + 'deg)'"><use [attr.href]="'#s-' + icon"></use></svg>`
})
export class IconComponent {
  @Input() icon: string;
  @Input() size: '' | 'sm' | 'md' | 'lg' = '';

  @Input() width: number;
  @Input() height: number;

  @Input() rotate: number;
}
