import { Component, OnInit } from '@angular/core';
import {install} from "../../_helpers/lib";

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html'
})
export class NotfoundComponent implements OnInit {

  constructor(
  ) {
  }

  ngOnInit(): void {
  }

  protected readonly install = install;
}
