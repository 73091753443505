<div class="modal-header">
  <button class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body text-center py-0">
  <p *ngIf="icon">
    <app-icon [icon]="icon" size="lg" [ngClass]="'text-' + type"/>
  </p>
  <p class="mb-0" translate>{{message}}</p>
</div>
<div class="modal-footer gap-3 flex-nowrap">
  <button class="btn btn-md btn-default subtitle fw-normal w-50" (click)="activeModal.dismiss('Cross click')" translate>Cancel</button>
  <button class="btn btn-md w-50" [ngClass]="'btn-' + type" (click)="onSuccess()" translate>{{ok}}</button>
</div>
