import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-skip-voice-modal',
  templateUrl: './skip-voice-modal.component.html',
  styleUrls: ['./skip-voice-modal.component.css']
})
export class SkipVoiceModalComponent implements OnInit {

  @Output() response = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
  }

  protected readonly localStorage = localStorage;

  onSuccess() {
    this.activeModal.dismiss('Cross click');
    this.response.next(true);
  }
}
