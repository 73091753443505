// deprecated

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import * as Plyr from 'plyr';
import {Audio, Training, User} from '../../../_business';
import {AuthService} from '../../../_services/auth.service';

@Component({
  selector: 'app-finish-modal',
  templateUrl: './finish-modal.component.html',
  styleUrls: ['./finish-modal.component.css']
})
export class FinishModalComponent implements OnInit {

  @Input() public training: Training;

  private user: User = null;
  private trainingKeyName = 'tr_days_';
  private trainingDays = [ 98, 99, 100, 101 ];

  public numb: number;
  public btn = 'Got it';
  public posttip: string;
  public step = 0;

  // @Output() response = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
    private auth: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.user = this.auth.user;

    // this.training.id = 101;
    this.step = this.trainingDays.indexOf(+this.training.id) + 1;

    if (this.step > 0) {
      const ids = JSON.parse(localStorage.getItem(this.trainingKeyName + this.user.id) || '[]');

      if (ids.find(x => x === +this.training.id))
        this.step = 0;
      else {
        this.numb = 0;
        this.btn = 'We\'ll continue tomorrow';

        switch (this.step) {
          case 1:
            this.posttip = 'Shhh! Your skills have begun to develop, don’t let this magical moment scare you away! 3 days left to reach the reward!';
            break;

          case 2:
            this.posttip = 'Another step towards a beautiful voice has been taken! Don\'t give up and the results will be phenomenal. 2 days left until the reward!';
            break;

          case 3:
            this.posttip = 'You\'ve already walked most of the way. How do you like it? Do you feel the difference? Every day you will begin to notice more and more changes. 1 day left until the reward!';
            break;

          default:
            this.posttip = '';
            this.btn = 'Got it';
            break;
        }

        ids.push(this.training.id);
        localStorage.setItem(this.trainingKeyName + this.user.id, JSON.stringify(ids));
      }
    }

    if (!this.posttip) {
      this.posttip = this.training.posttip;
      this.numb = Math.floor(Math.random() * 10) + 1;
    }
  }

}
