import {Component, OnInit} from '@angular/core';
import {DataService} from '../../_services/data.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {
  constructor(
    private dataService: DataService,
  ) {

  }

  ngOnInit(): void {
  }
}
