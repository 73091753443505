import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../_services/auth.service';
import {install} from "../../_helpers/lib";

@Component({
  selector: 'app-go-confirm-page',
  templateUrl: './go-confirm.component.html',
  styleUrls: ['./go-confirm.component.css']
})
export class GoConfirmComponent implements OnInit {

  public result = false;
  public loading = true;

  constructor(
    public translate: TranslateService,
    private route: ActivatedRoute,
    private auth: AuthService,
  ) {
  }

  ngOnInit(): void {
    this._parseParams();
  }

  private _parseParams() {

    const uid = this.route.snapshot.paramMap.get('uid');

    if (uid) {
      this.auth.goConfirm(uid).subscribe(() => {
        this.result = true;
      }).add(() => this.loading = false);
    }
  }

  protected readonly install = install;
}
