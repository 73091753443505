import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';

import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";

@Injectable()
export class LocalizationInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const lang = localStorage.getItem('currentLang');
    const width = window.innerWidth.toString();

    if (lang) {

      const headers = {
        'X-Version': environment.xVersion,
        // 'X-Timezone-Offset': (new Date()).getTimezoneOffset().toString(),
        'X-Screen-Width': width,
        'X-Language': lang,
      }

      request = request.clone(
        {
          setHeaders: headers
        }
      );

    }

    return next.handle(request);
  }
}
