<div class="container">
  <img *ngIf="loading && !error" width="50" height="50" src="/assets/img/loader.gif" alt=""/>

  <div *ngIf="success">
    <h1>{{ 'Page.Restore.success' | translate }}</h1>
    <div class="header page-info">
      <p>{{ 'Page.Restore.fraze1' | translate }}</p>
    </div>
  </div>

  <div *ngIf="!success && !loading">
    <h1>{{ 'Page.Restore.title' | translate }}</h1>

    <div class="form-block">
      <form [formGroup]="dataForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="position-relative">
            <svg-icon src="/assets/img/icons/eye.svg" class="eye" (click)="onViewPassword()"></svg-icon>
            <input [attr.type]="passwordType" formControlName="password" maxlength="64" class="form-control" autocomplete="current-password" placeholder="{{ 'New password' | translate }}" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">{{ 'Validator.require' | translate }}</div>
              <div *ngIf="f.password.errors.pattern">{{ 'Validator.passwordLatin' | translate }}</div>
              <div *ngIf="f.password.errors.minlength">{{ 'Validator.passwordMin' | translate }}</div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="form-group position-relative">
            <svg-icon src="/assets/img/icons/eye.svg" class="eye" (click)="onViewPassword()"></svg-icon>
            <input [attr.type]="passwordType" id="confirm" formControlName="confirm" class="form-control" placeholder="{{ 'Password confirmation' | translate }}" [ngClass]="{ 'is-invalid': submitted && f.confirm.errors }" />
            <div *ngIf="submitted && f.confirm.errors" class="invalid-feedback">
              <div *ngIf="f.confirm.errors.required">{{ 'Validator.require' | translate }}</div>
              <div *ngIf="f.confirm.errors.matchPassword">{{ 'Validator.passwordMatch' | translate }}</div>
            </div>
          </div>
        </div>

        <button [disabled]="loading" type="submit" class="btn btn-primary w-100">
          {{ 'Page.Restore.create' | translate }}
          <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
      </form>
    </div>
  </div>

  <div class="container" *ngIf="error">
    <h2>{{ 'Page.Restore.negativeTitle' | translate }}</h2>
    <div class="form-block">
      <div class="header page-info">
        <p>{{ 'Page.Restore.negativeText' | translate }}</p>
      </div>
      <p><a href="#" (click)="install()" class="btn btn-primary w-100">{{ 'Page.Restore.button' | translate }}</a></p>
      <app-download></app-download>
    </div>
  </div>

<!--  <div *ngIf="error" class="alert alert-danger">{{ error | translate }}</div>-->
</div>
