import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {Intensive} from 'src/app/_business';
import {DataService} from 'src/app/_services/data.service';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css']
})
export class CourseComponent implements OnInit {

  @Input() intensive: Intensive = null;
  @Output() response = new EventEmitter();

  public movieUrl: string;

  constructor(
    private dataService: DataService,
    private titleService: Title,
    private metaService: Meta,
  ) {
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.intensive.name);
    this.metaService.addTags([
      {name: 'description', content: this.intensive.short_description},
    ]);

    this.dataService.getIntensiveVideo(this.intensive.id).subscribe(
      (data) => {
        this.movieUrl = data.url;
      }
    );

    this.dataService.getIntensiveTrainings(this.intensive.id).subscribe(
      (data) => {
        this.intensive.trainings = data;
      }
    );
  }
}
