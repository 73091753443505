import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../../_services/auth.service';
import {User} from '../../../_business';
import {Subscription} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {environment} from "../../../../environments/environment";
import {install} from "../../../_helpers/lib";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy {

  private userSubscription: Subscription = null;
  private eventsSubscription: Subscription = null;

  public area: string = null;
  public user: User = null;
  public isTranslated = false;

  public readonly helpLink = environment.helpLink;

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.user = this.auth.user;
    this.userSubscription = this.auth.getCurrentUser().subscribe((user: User) => this.user = user);

    this.translate.onLangChange.subscribe(() => {
      this.isTranslated = true;
    });

    this.eventsSubscription = this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (this.route.firstChild.snapshot.data) {
            this.area = this.route.firstChild.snapshot.data.area;
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    this.eventsSubscription.unsubscribe();
  }

  protected readonly install = install;
}
