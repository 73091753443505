<div class="container">
  <h2>{{ 'My coach' | translate }}</h2>

  <div class="row align-items-center mb-3">
    <div class="col">
      <div class="card notice">
        <div class="card-body">
          <div class="media">
            <img src="/assets/img/icons/brain.svg" class="i me-4 mt-2" width="43" />
            <div class="media-body">
              <p>{{ 'The diaphragm is an important element of the speech apparatus. When the diaphragm is developed, the voice is healthy and stable.' | translate }}</p>
              {{ 'Pay attention to this area.' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div id="spider"></div>
<!--      <div [chart]="chartOptionsSpider"></div>-->
    </div>
  </div>

  <app-plan></app-plan>

  <div *ngIf="error" class="alert alert-danger">{{ error | translate }}</div>
</div>
