<div class="container page-content">
    <img *ngIf="loading && !error" width="50" height="50" src="/assets/img/loader.gif" alt=""/>


    <div *ngIf="!loading && !error">
      <h3 class="mb-4">{{ 'Page.Payment.title' | translate }}</h3>
      <div class="header page-info">
          <p>{{ 'Page.Payment.fraze' | translate }}</p>
        </div>

    </div>

    <div *ngIf="error">
      <h3 class="mb-4">{{ 'Page.Payment.negativeTitle' | translate }}</h3>
      <div class="header page-info">
        <p>{{ "If you think it's a mistake, please, let us know" | translate }}</p>
      </div>
    </div>

  </div>
