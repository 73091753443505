<h1 translate>Explore</h1>

<ul ngbNav #nav="ngbNav" class="nav-tabs mb-4">
  <li ngbNavItem>
    <a routerLink="/lessons" routerLinkActive="active" ngbNavLink translate>Trainings</a>
  </li>
  <li ngbNavItem>
    <a routerLink="/lectures" routerLinkActive="active" ngbNavLink translate>Talks</a>
  </li>
  <li *ngIf="['en', 'es', 'ru', 'uk'].includes(translate.currentLang)" ngbNavItem>
    <a routerLink="/podcasts" routerLinkActive="active" ngbNavLink translate>Podcasts</a>
  </li>
</ul>

<!--<ul class="nav-tabs mb-4 nav">
  <li class="nav-item">
    <a class="nav-link" routerLink="/lessons" routerLinkActive="active" translate>Trainings</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/lectures" routerLinkActive="active" translate>Talks</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/podcasts" routerLinkActive="active" translate>Podcasts</a>
  </li>
</ul>-->
