import {Component, Input, OnInit} from '@angular/core';
import {Tip} from "../../../_business/tip.model";
import {DataService} from "../../../_services/data.service";
import {NgbTooltipConfig} from "@ng-bootstrap/ng-bootstrap";
import {PlacementArray} from "@ng-bootstrap/ng-bootstrap/util/positioning";

@Component({
  selector: 'app-tip-load',
  templateUrl: './tip-load.component.html',
  styleUrls: ['./tip-load.component.css']
})
export class TipLoadComponent implements OnInit{

  @Input() code: string;
  // 'plan' | 'analysys' | 'spider'
  // 'voicetype' | 'volumerange' | 'vocalrange' | 'pitchgraph'
  // 'ai' | 'pitch' | 'volume'
  // 'intensives' | 'courses' | 'podcasts' | 'trainings'

  @Input() placement: PlacementArray;
  @Input() class = 'question';

  public tipText: string;
  public loading: boolean;
  public showLoading = true;

  constructor(
    private dataService: DataService,
    private tooltipConfig: NgbTooltipConfig
  ) {
    if (!this.placement)
      this.placement = tooltipConfig.placement;
  }

  ngOnInit(): void {
  }

  public onTip() {
    if (this.loading || this.tipText)
      return;

    this.loading = this.showLoading = true;

    this.dataService.getTipById(this.code)
      .subscribe({
        next: (tip: Tip) => this.tipText = tip.text,
      })
    .add(() => this.loading = this.showLoading = false);
  }

}
