import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../_services/auth.service';


@Injectable()
export class UserInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.auth.user && this.auth.user.token) {
      // console.log('add token', this.auth.user.token);
      if (!request.headers.has('Authorization'))
        request = request.clone(
          {
            setHeaders: {Authorization: 'Bearer ' + this.auth.user.token}
          }
        );

      // request = request.clone({
      //   setHeaders: {
      //     'Token-key': `${currentUser.token}`
      //     // Authorization: `Bearer ${currentUser.token}`
      //   }
      // });
    }

    return next.handle(request);
  }
}
