import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Achieve} from '../../../_business/achieve.model';

@Component({
  selector: 'app-achieve-modal',
  templateUrl: './achieve-modal.component.html',
  styleUrls: ['./achieve-modal.component.css']
})
export class AchieveModalComponent implements OnInit {

  @Input() public achieve: Achieve;

  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
  }

}
