<ngx-masonry [options]="masonryOptions" ordered="true">
  <div *ngFor="let item of list" ngxMasonryItem class="card grid-item">

    <div [ngClass]="{'opacity-0': item.hovered}">
      <div class="card-title overflow" role="button" (click)="onHoverTraining(item)">{{ item.name }}</div>

      <a *ngIf="user.premium else noPremium" href="javascript:void(0)" class="card-sign" (click)="onFavourite(item)">
        <fa-icon [style.color]="item.favorited ? '#fa2f2e' : ''" [icon]="item.favorited ? fasHeart : farHeart"></fa-icon>
      </a>
      <ng-template #noPremium>
        <button routerLink="/subscribe" class="btn btn-xss btn-premium card-sign" translate>Premium</button>
      </ng-template>

      <div class="card-body pt-0" role="button" (click)="onHoverTraining(item)">
        <img class="card-img" [attr.src]="item.images.main.url" alt="" width="570" height="320" />
        <p class="card-text subheader">{{ item.short_description }}</p>

        <button class="btn btn-md w-100" [ngClass]="{'btn-primary': !item.paid, 'btn-default subheader fw-normal': item.paid}">
<!--          <app-icon *ngIf="item.paid" icon="lock" class="i"></app-icon>-->
          {{ 'Start Training' | translate }}
        </button>
      </div>
    </div>

<!--    <div class="card-body hovered w-100" *ngIf="item.hovered">
      <button role="button" class="btn-close" (click)="item.hovered = false"></button>
      <div class="card-title overflow p-0 mb-4">{{ item.name }}</div>
      <div class="row controls align-content-center h-100 text-center mb-4">
        <div class="col">
          <a href="javascript:void(0)" (click)="onShowAudios(item)">
            <svg-icon src="/assets/img/icons/audio.svg" [svgStyle]="{ 'width.px': 32, 'height.px': 32 }"></svg-icon>
            <div class="note" translate>Audio recordings</div>
          </a>
        </div>
        <div class="col">
          <a href="javascript:void(0)" (click)="onShowComments(item)">
            <svg-icon src="/assets/img/icons/help.svg"></svg-icon>
            <div class="note" translate>Questions for the coach</div>
          </a>
        </div>
        <div class="col">
          <a href="javascript:void(0)" (click)="onWatchTraining(item)">
            <svg-icon src="/assets/img/icons/youtube.svg"></svg-icon>
            <div class="note" translate>Watch video</div>
          </a>
        </div>
      </div>
      <a [routerLink]="['/training', item.id]" class="btn btn-primary btn-md w-100" translate>
        Start Training
      </a>
    </div>-->

  </div>
</ngx-masonry>
