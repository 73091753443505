<div class="container">
  <h1 class="d-flex align-items-center">
    <a routerLink="/profile" class="btn p-0 me-3 subtitle"><app-icon icon="left"/></a>
    {{ 'Audio Recordings' | translate }}
  </h1>

  <app-audios [list]="audios" [loading]="loading"></app-audios>

<!--  <div *ngIf="error" class="alert alert-danger">{{ error | translate }}</div>-->
</div>
