<div *ngIf="progress <= 100" role="dialog" tabindex="-1" aria-modal="true" class="d-block fullscreen modal">
  <div role="document" class="modal-dialog modal-fullscreen">
    <div class="modal-content justify-content-lg-center">

      <div class="mw-lg-2">
        <div class="animation mb-lg-4">
          <div class="bg">
            <ng-lottie width="100%" height="auto" [options]="{path: '/assets/lottie/processing_bg.json'}" />
          </div>
          <canvas width="390" height="844" riv="data_proccessing_loader">
            <riv-state-machine name="data_proccessing_loader" play/>
          </canvas>
        </div>
        <p class="subheader small text-center" translate>
          Data processing in progress<span class="dots"><span>.</span><span>.</span><span>.</span></span>
        </p>
        <div class="progress progress-lg d-none d-lg-flex">
          <div class="progress-bar" role="progressbar" [style.width]="progress + '%'">{{progress}}%</div>
        </div>
      </div>

    </div>
  </div>
</div>


<ng-container *ngIf="progress > 100">
<div class="container pb-0">
  <div class="d-none d-lg-block">
    <button role="button" class="btn btn-close" (click)="onFinish()"></button>
    <h1>Results</h1>
  </div>

  <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs mb-4 d-lg-none" (activeIdChange)="onChangeTab($event)" (shown)="onChangeTab($event)">
    <li ngbNavItem [ngbNavItem]="'results'">
      <button class="text-uppercase fw-bold" ngbNavLink translate>Results</button>
      <ng-template ngbNavContent>
        <canvas riv="results_illustration" class="d-block mw-100 mx-auto" width="390" height="160">
          <riv-state-machine name="results_illustration" play/>
        </canvas>
      </ng-template>
    </li>
    <li ngbNavItem [ngbNavItem]="'audios'">
      <button class="text-uppercase fw-bold" ngbNavLink translate>Audios</button>
      <ng-template ngbNavContent>
        <canvas riv="audio_illustration" class="d-block mw-100 mx-auto" width="390" height="160">
          <riv-state-machine name="audio_illustration" play>
            <riv-input name="playing" [value]="true"></riv-input>
          </riv-state-machine>
        </canvas>
      </ng-template>
    </li>
  </ul>
  <div class="d-lg-none" [ngbNavOutlet]="nav"></div>
</div>

<div class="container pt-4 pt-lg-0 bg-80 bg-lg-transparent mob-height">

  <ngx-masonry class="panels" [options]="masonryOptions" ordered="true">

    <div ngxMasonryItem class="panel-lg bg-lg-80 mb-lg-4" [ngClass]="{'d-none d-lg-flex': activeTab != 'audios'}">
      <p class="fw-bold display-4 text-center text-warning mb-lg-0" translate>Your recordings</p>

      <div class="panel mb-6 mb-lg-0">
        <ng-container *ngIf="recordings?.skipped else playAudio">
          <div class="d-flex gap-3 align-items-center">
            <app-icon icon="warning" class="text-primary"/>
            {{'Record your voice before and after the training to analize the changes' | translate}}
          </div>
        </ng-container>

        <ng-template #playAudio>
          <app-audio-training *ngIf="recordings?.before" before="true" [audio]="recordings.before" [duration]="recordings.beforeDuration" (response)="onPlayerInit()"/>
          <hr class="my-0"/>
          <app-audio-training *ngIf="recordings?.after" [audio]="recordings.after" [duration]="recordings.afterDuration" (response)="onPlayerInit()"/>
        </ng-template>
      </div>
    </div>

    <app-loading *ngIf="!testResult else results" ngxMasonryItem class="mb-6" [ngClass]="{'d-none d-lg-block': activeTab != 'results'}"/>

    <ng-template #results>
      <div ngxMasonryItem class="panel mb-4" [ngClass]="{'d-none d-lg-flex': activeTab != 'results'}">
        <div class="d-flex align-items-center justify-content-between">
          <h4 class="mb-0" translate>AI Rating</h4>
          <app-tip-load code="ai" class="p-0 d-flex"/>
        </div>
        <hr class="my-0"/>
        <div class="item">
          <label class="d-flex align-items-center gap-3 text-nowrap">
            <span class="w-100" translate>Masculinity</span>
            <small>{{testResult.ai.male}}%</small>
            <small badge>{{testResult.ai.male_delta}}%</small>
          </label>
          <div class="progress progress-sm">
            <div class="progress-bar" role="progressbar" [style.width]="testResult.ai.male + '%'" [style.background-color]="'#6DBFF5'"></div>
          </div>
        </div>

        <div class="item">
          <label class="d-flex align-items-center gap-3 text-nowrap">
            <span class="w-100" translate>Femininity</span>
            <small>{{testResult.ai.female}}%</small>
            <small badge>{{testResult.ai.female_delta}}%</small>
          </label>
          <div class="progress progress-sm">
            <div class="progress-bar" role="progressbar" [style.width]="testResult.ai.female + '%'" [style.background-color]="'#E186C7'"></div>
          </div>
        </div>

        <div class="item">
          <label class="d-flex align-items-center gap-3 text-nowrap">
            <span class="w-100" translate>Confidence</span>
            <small>{{testResult.ai.confidence}}%</small>
            <small badge>{{testResult.ai.confidence_delta}}%</small>
          </label>
          <div class="progress progress-sm">
            <div class="progress-bar" role="progressbar" [style.width]="testResult.ai.confidence + '%'" [style.background-color]="'#FF9D42'"></div>
          </div>
        </div>

        <div class="item">
          <label class="d-flex align-items-center gap-3 text-nowrap" [ngClass]="{'mb-0': !user?.premium}">
            <span class="w-100" translate>Weak</span>
            <ng-container *ngIf="user?.premium else noPremium">
              <small>{{testResult.ai.weak}}%</small>
              <small badge>{{testResult.ai.weak_delta}}%</small>
            </ng-container>
          </label>
          <div *ngIf="user?.premium" class="progress progress-sm">
            <div class="progress-bar" role="progressbar" [style.width]="testResult.ai.weak + '%'" [style.background-color]="'#B89641'"></div>
          </div>
        </div>

        <div class="item">
          <label class="d-flex align-items-center gap-3 text-nowrap" [ngClass]="{'mb-0': !user?.premium}">
            <span class="w-100" translate>Monotonous</span>
            <ng-container *ngIf="user?.premium else noPremium">
              <small>{{testResult.ai.monotonous}}%</small>
              <small badge>{{testResult.ai.monotonous_delta}}%</small>
            </ng-container>
          </label>
          <div *ngIf="user?.premium" class="progress progress-sm">
            <div class="progress-bar" role="progressbar" [style.width]="testResult.ai.monotonous + '%'" [style.background-color]="'#9BE760'"></div>
          </div>
        </div>
      </div>

      <ng-template #noPremium>
        <button routerLink="/subscribe" class="btn btn-xs btn-premium" translate>Premium</button>
      </ng-template>

      <div *ngIf="user?.premium" ngxMasonryItem class="panel mb-4" [ngClass]="{'d-none d-lg-flex': activeTab != 'results'}">
        <div class="d-flex align-items-center justify-content-between">
          <h4 class="mb-0" translate>Pitch</h4>
          <app-tip-load code="pitch" class="p-0 d-flex"/>
        </div>
        <hr class="my-0"/>
        <div>
          <label class="d-flex align-items-center gap-3 text-nowrap">
            <span class="w-100" translate>Average</span>
            <small>{{testResult.pitch.average}} Hz</small>
            <small badge>{{testResult.pitch.average_delta}}%</small>
          </label>
          <label class="d-flex align-items-center gap-3 text-nowrap">
            <span class="w-100" translate>Median</span>
            <small>{{testResult.pitch.median}} Hz</small>
            <small badge>{{testResult.pitch.median_delta}}%</small>
          </label>
          <label class="d-flex align-items-center gap-3 text-nowrap">
            <span class="w-100" translate>High</span>
            <small>{{testResult.pitch.high}} Hz</small>
            <small badge level="high">{{testResult.pitch.high_delta}}%</small>
          </label>
          <label class="d-flex align-items-center gap-3 text-nowrap mb-0">
            <span class="w-100" translate>Low</span>
            <small>{{testResult.pitch.low}} Hz</small>
            <small badge level="low">{{testResult.pitch.low_delta}}%</small>
          </label>
        </div>
        <hr class="my-0"/>

        <div class="item">
          <label class="d-flex align-items-center gap-3 text-nowrap">
            <span class="w-100" translate>Male</span>
            <small>{{testResult.male}}%</small>
            <small badge>{{testResult.male_delta}}%</small>
          </label>
          <div class="progress progress-sm">
            <div class="progress-bar" role="progressbar" [style.width]="testResult.male + '%'"></div>
          </div>
        </div>

        <div class="item">
          <label class="d-flex align-items-center gap-3 text-nowrap">
            <span class="w-100" translate>Female</span>
            <small>{{testResult.female}}%</small>
            <small badge>{{testResult.female_delta}}%</small>
          </label>
          <div class="progress progress-sm">
            <div class="progress-bar" role="progressbar" [style.width]="testResult.female + '%'"></div>
          </div>
        </div>

        <div class="item">
          <label class="d-flex align-items-center gap-3 text-nowrap">
            <span class="w-100" translate>Range</span>
            <small>{{testResult.range}}%</small>
            <small badge>{{testResult.range_delta}}%</small>
          </label>
          <div class="progress progress-sm">
            <div class="progress-bar" role="progressbar" [style.width]="testResult.range + '%'"></div>
          </div>
        </div>
      </div>

      <div *ngIf="user?.premium" ngxMasonryItem class="panel mb-6" [ngClass]="{'d-none d-lg-flex': activeTab != 'results'}">
        <div class="d-flex align-items-center justify-content-between">
          <h4 class="mb-0" translate>Volume</h4>
          <app-tip-load code="volume" class="p-0 d-flex"/>
        </div>
        <hr class="my-0"/>
        <div>
          <label class="d-flex align-items-center gap-3 text-nowrap">
            <span class="w-100" translate>Average</span>
            <small>{{testResult.volume.average}} Hz</small>
            <small badge>{{testResult.volume.average_delta}}%</small>
          </label>
          <label class="d-flex align-items-center gap-3 text-nowrap">
            <span class="w-100" translate>Median</span>
            <small>{{testResult.volume.median}} Hz</small>
            <small badge>{{testResult.volume.median_delta}}%</small>
          </label>
          <label class="d-flex align-items-center gap-3 text-nowrap">
            <span class="w-100" translate>High</span>
            <small>{{testResult.volume.high}} Hz</small>
            <small badge level="high">{{testResult.volume.high}}%</small>
          </label>
          <label class="d-flex align-items-center gap-3 text-nowrap mb-0">
            <span class="w-100" translate>Low</span>
            <small>{{testResult.volume.low}} Hz</small>
            <small badge level="low">{{testResult.volume.low}}%</small>
          </label>
        </div>
        <hr class="my-0"/>
        <label class="d-flex align-items-center gap-3 text-nowrap mb-0">
          <span class="w-100" translate>Environment</span>
          <small>{{testResult.volume.environment}} Hz</small>
          <small badge>{{testResult.volume.environment_delta}}%</small>
        </label>
      </div>
    </ng-template>

  </ngx-masonry>

  <button class="btn btn-warning w-100 d-lg-none" (click)="onFinish()" translate>Got it</button>

</div>
</ng-container>
