<footer *ngIf="enabled" class="foot">
  <a routerLink="/lessons" class="btn" [ngClass]="{'subtitle': !['lessons', 'lectures', 'podcasts'].includes(area)}">
    <app-icon class="d-block" icon="search" size="md"/>
    {{'Explore'|translate}}
  </a>
  <a routerLink="/profile" class="btn" [ngClass]="{'subtitle': !['profile', 'subscribe', 'settings'].includes(area)}">
    <app-icon class="d-block" icon="user" size="md"/>
    {{'Profile'|translate}}
  </a>
</footer>
