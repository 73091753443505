import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {AuthService} from '../../_services/auth.service';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.css']
})
export class UnsubscribeComponent implements OnInit {

  public result = false;
  public loading = true;

  constructor(
      private route: ActivatedRoute,
      private auth: AuthService,
  ) {
  }

  ngOnInit(): void {
      this._parseParams();
  }

  private _parseParams() {

      const memberId = this.route.snapshot.paramMap.get('memberId');
      const code = this.route.snapshot.paramMap.get('code');

      if (memberId && code) {
            this.auth.unsubscribeEmail(memberId, code).subscribe(
                () => this.result = true,
                (err) => this.result = false
            )
            .add(() => this.loading = false);
        }
  }
}
