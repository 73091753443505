import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {AuthService} from '../../_services/auth.service';

@Component({
  selector: 'app-go-unsubscribe',
  templateUrl: './go-unsubscribe.component.html',
  styleUrls: ['./go-unsubscribe.component.css']
})
export class GoUnsubscribeComponent implements OnInit {

  public result = false;
  public loading = true;

  constructor(
      private route: ActivatedRoute,
      private auth: AuthService,
  ) {
  }

  ngOnInit(): void {
      this._parseParams();
  }

  private _parseParams() {

      const uid = this.route.snapshot.paramMap.get('uid');

      if (uid) {
            this.auth.goUnsubscribeEmail(uid).subscribe(
                () => this.result = true,
                (err) => this.result = false
            )
            .add(() => this.loading = false);
        }
  }
}
