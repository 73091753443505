<div class="container">
  <h1 class="d-flex align-items-center mb-6">
    <a routerLink="/auth" class="btn p-0 me-3 subtitle"><app-icon icon="left"/></a>
    {{ 'Page.Reconfirm.title' | translate }}
  </h1>

  <img *ngIf="loading && !error" width="50" height="50" src="/assets/img/loader.gif" alt=""/>

  <div *ngIf="success">
    <h2>{{ 'Page.Reconfirm.success' | translate }}</h2>
    <div class="header page-info">
      <p>{{ 'Page.Reconfirm.fraze1' | translate }}</p>
    </div>
  </div>

  <div *ngIf="!success && !loading">
    <div class="form-block">
      <p class="mb-6 subheader">
        {{ 'Enter the e-mail that you entered during sing up, and we will send you a link to confirm.' | translate }}
      </p>

      <form [formGroup]="dataForm" (ngSubmit)="onSubmit()">
        <div class="form-group mb-6">
          <div class="position-relative">
            <input formControlName="email" maxlength="64" class="form-control" autocomplete="email" placeholder="E-mail" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.email">{{ 'Check the spelling of your email address' | translate }}</div>
            </div>
          </div>
        </div>

        <button [disabled]="loading" type="submit" class="btn btn-primary w-100">
          {{ 'Restore' | translate }}
          <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
      </form>
    </div>
  </div>

  <div *ngIf="error" class="alert alert-danger">{{ error | translate }}</div>
</div>
