// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  debug: false,
  production: false,
  defaultLanguage: 'en',
  xVersion: '81000',
  mixpanelToken: 'a185b26333e37fa2d5c5db6f5315c678',
  api: 'https://vocalimage-api.bessia.net/',
  web: 'https://vocalimage-www.bessia.net/',
  onboardingLink: 'https://onboarding.vocalimage.net/dash',
  helpLink: 'https://help.vocalimage.app/',
  storeLink: {
    google: 'https://play.google.com/store/apps/details?id=app.vocalimage.bessia',
    apple: 'https://apps.apple.com/app/id1535324205'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
