import {Component, Input, OnInit} from '@angular/core';
import {DataService} from 'src/app/_services/data.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Training, Comment} from '../../../_business';
import {DataForm} from '../../../_helpers/dataForm';
import {FormBuilder, Validators} from '@angular/forms';
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {ConfirmModalComponent} from "../confirm-modal/confirm-modal.component";

@Component({
  selector: 'app-comments-modal',
  templateUrl: './comments-modal.component.html',
  styleUrls: ['./comments-modal.component.css']
})
export class CommentsModalComponent extends DataForm implements OnInit {

  @Input() public training: Training;

  public loadingList = true;
  public list: Comment[];

  // private translates;

  protected readonly fasPaperPlane = faPaperPlane;

  constructor(
    private dataService: DataService,
    public activeModal: NgbActiveModal,
    public formBuilder: FormBuilder,
    // private translate: TranslateService,
    private modalService: NgbModal,
  ) {
    super(formBuilder);

    // this.translate.get(['Confirm deletion']).subscribe(
    //   res => {
    //     this.translates = res;
    //   }
    // );
  }

  public initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        training_id: this.training.id,
        content: [
          null, [
            Validators.required
          ]]
      });

    this.dataService.getTrainingComments(this.training.id).subscribe(
      (data) => {
        this.list = data;
      }
    )
      .add(
        () => this.loadingList = false
      );
  }

  public onSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    this.dataService.addComment(this.dataForm.value).subscribe(
      (data) => {
        this.dataForm.get('content').setValue('', { emitEvent: false });
        this.list.unshift(data);
      }
    )
      .add(
        () => this.onStopSubmit()
      );
  }

  public onDelete(comment: Comment, index: number) {
    const modalConfirmRef = this.modalService.open(
      ConfirmModalComponent,
      // confirmOptions
      { size: 'sm', centered: true, backdrop: 'static' }
    );
    modalConfirmRef.componentInstance.type = 'danger';
    modalConfirmRef.componentInstance.icon = 'delete';
    modalConfirmRef.componentInstance.ok = 'Delete';
    modalConfirmRef.componentInstance.message = 'Confirm deletion';
    modalConfirmRef.componentInstance.response.subscribe(() => {
      this.dataService.deleteComment(comment.id).subscribe(
        (data) => {
          this.list.splice(index, 1);
        }
      );
    });
  }
}
