<div class="modal-header">
  <h3 class="modal-title"></h3>
  <button role="button" class="btn btn-secondary btn-close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <p class="mb-3 text-center">
    <img alt="" [src]="'/assets/img/bg/finish/' + numb + '.png'" />
  </p>

  <div *ngIf="step" class="steps text-center mb-3">
    <h2>{{ "Params.daysCompleted" | translate: { from: step, to: 4 } }}</h2>

    <span class="btn" *ngFor="let i of [1,2,3,4]" [ngClass]="{ 'btn-primary': i <= step, 'btn-secondary': i > step, 'gift': i == 4 }">
      <svg-icon *ngIf="i <= step" src="/assets/img/icons/check.svg"></svg-icon>
      <svg-icon *ngIf="i == 4 && step != 4" src="/assets/img/icons/gift.svg"></svg-icon>
    </span>
  </div>

  <pre class="body" [ngClass]="{ 'text-center': step }" translate>{{ posttip }}</pre>
</div>
<div class="modal-footer d-block">
  <a class="btn btn-primary w-100" (click)="activeModal.dismiss('Cross click')" translate>{{ btn }}</a>
</div>
