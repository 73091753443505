import {Component} from '@angular/core';
import {Validators} from '@angular/forms';
import {User} from '../../_business';
import {PasswordValidation} from '../../_helpers/passwordValidation';
import {AuthComponent} from '../auth/auth.component';

@Component({
  selector: 'app-auth',
  templateUrl: './reg.component.html',
  styleUrls: ['../auth/auth.component.css']
})
export class RegComponent extends AuthComponent {

  public initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        email: [
          null, [
            Validators.required, Validators.email
          ]],
        password: [
          null, [
            Validators.required,
            // Validators.pattern('^[a-zA-Z0-9]+'),
            Validators.minLength(2)
          ]],
        confirm: [
          null, [
            Validators.required
          ]]
      }, {
        validator: PasswordValidation.matchPassword
      });
  }

  public onSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    const user: User = {
      login: this.f.email.value,
      password: this.f.password.value
    };

    this.auth
      .signup(user).subscribe(
      () => {
      },
      err => {
        this.onStopSubmit();
        this.error = err.error;
      }
    );
  }

}
