import {Component, Input, OnInit} from '@angular/core';
import {Training} from "../../../_business";

@Component({
  selector: 'app-training-text',
  templateUrl: './training-text.component.html',
})
export class TrainingTextComponent implements OnInit {
  public txt: string;
  public source: string;
  public fontSize: number = 16;
  public fontSizeDirection : 'forward'|'backward' = 'forward';

  @Input() public training: Training;

  constructor() {
  }

  ngOnInit(): void {
    this.txt = this.training.record_text;
    const parts = this.txt.split('---');

    if (parts.length >= 2) {
      this.source = parts[1].trim(); // Part after '---'
      this.txt = parts[0].trim(); // Part above '---'
    }
  }

  public onChangeFontSizeTap() {
    if (this.fontSizeDirection == 'forward')
      this.fontSize++;
    else
      this.fontSize--;

    if (this.fontSize >= 20)
      this.fontSizeDirection = 'backward';
    else if (this.fontSize <= 16)
      this.fontSizeDirection = 'forward';
  }
}
