import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../../_services/auth.service';
import {DataService} from '../../../_services/data.service';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../../_business';
import { ActivatedRoute } from '@angular/router';
import {MixpanelService} from "../../../_services/mixpanel.service";


enum WhyCancelEnum {
  time = 1,
  price = 2,
  other = 3
}


@Component({
  selector: 'app-paywall-details',
  templateUrl: './paywall-details.component.html',
  styleUrls: ['./paywall-details.component.css']
})
export class PaywallDetailsComponent implements OnInit {

  // @Input() public type;// : 'paywall'|'offer'|'cancel';
  public type;

  public user: User = null;
  public loading = true;
  public error: string;

  public subscriptionData: {
    name: string;
    since: string;
    renewal: string;
    price: string;
    via: string;
    status: 'Active'|'Canceled';
    cancelable: boolean;
    offerable: boolean;
  };
  public subscriptionState = 0;

  public whyCancelEnum = WhyCancelEnum;
  public whyCancel = 0;
  public cancelComment = '';
  public commentList;

  public toOffer = false;
  public isBack = true;
  // public seeYouDate;

  public offerData;

  @ViewChild("iframe") iframe: ElementRef;

  constructor(
    private translate: TranslateService,
    private dataService: DataService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private _mixpanelService: MixpanelService,
  ) {
    this.type = this.route.snapshot.paramMap.get('type');

    switch (this.type) {
      case 'offer':
        this.toOffer = true;
        this.loading = false;
        this.onChangeSubscriptionState(3);
        break;

      case 'cancel':
        this.onChangeSubscriptionState(1);
        this.whyCancel = this.whyCancelEnum.other;
        this.onCancelSurvey();
        break;
    }

    this.translate.get(['comments']).subscribe(
      res => this.commentList = res.comments
    );
  }

  ngOnInit(): void {
    this.user = this.auth.user;

    if (this.type == 'offer')
      return;

    this.dataService.getMemberSubscriptions().subscribe(
      (data) => {
        // data.cancelable = true;
        // data.offerable = false;

        this.subscriptionData = data;
        this.loading = false;
      },
      err => {
        console.error(err.error);
        // this.error = err.error;
        this.error = 'cancelError';
        this.loading = false;
      }
    );

    // this.dataService.getSubscriptionManagerLink().subscribe(
    //   (data) => {
    //     this.managerLink = data.url;
    //     // this.loading = false;
    //   },
    //   err => {
    //     this.error = err.error;
    //     // this.loading = false;
    //   })
    // ;
  }

  public onChangeSubscriptionState(step) {
    this.subscriptionState += step;

    if (this.subscriptionState == 3 && !this.toOffer) {
      if (this.type == 'offer' || this.subscriptionData.offerable) {
        this.loading = true;
        this.dataService.getOfferSubscription().subscribe({
          next: (data) => {
            this.offerData = data;
            this.loading = false;
          },
          error: err => {
            console.error(err.error);
            // this.error = err.error;
            this.error = 'cancelError';
            this.loading = false;
          }
        });
      } else
        this.onCancelSubscription();
    }
  }

  public onCancelSurvey() {
    this.dataService.cancelSurvey(this.whyCancel, this.cancelComment).subscribe();
    this.onChangeSubscriptionState(1);

    this._mixpanelService.track(
      'Cancel', {
        Reason: WhyCancelEnum[this.whyCancel] + (this.cancelComment ? ': ' : '') + this.cancelComment,
        // 'Offer acceptance': true,
        // Success: true,
      }
    );
  }

  public onCancelSubscription() {
    this.loading = true;

    this.dataService.cancelSubscription().subscribe({
      next: () => {
        this.loading = false;
      },
      error: err => {
        console.error(err.error);
        // this.error = err.error;
        this.error = 'cancelError';
        this.loading = false;
      }
    });

    // let date = new Date();
    // date.setMonth(date.getMonth() + 3);
    // this.seeYouDate = date.toLocaleDateString(this.translate.currentLang, {month: "long", day: "numeric", year: "numeric"});

    this.onChangeSubscriptionState(1);
    this.isBack = false;
  }
}
