<div *ngIf="training && step - training.steps?.length >= 3" role="dialog" tabindex="-1" aria-modal="true" class="d-block fullscreen modal fade show">
  <div role="document" class="modal-dialog modal-fullscreen">
    <div class="modal-content">
      <app-finish-training [training]="training" [recordings]="recordings" [duration]="videoDuration"></app-finish-training>
    </div>
  </div>
</div>


<div class="container">

<!--  <canvas riv="audio_illustration">
    <riv-state-machine name="audio_illustration" play>
      <riv-input name="playing" [value]="true"></riv-input>
    </riv-state-machine>
  </canvas>-->

<!--  <canvas riv="audio_yellow_button">
    <riv-state-machine name="audio_button" play>
      <riv-input name="sound_playing" [value]="true"></riv-input>
    </riv-state-machine>
  </canvas>-->

<!--  <canvas riv="training_error" artboard="training_error">
    <riv-state-machine name="training_error" play></riv-state-machine>
  </canvas>-->

<!--  <canvas riv="exit_training_illustration">
    <riv-state-machine name="exit_training_illustration" play/>
  </canvas>-->

<!--  <canvas riv="recording_failed_bg">
    <riv-state-machine name="recording_failed_bg" play>
      <riv-input name="localisation_id" [value]="1"></riv-input>
    </riv-state-machine>
  </canvas>-->

<!--  <canvas riv="recording_success_bg">
    <riv-state-machine name="recording_success_bg" play>
      <riv-input name="localisation_id" [value]="1"></riv-input>
    </riv-state-machine>
  </canvas>-->

<!--  <canvas riv="dots" width="20" height="4">
    <riv-state-machine name="dots" play/>
  </canvas>-->

<!--  <canvas riv="dots" width="20" height="4">
    <riv-animation name="dots" play/>
  </canvas>-->

<!--  <canvas riv="recording_illustration2">
    <riv-state-machine name="recording_illustration" play>
      <riv-input name="recording" [value]="true"></riv-input>
    </riv-state-machine>
  </canvas>-->


  <button role="button" class="btn btn-close" (click)="onBack()"></button>

  <app-loading *ngIf="loading"/>

  <ng-container *ngIf="training">
    <div class="d-lg-flex justify-content-lg-between align-items-lg-center mb-lg-4">
      <div class="order-lg-1 d-flex align-items-center justify-content-center">
        <div class="progress">
          <div class="progress-bar bg-warning flash" role="progressbar" style="border-radius: 20px" [style.width]="100 / (training.steps.length + 4) * (step + 1) + '%'"></div>
        </div>
        <button class="btn" (click)="onStep(1, true)">
          <app-icon icon="right"/>
        </button>
      </div>

      <h1 class="mb-lg-0 text-center text-lg-start">{{title || training.name | translate}}</h1>
    </div>

    <ng-container [ngSwitch]="step">

      <ng-container *ngSwitchCase="step < training.steps.length ? step : ''">
        <div class="d-lg-flex mx-auto gap-lg-4" style="max-width: 1120px;">
          <div class="w-100 fs-0 d-none d-lg-block" style="opacity: 0.2; filter: blur(10px)">
            <ng-container *ngIf="riveLoaded > 1 && step > 0">
              <canvas class="mw-100" width="350" height="220" riv="step_illustration" artboard="step_illustration">
                <riv-state-machine name="illustration" play>
                  <riv-input name="illustration_id" [value]="step"></riv-input>
                </riv-state-machine>
              </canvas>

              <div class="panel text-center mb-6">
                <div class="subheader mb-4 display-3 fw-bold lh-0">...</div>
                <h2 class="mb-0">{{training.steps[step - 1].text}}</h2>
              </div>
            </ng-container>
          </div>

          <div class="w-100 mw-2 fs-0">
            <canvas class="mw-100" width="350" height="220" riv="step_illustration" artboard="step_illustration">
              <riv-state-machine name="illustration" play (load)="onLoadRive(1)">
                <riv-input name="illustration_id" [value]="step + 1"></riv-input>
              </riv-state-machine>
            </canvas>

            <div class="panel text-center mb-6">
              <div class="subheader mb-4 display-3 fw-bold lh-0">...</div>
              <h2 class="mb-0">{{training.steps[step].text}}</h2>
            </div>

            <div class="mobile-bottom mw-2">
              <button class="btn btn-warning w-100" translate (click)="onStep(1, true)">Next</button>
            </div>
          </div>

          <div class="w-100 fs-0 d-none d-lg-block" style="opacity: 0.2; filter: blur(10px)">
            <ng-container *ngIf="riveLoaded > 0 && step + 1 < training.steps.length">
              <canvas class="mw-100" width="350" height="220" riv="step_illustration" artboard="step_illustration">
                <riv-state-machine name="illustration" play (load)="onLoadRive(2)">
                  <riv-input name="illustration_id" [value]="step + 2"></riv-input>
                </riv-state-machine>
              </canvas>

              <div class="panel text-center mb-6">
                <div class="subheader mb-4 display-3 fw-bold lh-0">...</div>
                <h2 class="mb-0">{{training.steps[step + 1].text}}</h2>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="training.steps.length + 1">
        <app-loading *ngIf="!training.movieUrl"/>

        <div *ngIf="training.movieUrl" class="plyr-inline">
          <div plyr [plyrTitle]="training.name" [plyrPlaysInline]="true" (window:resize)="onResize()" [style.max-width]="playerWidth + 'px'"
               [plyrOptions]="{autoplay: !uploadError, controls: ['rewind', 'play', 'fast-forward'], iconUrl: '/assets/img/icons/sprite-plyr.svg'}"
               [plyrPoster]="training.images.main.url" [plyrSources]="[{ src: training.movieUrl, type: 'video/mp4' }]"
               (plyrInit)="onPlayerInit($event)" (plyrPlay)="onPlayerStart()" (plyrTimeUpdate)="onVideoTime()" (plyrPause)="onPlayerPause()" (plyrLoadedData)="onPlayerLoaded($event)" (plyrEnded)="onPlayerEnd($event)"
          ></div>
          <div *ngIf="stepText else videoTimer" class="panel video-panel">
            <div *ngIf="videoPlayer && videoTimer" class="video-timer">{{(videoPlayer.duration - videoPlayer.currentTime) * 1000 | date:'mm:ss'}}</div>
            {{stepText}}
          </div>
          <ng-template #videoTimer>
            <div *ngIf="videoPlayer && videoTimer" class="video-timer">{{(videoPlayer.duration - videoPlayer.currentTime) * 1000 | date:'mm:ss'}}</div>
          </ng-template>
        </div>

      </ng-container>

      <ng-container *ngSwitchDefault>
        <div class="row">
          <div class="col-lg text-center">
            <div class="panel-lg bg-lg-secondary d-block h-lg-100">
              <canvas class="d-block mx-auto mw-100" width="376" height="150" [riv]="'recording_illustration' + (step == training.steps.length ? 1 : 2)">
                <riv-state-machine name="recording_illustration" play>
                  <riv-input name="recording" [value]="mediaRecorder?.state == 'recording'"></riv-input>
                </riv-state-machine>
              </canvas>

              <app-training-text class="d-block mobile-mb d-lg-none" [training]="training"/>

              <div *ngIf="!error" class="mobile-bottom">
                <div *ngIf="!audio">
                  <p class="fw-bold display-4 mb-4 my-lg-5 text-center" [ngClass]="mediaRecorder?.state != 'recording' && 'opacity-0'">{{time * 1000 | date:'mm:ss'}}</p>

                  <button class="btn w-100" [ngClass]="{'btn-warning': mediaRecorder?.state != 'recording', 'btn-outline-warning': mediaRecorder?.state == 'recording'}" (click)="onRecording()">
                    <em *ngIf="mediaRecorder?.state == 'recording'" class="recording-icon i animate-flash"></em>
                    {{(mediaRecorder?.state == 'recording' ? 'Tap to stop recording' : 'Tap to record') | translate}}
                  </button>
                </div>

                <div *ngIf="audio && success">
                  <div class="d-flex gap-4 gap-lg-5 mb-4 my-lg-4">
                    <div class="text-success w-100 text-start">
                      <p class="h2 mb-1" translate>
                        <app-icon icon="check" size="md" class="i"/>Nice!
                      </p>
                      <span translate>Recording is made!</span>
                    </div>
                    <button class="btn btn-service btn-success p-3 fs-0" (click)="audio = null"><app-icon icon="repeat" size="md"/></button>
                    <button *ngIf="!playing" class="btn btn-service btn-success p-3 fs-0" (click)="onPlay()"><app-icon icon="sound" size="md"/></button>
                    <button *ngIf="playing" class="btn p-0 fs-0" (click)="onStop()">
                      <canvas width="52" height="52" riv="recording_success_button_audio" style="pointer-events: none">
                        <riv-state-machine name="recording_success_button_audio" play>
                          <riv-input name="sound_playing" [value]="true"></riv-input>
                        </riv-state-machine>
                      </canvas>
                    </button>
                  </div>
                  <button class="btn w-100 btn-success" translate (click)="onStep(1)">Next</button>
                </div>

                <div *ngIf="audio && !success">
                  <div class="d-flex gap-4 gap-lg-5 mb-4 my-lg-4">
                    <div class="text-danger w-100 text-start">
                      <p class="h2 mb-1" translate>
                        <app-icon icon="incorrect" size="md" class="i"/>Incorrect
                      </p>
                      <span translate>Recording is too short! Please try again.</span>
                    </div>
                  </div>
                  <button class="btn w-100 btn-danger" translate (click)="onRepeat()">Got it</button>
                </div>
              </div>
            </div>

          </div>

          <div class="col-lg d-none d-lg-block">
            <app-training-text [training]="training"/>
          </div>
        </div>
      </ng-container>

    </ng-container>

  </ng-container>

  <div *ngIf="error" class="alert alert-danger" translate>{{ error | translate }}</div>

</div>

<svg-icon src="/assets/img/icons/sprite-training.svg" class="d-none"></svg-icon>
