<div class="download" [ngClass]="{'inline': inline, 'text-center': !inline}">
  <!--<p class="subtitle d-none d-md-block">{{ 'Footer.Download' | translate }}</p>-->
  <div class="row mini">
    <div class="col-6">
      <a (click)="onInstall('iOS')" href="{{ storeLink.apple }}"><img src="/assets/img/app-store.png" alt="" width="140" /></a>
    </div>
    <div class="col-6">
      <a (click)="onInstall('android')" href="{{ storeLink.google }}"><img src="/assets/img/google-play.png" alt="" width="140" /></a>
    </div>
  </div>
</div>
