<div class="container page-content" *ngIf="loading">
  <img width="50" height="50" src="../assets/img/loader.gif" alt=""/>
</div>

<div class="container page-content" *ngIf="audio && !loading">

  <div class="card"><div class="card-body">
    <h5>{{ audio.name }}</h5>
    <div class="row mini align-items-end aplay">
      <div class="col">
        <img [src]="audio.image_passive" alt="" class="w-100" /><br/>
        <div plyr plyrColorMain="red" plyrTitle="Audio" plyrType="audio"
             [plyrPlaysInline]="true" [plyrCrossOrigin]="true" [plyrSources]="audioSources"></div>
      </div>
      <div class="col-auto pb-1">
        <span class="time header note">{{ audio.length }}</span>
      </div>
    </div>
  </div></div>

</div>
