import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DataForm} from '../../_helpers/dataForm';
import {AuthService} from '../../_services/auth.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent extends DataForm implements OnInit {

  constructor(
    public translate: TranslateService,
    private route: ActivatedRoute,
    private auth: AuthService,
    public formBuilder: FormBuilder,
  ) {
    super(formBuilder);
  }

  public initForm(): void {
    this.dataForm =
      this.formBuilder.group({
        email: [
          null, [
            Validators.required, Validators.email
          ]],
      });
  }

  public onSubmit() {
    if (!this.onStartSubmit()) {
      return;
    }

    const email = this.f.email.value;

    if (email) {
      this.auth
        .restore(email).subscribe(
        () => {
          this.onStopSubmit();
          this.success = true;
        },
        err => {
          this.error = err.error;
        }
      )
        .add(() => {
        });
    }
  }
}
