import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '../../_services/data.service';
import {Intensive} from '../../_business';
import {InstallModalComponent} from '../parts/install-modal/install-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Achieve} from '../../_business/achieve.model';
import {VideoModalComponent} from '../parts/video-modal/video-modal.component';
import {fullscreenOptions, install} from "../../_helpers/lib";
import {AchieveModalComponent} from "../profile/achieve-modal/achieve-modal.component";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public loading = true;
  public events: any[] = [];
  public achieves: Achieve[] = [];

  public specialCount = 3;
  public intensiveCurrent: any;
  public videoTutorial: string;
  public plan = 0;

  // public managerLink: string = null;

  constructor(
    private dataService: DataService,
    private router: Router,
    private modalService: NgbModal,
  ) {

  }

  ngOnInit(): void {
    this.dataService.getVideoTutorial().subscribe(
      (data) => {
        this.videoTutorial = data.url;
        this.specialCount++;
      }
    );

    this.dataService.getIntensiveCurrent().subscribe(
      (data) => {
        this.intensiveCurrent = data;
        this.specialCount++;
      }
    );

    this.dataService.getLastEvent().subscribe(
      (data) => {
        this.events.push(data);
      }
    )
      .add(() => this.loading = false);

    this.dataService.getAchieves().subscribe(
      (data) => {
        this.achieves = data;
      }
    )
      .add(() => this.loading = false);

/*    this.dataService.getSubscriptionManagerLink().subscribe(
      (data: any) => this.managerLink = data.url
    )
      .add(
        () => this.loading = false
      );*/
  }

/*  public toManager() {
    window.location.href = this.managerLink;
  }

  public toPaywall() {
    this.router.navigate(['/subscribe']);
  }*/

  public showAchieve(achieve: Achieve) {
    const modalRef = this.modalService.open(
      AchieveModalComponent,
      { centered: true, modalDialogClass: 'gradient' }
    );
    modalRef.componentInstance.achieve = achieve;
  }

  public toIntall() {
    // this.router.navigate(['/install']);
    const modalRef = this.modalService.open(
      InstallModalComponent,
      { centered: true }
    );
  }

  public onShowMoviePop() {
    const modalRef = this.modalService.open(
      VideoModalComponent,
      fullscreenOptions
      // { backdrop: 'static', size: 'lg', centered: true }
    );
    modalRef.componentInstance.movieUrl = this.videoTutorial;
    modalRef.componentInstance.name = 'Видео руководство';
  }

  public getEventItem(event) {
    const item = event.training || event.podcast || event.intensive || event.course;

    if (event.training) {
      item.btn = 'Начать обучение';
      item.title = 'Обучающий курс';
      item.url = ['/training', event.training.id];
    } else if (event.podcast) {
      item.btn = 'Прослушать';
      item.title = 'Подкаст';
      item.url = ['/podcasts'];
      item.queryParams = { id: event.podcast.id };
    } else if (event.intensive) {
      item.btn = 'Смотреть';
      item.title = 'Обучающий курс';
      item.url = ['/courses'];
      item.queryParams = { id: event.intensive.id };
    } else if (event.course) {
      item.btn = 'Смотреть';
      item.title = 'Лекция';
      item.url = ['/lectures'];
      item.queryParams = { id: event.course.id };
    }

    return item;
  }

  public goToEvent(event, item) {
    if (event.training.paid)
      this.router.navigate(['/subscribe']);
    else if (item.url)
      this.router.navigate(item.url, { queryParams: item.queryParams });
    else
      this.toIntall();
    // this.selectedIntensive = intensive.training;
  }

  protected readonly install = install;
}
