<div class="container page-content" *ngIf="loading">
  <img width="50" height="50" src="/assets/img/loader.gif" alt=""/>
</div>

<div class="container pt-4" *ngIf="training">
<!--  <nav>
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home">{{ 'Home' | translate }}</a></li>
      <li class="breadcrumb-item"><a routerLink="/lessons">{{ 'Page.Trainings.title' | translate }}</a></li>
      <li class="breadcrumb-item active">{{ training.name }}</li>
    </ol>
  </nav>-->

<!--  <h2>{{ training.name }}</h2>-->

  <div class="row align-items-center mb-5">
    <div class="col-lg-6 mb-4-mob">
      <div class="card">
        <div class="card-title overflow">{{ training.name }}</div>

        <a href="javascript:void(0)" class="card-sign" (click)="onFavourite()">
          <fa-icon [icon]="training.favorited ? fasHeart : farHeart"></fa-icon>
        </a>
        <img class="card-img" [attr.src]="training.images.main.url" alt="" width="570" height="320" />
        <div class="card-img-overlay header text-center">
          <p class="card-text">{{ training.short_description }}</p>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="txt-block d-none d-md-block">
        <pre class="body pt-0 mb-0">
          <h2><img src="/assets/img/icons/ai.svg" alt=""/><span>{{ 'Page.Trainings.ai' | translate }}</span>
          </h2>{{ training.record_text }}</pre>

        <app-download postfix="_top" [inline]="true"></app-download>
      </div>

      <div id="carousel" class="carousel slide carousel-multi-item mt-4-desktop" data-ride="carousel">
        <div class="carousel-inner" role="listbox">
          <ng-container *ngFor="let item of training.images.shots; let i = index; first as isFirst">
            <div class="carousel-item" [ngClass]="{'active': isFirst}" *ngIf=" i % 3 === 0 ">
              <div class="row mini">
                <div class="col-4" *ngFor="let shot of training.images.shots.slice(i, i + 3)">
                  <img [attr.src]="shot.url" alt="" width="570" height="320" />
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <a class="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carousel" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </div>

  <div class="page mb-4">
    <div class="row">
      <div class="col-lg-6">
        <div class="card-body pr-0-desktop">
          <h4 class="text-center text-md-start">{{ 'Page.Trainings.descr' | translate }}</h4>
          <p>{{ training.description }}</p>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card-body pl-0-desktop" *ngIf="videoReady">
          <div plyr [plyrTitle]="training.name" [plyrPlaysInline]="true"
               [plyrPoster]="poster" [plyrSources]="videoSources" (plyrInit)="player = $event"
               (plyrPlay)="played($event)"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-md-none">
    <pre class="body px-0">
      <h4><img src="/assets/img/icons/ai.svg" alt=""/><span>{{ 'Page.Trainings.ai' | translate }}</span>
      </h4>{{ training.record_text }}</pre>
  </div>

  <h3 class="text-center text-md-start mb-4">{{ 'Page.Trainings.similars' | translate }}</h3>
  <div id="relates" class="carousel slide carousel-multi-item" data-ride="relates" (window:resize)="onResize($event)">
    <div class="carousel-inner" role="listbox">
      <ng-container *ngFor="let item of training.similars; let i = index; first as isFirst">
        <div class="carousel-item" [ngClass]="{'active': isFirst}" *ngIf=" i % columns === 0 ">
          <div class="row mini">
            <div class="col" *ngFor="let train of training.similars.slice(i, i + columns)">
              <div class="card">
                <a href="javascript:void(0)" class="card-link" (click)="goToTraining(train)"></a>
                <img class="card-img" [attr.src]="train.image.preview" alt="" width="204" height="92" />
                <div class="card-img-overlay header">
                  <p class="card-text">{{ train.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <a class="carousel-control-prev" href="#relates" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#relates" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>

</div>
