import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {AuthService} from '../../../_services/auth.service';
import {User} from '../../../_business';
import {LanguageEnum} from '../../../_business/language.enum';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-head',
  templateUrl: './head.component.html',
  styleUrls: ['./head.component.css']
})
export class HeadComponent implements OnInit, OnDestroy {

  // private eventsSubscription: Subscription = null;
  private userSubscription: Subscription = null;
  public readonly WEB: string = environment.web;

  public user: User = null;
  public languageEnum = LanguageEnum;

  constructor(
    // private renderer: Renderer2,
    // private router: Router,
    // private _route: ActivatedRoute,
    private _authService: AuthService,
    public translate: TranslateService,
  ) {
    // this.eventsSubscription = this.router.events
    //   .subscribe((event) => {
    //     if (event instanceof NavigationEnd) {
    //       const isMobile =
    //         /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Windows Phone|Opera M/i.test(navigator.userAgent.toLowerCase());
    //
    //       if (this._route.firstChild.snapshot.data) {
    //         this.renderer.removeAttribute(document.body, 'class');
    //
    //         if (isMobile) {
    //           this.renderer.addClass(document.body, 'mobile');
    //         }
    //       }
    //     }
    //   });

  }

  ngOnInit(): void {
    this.user = this._authService.user;
    this.userSubscription = this._authService.getCurrentUser().subscribe((user: User) => this.user = user);
  }

  ngOnDestroy(): void {
    // this.eventsSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }

  public changeLang(key) {
    this.translate.use(key);

    return false;
  }
}
