<div class="container">
<!--  <img *ngIf="loading && !error" width="50" height="50" src="/assets/img/loader.gif" alt=""/>-->

  <div class="float-end" style="margin-top: -6px;">
    <button class="btn btn-secondary btn-md subtitle fw-normal" (click)="onLogout()">
      <span class="i">{{ 'Log Out' | translate }}</span>
      <app-icon icon="right"/>
    </button>
  </div>

  <h1 class="d-flex align-items-center">
    <a routerLink="/profile" class="btn p-0 me-3 subtitle d-lg-none"><app-icon icon="left"/></a>
    {{ 'Settings' | translate }}
  </h1>

  <form [formGroup]="dataForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <h4 translate>Your name</h4>
      <div class="row">
        <div class="col-lg-4">
          <input type="text" formControlName="name" maxlength="64" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
        </div>
      </div>
    </div>

<!--
    <div class="form-group">
      <h4>E-mail</h4>
      <div class="row">
        <div class="col-lg-4">
          <input type="email" formControlName="email" maxlength="64" class="form-control" autocomplete="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
        </div>
      </div>
    </div>

    <div class="form-group form-block">
      <h4>E-mail</h4>
      <input type="email" formControlName="email" maxlength="64" class="form-control" autocomplete="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
    </div>

    <div class="form-group goal">
      <h4>{{ 'Main goal' | translate }}</h4>
      <label *ngFor="let goal of goalEnum | keyvalue" class="btn btn-secondary me-3" [ngClass]="{ 'active': goal.key == f.goal.value }">
        <span class="form-check">
          <input type="radio" formControlName="goal" class="form-check-input" value="{{ goal.key }}">
          <span class="form-check-label">{{ goal.value | translate }}</span>
        </span>
      </label>
      <label class="btn btn-secondary" [ngClass]="{ 'active': !f.goal.value }">
        <span class="form-check">
          <input type="radio" formControlName="goal" class="form-check-input" value="">
          <span class="form-check-label">{{ 'Do not select' | translate }}</span>
        </span>
      </label>
    </div>

    <div class="form-group gender">
      <h4>{{ 'Gender' | translate }}</h4>
      <label *ngFor="let gender of genderEnum | keyvalue" class="btn btn-secondary me-3" [ngClass]="{ 'active': gender.key == f.gender.value }">
        <span class="form-check">
          <input type="radio" formControlName="gender" class="form-check-input" value="{{ gender.key }}">
          <span class="form-check-label">
            <svg-icon src="/assets/img/icons/{{ gender.key }}.svg" style="width: 25px; height: 24px;"></svg-icon><br />
            {{ gender.value | translate }}
          </span>
        </span>
      </label>
      <label class="btn btn-secondary" [ngClass]="{ 'active': !f.gender.value }">
        <span class="form-check">
          <input type="radio" formControlName="gender" class="form-check-input" value="">
          <span class="form-check-label">
            <svg-icon src="/assets/img/icons/gender.svg" style="width: 25px; height: 24px;"></svg-icon><br />
            {{ 'Do not select' | translate }}
          </span>
        </span>
      </label>
    </div>-->
  </form>

  <div class="form-group mb-2 mb-lg-4">
    <h4>{{ 'Language' | translate }}</h4>
    <div class="row">
      <div *ngFor="let language of languageEnum | keyvalue" class="col-lg-4">
        <label class="btn btn-secondary btn-checkbox btn-border w-100 mb-2 mb-lg-4" [ngClass]="{ 'active': language.key == translate.currentLang }">
          <span class="form-check">
            <span class="form-check-label small">{{ language.value }}</span>
            <input type="radio" name="language" class="form-check-input" value="{{ language.key }}" [attr.checked]="language.key == translate.currentLang ? 'checked' : null" (change)="changeLang(language.key)">
          </span>
        </label>
      </div>
    </div>
  </div>

  <form [formGroup]="dataFormNotify" (ngSubmit)="onSubmitNotify()">
    <div class="form-group mb-0 mb-lg-4">
      <h4 translate>Email Notification</h4>
      <div class="row">
        <div class="col-lg-4">
          <label class="btn btn-secondary btn-checkbox fw-normal w-100" [ngClass]="{ 'active': dataFormNotify.value.marketing }">
            <span class="form-check form-switch">
              <span class="form-check-label" translate>Content Explorer</span>
              <input class="form-check-input" type="checkbox" role="switch" formControlName="marketing">
            </span>
          </label>
          <p class="subtitle small" translate>
            Be the first to know about the latest additions, including new challenges, trainings, tests, podcasts, videos, talks, and lectures in the app.
          </p>
        </div>

        <div class="col-lg-4">
          <label class="btn btn-secondary btn-checkbox fw-normal w-100" [ngClass]="{ 'active': dataFormNotify.value.product_updates }">
            <span class="form-check form-switch">
              <span class="form-check-label" translate>Deal Detector</span>
              <input class="form-check-input" type="checkbox" role="switch" formControlName="product_updates">
            </span>
          </label>
          <p class="subtitle small" translate>
            Keep an eye on valuable discounts and limited-time offers.
          </p>
        </div>
      </div>
    </div>
  </form>

  <div class="form-group mb-0 mb-lg-4 d-lg-none">
    <h4 translate>Manage subscription</h4>
    <p>
      <a routerLink="/subscribe" class="btn btn-secondary subtitle fw-normal d-flex justify-content-between">
        <span class="i" translate>Membership</span>
        <app-icon icon="right"/>
      </a>
    </p>
  </div>

  <div class="form-group mb-2 mb-lg-4">
    <h4 translate>Info & Policies</h4>
    <div class="row">
      <div class="col-lg-4 mb-2 mb-lg-4">
        <a [attr.href]="WEB + 'terms'" target="_blank" class="btn btn-secondary subtitle w-100 text-start">
          <small translate>Terms</small>
        </a>
      </div>
      <div class="col-lg-4 mb-2 mb-lg-4">
        <a [attr.href]="WEB + 'privacy'" target="_blank" class="btn btn-secondary subtitle w-100 text-start">
          <small translate>Privacy</small>
        </a>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <a routerLink="/delete" class="btn btn-outline-danger fw-normal d-flex justify-content-between">
        {{ 'Page.Settings.delete_account' | translate }}
        <app-icon icon="right"/>
      </a>
    </div>
  </div>

  <div *ngIf="error" class="alert alert-danger mt-4">{{ error | translate }}</div>

</div>
