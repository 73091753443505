import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from './auth.service';


@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router,
    private auth: AuthService
  ) {
  }

  public canActivate() {
    if (this.auth.user) {
      return true;
    } else {
      this.router.navigate(['/auth']);
      return false;
    }
  }
}
