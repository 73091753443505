<div class="container page-content" *ngIf="loading">
  <img width="50" height="50" src="../assets/img/loader.gif" alt=""/>
</div>

<div class="container page-content" *ngIf="!result && !loading">
  <h3 class="mb-4">{{ 'Page.SpecialOffer.negativeTitle' | translate }}</h3>
  <div class="header page-info">
    <p>{{ "If you think it's a mistake, please, let us know" | translate }}</p>
  </div>
</div>
