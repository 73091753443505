import {Component, OnInit} from '@angular/core';
import {DataService} from '../../_services/data.service';
import {Statistics} from '../../_business/statistics.model';
import {Audio} from '../../_business';

@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.css']
})
export class AudioComponent implements OnInit {

  public loading = true;
  public error;
  public audios: Audio[];

  constructor(
    private dataService: DataService,
  ) {

  }

  ngOnInit(): void {
    this.dataService.getAudiosRecent().subscribe(
      (data: Audio[]) => {
        this.audios = data;

        // this.audios = JSON.parse(JSON.stringify([
        //   {
        //     "id":"276434",
        //     "created":"09 Nov 2021 в 11:04",
        //     "audio":"https:\/\/cdn.vocalimage.net\/audios\/25\/276434_5714d9ef-f430-44cf-bac0-eef64192b3c2.mp3",
        //     "exercise_id":null,
        //     "training_id":"98",
        //     "course_id":null,
        //     "intensive_id":null,
        //     "length":"02:28",
        //     "uid":"fcdd5cc8ab1746c3",
        //     "image":"https:\/\/cdn.vocalimage.net\/audios\/25\/276434_silent.png",
        //     "image_active":"https:\/\/cdn.vocalimage.net\/audios\/25\/276434_active.png",
        //     "image_passive":"https:\/\/cdn.vocalimage.net\/audios\/25\/276434_passive.png",
        //     "name":"Day 1. Correct breathing"
        //   },
        //   {
        //     "id":"276432",
        //     "created":"06 Nov 2021 в 15:30",
        //     "audio":"https:\/\/cdn.vocalimage.net\/audios\/25\/276432_2d3f82b5-c30b-09e8-d05f-24e76a9a73fb.mp3",
        //     "exercise_id":null,
        //     "training_id":"98",
        //     "course_id":null,
        //     "intensive_id":null,
        //     "length":"02:28",
        //     "uid":"dadeaaef84ca4409",
        //     "image":"https:\/\/cdn.vocalimage.net\/audios\/25\/276432_silent.png",
        //     "image_active":"https:\/\/cdn.vocalimage.net\/audios\/25\/276432_active.png",
        //     "image_passive":"https:\/\/cdn.vocalimage.net\/audios\/25\/276432_passive.png",
        //     "name":"Day 1. Correct breathing"
        //   }
        // ]));

        console.log('audios', this.audios);
        this.loading = false;
      },
      err => {
        this.error = err.error;
        this.loading = false;
      }
    );
  }
}
