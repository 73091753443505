import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Settings, Audio, Tag, Training, Intensive, Podcast, Course, Comment} from '../_business';
import {Statistics} from '../_business/statistics.model';
import {Message} from '../_business/message.model';
import {Achieve} from '../_business/achieve.model';
import {EmailNotificationSettings} from "../_business/email-notification-settings.model";
import {TrainingPractice, TrainingSession} from "../_business/training-session.model";
import {Tip} from "../_business/tip.model";
import {AnalysisStat} from "../_business/analysis-stat.model";
import {TranslateService} from "@ngx-translate/core";


@Injectable({
  providedIn: 'root'
})
export class DataService {

  private readonly API_SERVER: string = environment.api;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
  ) {
  }

  public setSettings(settings: Settings): Observable<Settings> {
    return this.http.post<Settings>(
      this.API_SERVER + 'member-settings',
      settings
    );
  }

  public getSettings(): Observable<Settings> {
    return this.http.get<Settings>(this.API_SERVER + 'member-settings');
  }

  // public setSettings(settings: Settings): Observable<Settings> {
  //   return this.http.post<Settings>(
  //     this.API_SERVER + 'members/settings',
  //     settings
  //   );
  // }
  //
  // public getSettings(): Observable<Settings> {
  //   return this.http.get<Settings>(this.API_SERVER + 'members/settings');
  // }

  public setEmailNotificationSettings(settings: EmailNotificationSettings): Observable<EmailNotificationSettings> {
    return this.http.post<EmailNotificationSettings>(
      this.API_SERVER + 'member-notification-settings/email/',
      settings
    );
  }

  public getEmailNotificationSettings(): Observable<EmailNotificationSettings> {
    return this.http.get<EmailNotificationSettings>(this.API_SERVER + 'member-notification-settings/email/');
  }

  public getMemberAnalysisStats() : Observable<AnalysisStat[]> {
    return this.http.get<AnalysisStat[]>(this.API_SERVER + 'member-analysis-stat');
  }

  public getMemberAnalysisStatById(id: number, days: 10|30|90) : Observable<AnalysisStat> {
    return this.http.get<AnalysisStat>(this.API_SERVER + 'member-analysis-stat/days-' + days + '/' + id);
  }

  public getPaymentLink(id: number): Observable<any> {
    return this.http.get<any>(this.API_SERVER + 'subscription/web/' + id);
  }

  public getSpecialOfferLink(id: string, code: string, coupon: string): Observable<any> {
    return this.http.post<any>(this.API_SERVER + 'special-offers/member/' + id, {
      code,
      coupon,
    });
  }

  public getPartnerOfferLink(partner: string, email: string, locale: string): Observable<any> {
    return this.http.put<any>(this.API_SERVER + 'presell', {
      partner_id: partner,
      email,
      locale
    });
  }

  public getSubscriptionManagerLink(): Observable<any> {
    return this.http.get<any>(this.API_SERVER + 'members/subscription-manager');
  }

  public getMemberSubscriptions(): Observable<any> {
    return this.http.get<any>(this.API_SERVER + 'member-subscriptions');
  }

  public cancelSurvey(option_id: number, comment: string) : Observable<any> {
    return this.http.post<any>(this.API_SERVER + 'member-subscriptions/cancel-survey', { option_id, comment });
  }

  public cancelSubscription() : Observable<any> {
    return this.http.post<any>(this.API_SERVER + 'member-subscriptions/cancel', {});
  }

  public getOfferSubscription() : Observable<any> {
    return this.http.get<any>(this.API_SERVER + 'member-subscriptions/offer?lang=' + this.translate.currentLang);
  }

  public getStatisticBars(): Observable<Statistics[]> {
    return this.http.get<Statistics[]>(this.API_SERVER + 'statistics/bars');
  }

  public getStatisticPlan(): Observable<Statistics[]> {
    return this.http.get<Statistics[]>(this.API_SERVER + 'statistics/plan');
  }

  public getStatisticSpider(): Observable<Statistics[]> {
    return this.http.get<Statistics[]>(this.API_SERVER + 'statistics/spider');
  }

  public getAudiosAll(): Observable<Audio[]> {
    return this.http.get<Audio[]>(this.API_SERVER + 'audios/all');
  }

  public getAudiosRecent(): Observable<Audio[]> {
    return this.http.get<Audio[]>(this.API_SERVER + 'audios/recent');
  }

  public getAudioByUid(uid: string): Observable<Audio> {
    return this.http.get<Audio>(this.API_SERVER + 'audios/share/' + uid);
  }

  public getTags(): Observable<Tag[]> {
    return this.http.get<Tag[]>(this.API_SERVER + 'tags/');
  }

  public getTrainings(): Observable<Training[]> {
    return this.http.get<Training[]>(this.API_SERVER + 'trainings/');
  }

  public getTrainingById(id: number): Observable<Training> {
    return this.http.get<Training>(this.API_SERVER + 'trainings/' + id);
  }

  public getTrainingVideo(id: number) : Observable<any>  {
    return this.http.get<any>(this.API_SERVER + 'trainings/video/' + id);
  }

  public getTrainingAudios(id: number) : Observable<any>  {
    return this.http.get<any>(this.API_SERVER + 'trainings/' + id + '/audios');
  }

  public getTrainingComments(id: number): Observable<Comment[]> {
    return this.http.get<Comment[]>(this.API_SERVER + 'trainings/' + id + '/comments');
  }

  public getServiceTraining(): Observable<Training> {
    return this.http.get<Training>(this.API_SERVER + 'trainings/service/');
  }

  public setTrainingRating(id: number, rating: number) : Observable<any>  {
    return this.http.post<any>(this.API_SERVER + 'trainings/' + id + '/rating', { rating });
  }

  public getPodcasts(): Observable<Podcast[]> {
    return this.http.get<Podcast[]>(this.API_SERVER + 'podcasts');
  }

  public getPodcastById(id: number): Observable<Podcast> {
    return this.http.get<Podcast>(this.API_SERVER + 'podcasts/' + id);
  }

  public getPodcastAudioUrl(id: number) : Observable<any>  {
    return this.http.get<any>(this.API_SERVER + 'podcasts/video/' + id);
  }

  public getIntensives(): Observable<Intensive[]> {
    return this.http.get<Intensive[]>(this.API_SERVER + 'intensives');
  }

  public getIntensiveById(id: number): Observable<Intensive> {
    return this.http.get<Intensive>(this.API_SERVER + 'intensives/' + id);
  }

  public getIntensiveCurrent(): Observable<any> {
    return this.http.get<any>(this.API_SERVER + 'intensives/current');
  }

  public getIntensiveTrainings(id: number): Observable<Training[]> {
    return this.http.get<Training[]>(this.API_SERVER + 'intensives/' + id + '/trainings');
  }

  public getIntensiveVideo(id: number) : Observable<any>  {
    return this.http.get<any>(this.API_SERVER + 'intensives/video/' + id);
  }

  public getCourses(): Observable<Course[]> {
    return this.http.get<Course[]>(this.API_SERVER + 'courses');
  }

  public getCourseById(id: number): Observable<Course> {
    return this.http.get<Course>(this.API_SERVER + 'courses/' + id);
  }

  public getCourseTrainings(id: number): Observable<Training[]> {
    return this.http.get<Training[]>(this.API_SERVER + 'courses/' + id + '/trainings');
  }

  public getCourseVideo(id: number) : Observable<any>  {
    return this.http.get<any>(this.API_SERVER + 'courses/video/' + id);
  }

  public getVideo(url: string) : Observable<any>  {
    return this.http.get<any>(this.API_SERVER + url);
  }

  public getPricelist(): Observable<any> {
    return this.http.get<Intensive>(this.API_SERVER + 'web-payments/prices?lang=' + this.translate.currentLang);
    // return this.http.get<Intensive>(this.API_SERVER + 'pricelist');
  }

  public getUserMessages(): Observable<Message[]> {
    return this.http.get<Message[]>(this.API_SERVER + 'user-messages');
  }

  public getUserMessagesUnread(): Observable<any> {
    return this.http.get<any>(this.API_SERVER + 'user-messages/unread');
  }

  public markAsReadUserMessage(id: number): Observable<any> {
    return this.http.post<any>(this.API_SERVER + 'user-messages/' + id + '/read', {});
  }

  public getLastEvent(): Observable<any> {
    return this.http.get<any>(this.API_SERVER + 'members/lastevent');
  }

  public getAchieves(): Observable<Achieve[]> {
    return this.http.get<Achieve[]>(this.API_SERVER + 'achievements');
  }

  public getVideoTutorial() : Observable<any>  {
    return this.http.get<any>(this.API_SERVER + 'info/videotutorial');
  }

  public getUploadToken(id: number) : Observable<any>  {
    return this.http.get<any>(this.API_SERVER + 'audios/preupload/' + id);
  }

  public upload(data: any) : Observable<any>  {
    const headers = new HttpHeaders({
      // 'Authorization': 'Basic ' + Base64.encode(login + ':' + password),
      // 'Content-Type': 'application/x-www-form-urlencoded',
      // token: data.token
      //'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + data.token
    });

    const formParams = new FormData();
    formParams.append('file', data.audio as Blob, 'fileToUpload');

    return this.http.post<any>(data.url, formParams, { headers });
  }

  public addComment(data: any): Observable<any> {
    return this.http.put<any>(this.API_SERVER + 'comments', data);
  }

  public deleteComment(id: number): Observable<any> {
    return this.http.delete<any>(this.API_SERVER + 'comments/' + id);
  }





  public addTrainingSession(trainingSession: TrainingSession) :  Observable<TrainingSession> {
    return this.http.put<TrainingSession>(this.API_SERVER + 'training-sessions', trainingSession);
  }

  public saveTrainingSession(trainingSession: TrainingSession) :  Observable<TrainingSession> {
    return this.http.post<TrainingSession>(this.API_SERVER + 'training-sessions/' + trainingSession.id, trainingSession);
  }

  public getUserLogForTraining(training: Training) :  Observable<TrainingSession[]> {
    return this.http.get<TrainingSession[]>(this.API_SERVER + 'training-sessions/training/' + training.id);
  }




  public recognizeTrainingSession(trainingSession: TrainingSession) :  Observable<any> {
    return this.http.get<any>(this.API_SERVER + 'training-sessions/recognize/' + trainingSession.id);
  }

  public pitchingTrainingSession(trainingSession: TrainingSession) :  Observable<any> {
    return this.http.get<any>(this.API_SERVER + 'training-sessions/pitching/' + trainingSession.id);
  }

  public analyzeTrainingSession(trainingSession: TrainingSession) :  Observable<any> {
    return this.http.get<any>(this.API_SERVER + 'training-sessions/analyze/' + trainingSession.id);
  }

  public getTrainingSessionResultItems(trainingSession: TrainingSession) :  Observable<any> {
    return this.http.get<any>(this.API_SERVER + 'training-sessions/result-items/' + trainingSession.id);
  }

  public getTrainingSessionResult(trainingSession: TrainingSession) :  Observable<any> {
    return this.http.get<any>(this.API_SERVER + 'training-sessions/result/' + trainingSession.id);
  }

  public getCommonTrainingSessionAnalyze() :  Observable<any> {
    return this.http.get<any>(this.API_SERVER + 'training-sessions/common-analyze');
  }

  public recognizeAudio(audio: Audio) :  Observable<any> {
    return this.http.get<any>(this.API_SERVER + 'audios/recognize/' + audio.id);
  }

  public analyzeAudio(audio: Audio) :  Observable<any> {
    return this.http.get<any>(this.API_SERVER + 'audios/analyze/' + audio.id);
  }

  public analyzeSinging(audio: Audio) :  Observable<any> {
    return this.http.get<any>(this.API_SERVER + 'audios/pitching/' + audio.id);
  }

  public analyzeAge(audio: Audio) :  Observable<any> {
    return this.http.get<any>(this.API_SERVER + 'audios/age/' + audio.id);
  }

  public analyzeAccent(audio: Audio) :  Observable<any> {
    return this.http.get<any>(this.API_SERVER + 'audios/accent/' + audio.id);
  }

  public analyzeType(audio: Audio) :  Observable<any> {
    return this.http.get<any>(this.API_SERVER + 'audios/archetype/' + audio.id);
  }

  public analyzeCelebrity(audio: Audio) :  Observable<any> {
    return this.http.get<any>(this.API_SERVER + 'audios/celebrity/' + audio.id);
  }

  public deleteAudio(audio: Audio) :  Observable<any> {
    return this.http.delete<any>(this.API_SERVER + 'audios/' + audio.id);
  }


  public getFavourites() :  Observable<Training[]> {
    return this.http.get<Training[]>(this.API_SERVER + 'favourites');
  }

  public addToFavourites(id: number) :  Observable<any> {
    return this.http.put<any>(this.API_SERVER + 'favourites/' + id, {});
  }

  public deleteFromFavourites(id: number) :  Observable<any> {
    return this.http.delete<any>(this.API_SERVER + 'favourites/' + id, {});
  }

  public getTipById(code: string) :  Observable<Tip> {
    return this.http.get<Tip>(this.API_SERVER + 'info/tips/' + code);
  }



  public saveTrainingRating(trainingPractice: TrainingPractice) :  Observable<TrainingPractice> {
    return this.http.post<TrainingPractice>(
      this.API_SERVER + 'trainings/' + trainingPractice.training_id + '/rating',
      trainingPractice
    );
  }

}
