<div class="container topper">
  <div class="page-content text-center">
    <h1>{{ 'Page.ToApp.title' | translate }}</h1>
    <hr class="br" />
    <h2 class="h4">{{ 'Page.ToApp.text' | translate }}</h2>
    <div class="mb-3 ii">
      <a href="#" (click)="install()" class="btn btn-primary d-block">{{ 'Page.ToApp.button' | translate }}</a>
      <br />
      <div>
        <app-download></app-download>
      </div>
    </div>
    <p class="note header mb-0">{{ 'Page.ToApp.note' | translate }}</p>
  </div>
</div>
