export enum LanguageEnum {
  en = 'English',
  es = 'Español',
  ru = 'Русский',
  uk = 'Українська',
  de = 'Deutsch',
  fr = 'Français',
}

// export enum LanguageIdEnum {
//   en = 1,
//   es = 2,
//   ru = 3,
//   uk = 4,
//   de = 5,
// }
