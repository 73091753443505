import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from '../../_services/auth.service';

@Component({
  selector: 'app-token',
  templateUrl: './token.component.html'
})
export class TokenComponent implements OnInit {

  public loading = true;

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.auth.tokenAuth(
      this.route.snapshot.paramMap.get('token')
    ).subscribe(
      () => this.loading = false,
      () => this.loading = false
    );
  }

}
