<div class="panel bg-secondary gap-0 text-start h-lg-100">
  <p class="d-flex align-items-center justify-content-between mb-2 mb-lg-4">
    <span class="subtitle fw-bold" translate>Text to record</span>
    <button class="btn btn-service btn-md" (click)="onChangeFontSizeTap()">
      <span class="i">Aa</span>
      <app-icon icon="arrow-up" width="8" height="14" [rotate]="fontSizeDirection != 'forward' ? 180 : 0"/>
    </button>
  </p>

  <pre class="p-0 mb-2 mb-lg-4 small" [style.font-size]="fontSize + 'px'">{{txt}}</pre>
  <i class="small subtitle">{{source}}</i>
</div>
