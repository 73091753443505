import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-foot-logged',
  templateUrl: './foot.component.html',
  styleUrls: ['./foot.component.css']
})
export class FootLoggedComponent implements OnInit, OnDestroy {

  public area: string = null;
  public enabled = true;

  private eventsSubscription: Subscription = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
  ) {
  }

  ngOnInit(): void {
    this.eventsSubscription = this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (this.route.firstChild.snapshot.data) {
            this.area = this.route.firstChild.snapshot.data.area;
            this.enabled = !['training', 'settings', 'audio'].includes(this.area);

            if (this.enabled)
              this.renderer.addClass(document.body, 'with-foot');
            else
              this.renderer.removeClass(document.body, 'with-foot');
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.eventsSubscription.unsubscribe();
  }
}
