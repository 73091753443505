import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {Course, User} from '../../_business';
import {DataService} from '../../_services/data.service';
import {Location} from '@angular/common';
import {masonryOptions} from "../../_helpers/lib";
import {faHeart} from "@fortawesome/free-regular-svg-icons";
import {AuthService} from "../../_services/auth.service";


@Component({
  selector: 'app-lectures',
  templateUrl: './lectures.component.html',
  styleUrls: ['./lectures.component.css']
})
export class LecturesComponent implements OnInit {

  private lastScroll = 0;

  public loading = true;
  public error;
  public courses: Course[] = [];
  public selectedCourse: Course = null;
  public id: number;
  public user: User = null;

  protected readonly masonryOptions = masonryOptions;

  constructor(
    private dataService: DataService,
    private titleService: Title,
    private translate: TranslateService,
    private router: Router,
    private _location: Location,
    private auth: AuthService,
  ) {
    this.translate.get(['Talks']).subscribe(
      res => {
        console.log(res);
        this.titleService.setTitle(res.Talks);
      }
    );
  }

  ngOnInit(): void {
    this.user = this.auth.user;

    if (this.id) {
      this.dataService.getCourseById(this.id).subscribe(
        (data) => {
          this.selectedCourse = data;
        }
      )
        .add(() => this.loading = false);
    } else {
      this.dataService.getCourses().subscribe(
        (data: Course[]) => {
          this.courses = data;
        }
      )
        .add(() => this.loading = false);
    }
  }

  public onShowPop(course: Course) {
    // if (course.paid)
    //   this.router.navigate(['/subscribe']);
    // else {
      this.selectedCourse = course;
      this.lastScroll = window.pageYOffset;
      window.scroll(0,0);
    // }
  }

  public onClosePop() {
    if (this.id)
      this._location.back();
    else {
      this.selectedCourse = null;
      window.scroll(0, this.lastScroll);
    }
  }

  protected readonly farHeart = faHeart;
}
