<div class="container">

  <button role="button" class="btn btn-lg btn-close" (click)="response.emit('close')"></button>

  <h1>{{ podcast.name }}</h1>

  <div class="card">
    <div class="card-body page">
      <div class="row">
        <div class="col-xl-4 show-poster mb-4 mb-xl-0">
          <div *ngIf="movieUrl" plyr [plyrSources]="[{ src: movieUrl, type: 'video/mp4' }]" [plyrTitle]="podcast.name"
               [plyrPoster]="podcast.images.main.url" [plyrPlaysInline]="true" [plyrOptions]="isMobile() ? {} : {ratio: '1:1'}" (plyrEnded)="onEnd($event)"></div>
        </div>
        <div class="col-xl-8">
          <h2 translate>Overview</h2>
          <pre class="p-0 mb-0 small subheader">{{ podcast.description }}</pre>
        </div>
      </div>
    </div>
  </div>

</div>
