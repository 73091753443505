import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {AuthService} from '../_services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      console.log('ErrorInterceptor', err);

      let error = err.statusText || err.message;

      switch (err.status) {
        case 400:
          error = 'Request parameters error';
          break;

        case 401: // Необходима авторизация, в запросах отсутствует токен
        case 498: // Истекший или невалидный токен
          // auto logout if 401 response returned from api
          if (this.auth.user && request.headers.get('Authorization') === 'Bearer ' + this.auth.user.token) {
            this.auth.logout();
            location.reload();
          }

          break;

        case 403:
          error = 'Incorrect data entry';
          break;

        case 404:
          error = 'Object not found';
          break;

        case 405:
          error = 'Method execution is not allowed based on certain criteria';
          break;

        case 406:
          error = 'The object already exists';
          break;

        case 409:
          error = 'The method cannot be executed due to a violation of the sequence of actions or the state of objects';
          break;

        case 415:
          error = 'Incorrect media data format, incorrect link format, image, etc.';
          break;

        case 501:
          error = 'Method or service is not supported on the server';
          break;

        case 503:
          error = 'Service is temporarily unavailable';
          break;

        default:
          error = 'Unexpected error';
          break;
      }

      if (err.error) {
        if (err.error.Error) {
          error = '#' + err.error.Status + ' ' + err.error.Error;
        } else if (typeof(err.error) === 'string') {
          const ptxt = '<b>message</b>';
          const p1 = err.error.indexOf(ptxt);
          const p2 = err.error.indexOf('</p>', p1 + ptxt.length);

          if (p1 > -1 && p2 > 0) {
            const e = err.error.substring(p1 + ptxt.length, p2);

            if (e) {
              error = e;
            }
          }
        }
      }

      console.error({ error: error, status: err.status });

      return throwError({ error: error, status: err.status } );
    }));
  }
}
