<div *ngIf="!selectedIntensive" class="container">

  <app-courses-head title="Training programs"></app-courses-head>

  <app-loading *ngIf="loading"/>

  <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 mb-3">
    <div class="card" *ngFor="let item of intensives" [ngClass]="{'paid': item.paid}">
      <a class="card-link" href="javascript:void(0)" (click)="onShowPop(item)"></a>
      <div class="card-title">
        <p class="subtitle">
          <svg-icon src="/assets/img/icons/fire.svg" class="i" [svgStyle]="{ 'fill': (item.paid ? '#E26564' : '#4d5ebf') }"></svg-icon>{{ 'Training course' | translate }}
        </p>
        <div class="overflow">{{ item.name }}</div>
      </div>
      <img class="card-img" [attr.src]="item.images.main.url" alt="" width="570" height="320" />
      <div class="card-img-overlay">
        <p class="card-text">{{ item.short_description }}</p>
        <div class="row align-items-center">
          <div class="col">
            <a href="javascript:void(0)" class="btn" [ngClass]="{'btn-primary': !item.paid, 'btn-danger': item.paid}">
              <svg-icon *ngIf="item.paid" src="/assets/img/icons/lock.svg" class="i"></svg-icon>{{ 'Watch' | translate }}
            </a>
          </div>
          <div class="col text-end subtitle">
            <svg-icon src="/assets/img/icons/course.svg" class="i"></svg-icon>{{ item.training_count | i18nPlural: {'=0': 'Params.lectures.zero', '=1': 'Params.lectures.one', 'few': 'Params.lectures.few', 'other': 'Params.lectures.other'} | translate:{number: item.training_count} }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="error" class="alert alert-danger">{{ error | translate }}</div>
</div>

<app-course *ngIf="selectedIntensive" [intensive]="selectedIntensive" (response)="onClosePop()"></app-course>
