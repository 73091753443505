<div *ngIf="!selectedPodcast" class="container">
  <app-tip-load code="podcasts"/>

  <h1 class="d-none d-lg-block" translate>Podcasts</h1>

<!--  <div class="plyr-inline">
    <div plyr [plyrTitle]="'training.name'" [plyrPlaysInline]="true" [style.max-width]="800 + 'px'"
         [plyrOptions]="{autoplay: true, controls: ['rewind', 'play', 'fast-forward'], iconUrl: '/assets/img/icons/sprite-plyr.svg'}"
         [plyrSources]="[{ src: 'https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-576p.mp4', type: 'video/mp4' }]"
    ></div>
  </div>-->

  <app-explore-tabs class="d-lg-none"/>

  <app-loading *ngIf="loading"/>

  <ngx-masonry [options]="masonryOptions" ordered="true">
    <div *ngFor="let item of podcasts" ngxMasonryItem class="card grid-item" [ngClass]="{'paid': item.paid}" role="button" (click)="onShowPop(item)">
      <div class="card-title">
        <h2 class="overflow">{{ item.name }}</h2>
      </div>

      <button *ngIf="!user.premium && item.paid" routerLink="/subscribe" class="btn btn-xss btn-premium card-sign" translate>Premium</button>

      <div class="card-body pt-0">
        <img class="card-img" [attr.src]="item.images.main.url" alt="" width="570" height="320" />
        <p class="card-text subheader">{{ item.short_description }}</p>
        <button class="btn btn-md w-100" [ngClass]="{'btn-primary': !item.paid, 'btn-default subheader fw-normal': item.paid}">
<!--          <app-icon *ngIf="item.paid" icon="lock" class="i"></app-icon>-->
          {{ 'Continue' | translate }}
        </button>
      </div>
    </div>
  </ngx-masonry>

  <div *ngIf="error" class="alert alert-danger">{{ error | translate }}</div>
</div>

<app-podcast *ngIf="selectedPodcast" [podcast]="selectedPodcast" (response)="onClosePop()"></app-podcast>
