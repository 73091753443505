import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuardService} from './_services/auth-guard.service';
import {GuestGuardService} from './_services/guest-guard.service';
import {AuthComponent} from './promo/auth/auth.component';
import {HomeComponent} from './logged/home/home.component';
import {NotfoundComponent} from './promo/not-found/not-found.component';
import {RegComponent} from './promo/reg/reg.component';
import {ReconfirmComponent} from './promo/reconfirm/reconfirm.component';
import {ConfirmComponent} from './promo/confirm/confirm.component';
import {RestoreComponent} from './promo/restore/restore.component';
import {SettingsComponent} from './logged/settings/settings.component';
import {CoachComponent} from './logged/coach/coach.component';
import {AudioComponent} from './logged/audio/audio.component';
import {StatisticsComponent} from './logged/statistics/statistics.component';
import {SupportComponent} from './promo/support/support.component';
import {LessonsComponent} from './logged/lessons/lessons.component';
import {LessonComponent} from './logged/lessons/lesson/lesson.component';
import {ToAppComponent} from './promo/to-app/to-app.component';
import {PaymentComponent} from './logged/payment/payment.component';
import {PaywallComponent} from './logged/paywall/paywall.component';
import {TokenComponent} from './promo/token/token.component';
import {UnsubscribeComponent} from './promo/unsubscribe/unsubscribe.component';
import {AudioShareComponent} from './logged/audio-share/audio-share.component';
import {SpecialComponent} from './promo/special/special.component';
import {SpecialOfferPageComponent} from './promo/special-offer-page/special-offer-page.component';
import {MessagesComponent} from './logged/messages/messages.component';
import {CoursesComponent} from './logged/courses/courses.component';
import {PodcastsComponent} from './logged/podcasts/podcasts.component';
import {LecturesComponent} from './logged/lectures/lectures.component';
import {TrainingComponent} from './logged/training/training.component';
import { ResetComponent } from './promo/reset/reset.component';
import { GoUnsubscribeComponent } from './promo/go-unsubscribe/go-unsubscribe.component';
import { GoConfirmComponent } from './promo/go-confirm/go-confirm.component';
import {DeleteComponent} from './logged/delete/delete.component';
import {ProfileComponent} from "./logged/profile/profile.component";
import {PendingChangesGuard} from "./_services/pending-changes.guard";

const routes: Routes = [
  {
    path: '',
    redirectTo: '/profile',
    pathMatch: 'full'
  },

  {
    path: 'auth',
    component: AuthComponent,
    data: {area: 'auth', section: 'static'},
    canActivate: [GuestGuardService]
  },
  {
    path: 'reg',
    component: RegComponent,
    data: {area: 'reg', section: 'static'},
    canActivate: [GuestGuardService]
  },
  {
    path: 'confirm/:memberId/:code',
    component: ConfirmComponent,
    data: {area: 'confirm', section: 'static'},
    //canActivate: [GuestGuardService]
  },
  {
    path: 'reconfirm',
    component: ReconfirmComponent,
    data: {area: 'reconfirm', section: 'static'},
    //canActivate: [GuestGuardService]
  },
  {
    path: 'reset',
    component: ResetComponent,
    data: {area: 'reconfirm', section: 'static'},
    //canActivate: [GuestGuardService]
  },
  {
    path: 'restore/:memberId/:code',
    component: RestoreComponent,
    data: {area: 'restore', section: 'static'},
    canActivate: [GuestGuardService]
  },
  {
    path: 'unsubscribe/:memberId/:code',
    component: UnsubscribeComponent,
    data: {area: 'unsubscribe', section: 'static'}
  },
  {
    path: 'go-unsubscribe/:uid',
    component: GoUnsubscribeComponent,
    data: {area: 'go-unsubscribe', section: 'static'}
  },
  {
    path: 'go-confirm/:uid',
    component: GoConfirmComponent,
    data: {area: 'go-confirm', section: 'static'},
    //canActivate: [GuestGuardService]
  },
  {
    path: 'audios/share/:uid',
    component: AudioShareComponent,
    data: {area: 'audio-share', section: 'static'}
  },
  {
    path: 'special/:partner',
    component: SpecialComponent,
    data: {area: 'special', section: 'static'}
  },
  {
    path: 'special/:partner/:paymentResult',
    component: SpecialComponent,
    data: {area: 'special', section: 'static'}
  },
  {
    path: 'special-offer/:memberId/:coupon/:code',
    component: SpecialOfferPageComponent,
    data: {area: 'special-offer', section: 'static'}
  },
  {
    path: 'token/:token',
    component: TokenComponent,
    data: {area: 'token', section: 'static'},
    canActivate: [GuestGuardService]
  },
  {
    path: 'support',
    component: SupportComponent,
    data: {area: 'support', section: 'static'}
  },
  /*{
    path: 'terms',
    component: TermsComponent,
    data: {area: 'terms', section: 'static'}
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
    data: {area: 'privacy', section: 'static'}
  },
  */
  {
    path: 'install',
    component: ToAppComponent,
    data: {area: 'install', section: 'static'}
  },
  {
    path: '404',
    component: NotfoundComponent,
    data: {area: 'page-404', section: 'static'}
  },

  {
    path: 'home',
    component: HomeComponent,
    data: {area: 'home', section: 'cabinet'},
    canActivate: [AuthGuardService]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    data: {area: 'profile', section: 'cabinet'},
    canActivate: [AuthGuardService]
  },
  {
    path: 'messages',
    component: MessagesComponent,
    data: {area: 'messages', section: 'cabinet'},
    canActivate: [AuthGuardService]
  },
  {
    path: 'settings',
    component: SettingsComponent,
    data: {area: 'settings', section: 'cabinet'},
    canActivate: [AuthGuardService]
  },
  {
    path: 'delete',
    component: DeleteComponent,
    data: {area: 'delete', section: 'cabinet'},
    canActivate: [AuthGuardService]
  },
  {
    path: 'lessons',
    component: LessonsComponent,
    data: {area: 'lessons', section: 'cabinet'},
    canActivate: [AuthGuardService]
  },
  {
    path: 'lessons/view/:id',
    component: LessonComponent,
    data: {area: 'lesson'},
  },
  {
    path: 'courses',
    component: CoursesComponent,
    data: {area: 'courses', section: 'cabinet'},
    canActivate: [AuthGuardService]
  },
/*  {
    path: 'courses/view/:id',
    component: CourseComponent
  },*/
  {
    path: 'podcasts',
    component: PodcastsComponent,
    data: {area: 'podcasts', section: 'cabinet'},
    canActivate: [AuthGuardService]
  },
/*  {
    path: 'podcasts/:id',
    component: PodcastComponent
  },*/
  {
    path: 'lectures',
    component: LecturesComponent,
    data: {area: 'lectures', section: 'cabinet'},
    canActivate: [AuthGuardService]
  },
  {
    path: 'trainer',
    component: CoachComponent,
    data: {area: 'trainer', section: 'cabinet'},
    canActivate: [AuthGuardService]
  },
  {
    path: 'audio',
    component: AudioComponent,
    data: {area: 'audio', section: 'cabinet'},
    canActivate: [AuthGuardService]
  },
  {
    path: 'statistics',
    component: StatisticsComponent,
    data: {area: 'statistics', section: 'cabinet'},
    canActivate: [AuthGuardService]
  },
  {
    path: 'subscribe/:type',
    component: PaywallComponent,
    data: {area: 'subscribe', section: 'cabinet'},
    canActivate: [AuthGuardService]
  },
  { path: 'subscribe', redirectTo: 'subscribe/paywall', pathMatch: 'full' },
  {
    path: 'payment/:result',
    component: PaymentComponent,
    data: {area: 'payment', section: 'cabinet'},
    canActivate: [AuthGuardService]
  },
  {
    path: 'training/:id',
    component: TrainingComponent,
    data: {area: 'training', section: 'cabinet'},
    canActivate: [AuthGuardService],
    canDeactivate: [PendingChangesGuard]
  },

  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
