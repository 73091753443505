import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// import { GoogleAnalyticsService } from 'ngx-google-analytics';
import {Subscription} from 'rxjs';
import {StateService} from 'src/app/_services/state.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent implements OnInit, OnDestroy {

  @Input() postfix = '';
  @Input() inline = false;
  private pageName: string = null;
  private _pageNameSubscription: Subscription;
  public readonly storeLink = environment.storeLink;

  constructor(
    // private _gaService: GoogleAnalyticsService,
    private _stateService: StateService,
  ) {
  }

  ngOnInit(): void {
    this._pageNameSubscription = this._stateService.getCurrentActiveItem().subscribe(
      (pageName: string) => this.pageName = pageName
    );
  }

  ngOnDestroy(): void {
    if (this._pageNameSubscription)
      this._pageNameSubscription.unsubscribe();
  }

  public onInstall(os: string) {

    // if (this.postfix == 'm2f') {
    //     this._gaService.event('click', 'mtf');
    // } else {
    //
    //     const label = os + this.postfix;
    //
    //     if (this.pageName)
    //         this._gaService.event(
    //             'click',
    //             this.pageName,
    //             label
    //         );
    // }

  }

}
