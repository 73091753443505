<app-paywall-details *ngIf="user.premium || type == 'offer'"></app-paywall-details>


<div class="container" *ngIf="!user.premium && type != 'offer'">
  <p class="h1 mb-6 d-flex align-items-center d-lg-none">
    <a routerLink="/profile" class="btn p-0 me-3 subtitle"><app-icon icon="left"/></a>
    {{ 'Membership' | translate }}
  </p>

  <h1 class="display-3 fw-bold text-center mb-4">
    {{ 'Page.Paywall.title' | translate }}
  </h1>
  <p class="subheader text-center mb-6">
    {{ 'Page.Paywall.notice' | translate }}
  </p>

  <p *ngIf="loading" class="text-center"><app-loading/></p>

  <div *ngIf="!loading" class="card pt-lg-4">
    <div class="card-body p-lg-0 bg-lg-transparent">


      <div class="items d-inline-flex flex-column align-items-start gap-4 flex-lg-row w-100 mb-4" (window:resize)="onResize()">
        <label *ngFor="let item of tarifList" class="block-plan period-{{item.period}}" [ngClass]="{'popular': item.popular, 'active': item.selected }" (mouseenter)="switchActive(item)">
          <span *ngIf="item.promo" class="promo small"><em>{{item.promo}}</em></span>
          <span class="item w-100 d-flex align-items-center gap-3 flex-lg-column gap-lg-0">
            <span *ngIf="tarifList.length > 1" class="form-check d-lg-none">
              <input type="radio" name="language" class="form-check-input" value="{{ item.id }}" [attr.checked]="item.selected ? 'checked' : null" (change)="switchActive(item)">
            </span>
            <span class="w-100 text-start text-lg-center">
              <strong class="d-block mb-lg-4">{{item.name}}</strong>
              <span *ngIf="item.promo2" class="d-none d-lg-inline-block small mb-lg-4" style="color: #5DDEA7; background-color: rgba(93, 222, 167, 0.2); padding: 8px 16px; border-radius: 8px;">{{item.promo2}}</span>
              <span>
                <em class="small d-lg-block">{{item.currency}} {{item.fullPrice}}</em>&nbsp;
                <s *ngIf="item.standardPrice || item.price" class="note lg">{{item.currency}} {{item.standardPrice || item.price}}</s>
              </span>
            </span>
            <hr class="d-none d-lg-block my-4 w-100"/>
            <span class="nowrap align-items-lg-center mb-lg-4">
              <sup>{{item.currencySymbol}}</sup>
              <span class="big">{{Math.trunc(item.dayPrice)}}</span>
              <span class="d-flex flex-column align-items-center">
                <em>{{item.dayPrice % 1 * 100 | number:'1.0-0'}}</em>
                <small>{{'Page.Paywall.GetMyPlan.per_day' | translate}}</small>
              </span>
            </span>

            <button class="btn w-100 mb-4 d-none d-lg-block" [ngClass]="{'btn-primary': item.selected, 'btn-secondary text-primary': !item.selected}" (click)="goToPayment()">{{'Page.Paywall.GetMyPlan.btn' | translate}}</button>

            <span class="note d-none d-lg-block">
              {{'Page.Paywall.GetMyPlan.following_periods' | translate}}
              {{item.currencySymbol}}{{item.price}}/{{item.name.replace('1 ', '')}}
            </span>

          </span>
        </label>
      </div>

      <div class="d-flex gap-3 align-items-center mb-4 panel-lg flex-row descr">
        <em style="margin-bottom: 22px">👍</em>
        <div>
          <p class="small mb-1">{{'Page.Paywall.GetMyPlan.desc' | translate}}</p>
          <p class="note mb-0">{{'Page.Paywall.GetMyPlan.note' | translate}}</p>
        </div>
      </div>

      <div class="d-lg-none">
        <button class="btn btn-primary w-100 mb-4" (click)="goToPayment()">{{'Page.Paywall.GetMyPlan.btn' | translate}}</button>

        <p class="d-flex gap-2 success-text justify-content-center">
          <img src="/assets/img/icons/secure.svg" alt="" width="14" height="16" />
          <em class="small">{{'Page.Paywall.GetMyPlan.secure' | translate}}</em>
        </p>

        <img src="/assets/img/logos.png" alt="stripe" class="mw-100 d-block mx-auto" width="326" height="22" style="padding: 0 15px" />
      </div>


    </div>
  </div>

<!--  <div class="paywall">
    <div class="options">
      &lt;!&ndash; <h5 class="empty">{{ 'Page.Paywall.price_per_month' | translate }}</h5>&ndash;&gt;
      <h5 class="empty">&nbsp;</h5>
      <h5 innerHTML="{{ 'Page.Paywall.option1' | translate }}"></h5>
      <h5 innerHTML="{{ 'Page.Paywall.option2' | translate }}"></h5>
      <h5 innerHTML="{{ 'Page.Paywall.option3' | translate }}"></h5>
      <h5 innerHTML="{{ 'Page.Paywall.option4' | translate }}"></h5>
    </div>

    <div class="row">
      <div class="col"></div>

      <div class="col" *ngFor="let tarif of tarifList">
        <div class="card" [ngClass]="{'active': tarif.active, 'with-subtitle': tarif.subtitle}" (mouseenter)="switchActive(tarif)">
          <div *ngIf="tarif.subtitle" class="subtitle">{{ 'Page.Paywall.economy' | translate }} {{ tarif.subtitle }}</div>
          <img *ngIf="tarif.popular" src="/assets/img/icons/TOP.svg" alt="" class="popular"/>
          <div class="card-body">
            <div class="item">
              <div *ngIf="tarif.timer" class="timer">
                <img src="/assets/img/icons/hourglass.svg" alt=""/>
                &lt;!&ndash;                2d : 12h : 45m : 12s&ndash;&gt;
                <countdown #cd [config]="config" (event)="handleEvent($event)"></countdown>
              </div>
              <div *ngIf="!tarif.timer" class="timer opacity-0">&nbsp;</div>
              <h3 *ngIf="!tarif.price">{{ 'Page.Paywall.free' | translate }}</h3>
              <h3 *ngIf="tarif.price && tarif.monthly">{{ 'Page.Paywall.monthly' | translate }}</h3>
              <h3 *ngIf="tarif.price && !tarif.monthly">{{ 'Page.Paywall.yearly' | translate }}</h3>
              <h4 *ngIf="tarif.price" class="h2">{{ tarif.price }} <span class="note">/ {{ 'Page.Paywall.mo' | translate }}</span></h4>
              <div *ngIf="tarif.price && tarif.monthly" class="note">{{ 'Page.Paywall.billed_monthly' | translate }}</div>
              <div *ngIf="tarif.price && !tarif.monthly" class="note">{{ 'Page.Paywall.billed_annually' | translate }}</div>
            </div>
            <div class="item" *ngFor="let option of options; let i = index" [ngClass]="{'with-note': option}">
              &lt;!&ndash;          <i class="fas fa-times"></i>&ndash;&gt;
              &lt;!&ndash;          <i class="fas fa-check"></i>&ndash;&gt;

              <dl class="row align-items-center">
                &lt;!&ndash;                <dt class="col-9 d-md-none" [innerHTML]="option"></dt>&ndash;&gt;
                <dd class="col">
                  <svg-icon *ngIf="!tarif.options[i]" src="/assets/img/icons/close.svg" class="icon"></svg-icon>
                  <svg-icon *ngIf="tarif.options[i]" src="/assets/img/icons/check.svg" class="icon check" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
                </dd>
              </dl>
            </div>
          </div>
          <a *ngIf="tarif.id" href="javascript:void(0)" (click)="goToPayment(tarif.id)" class="btn w-100">{{ 'Page.Paywall.continue' | translate }}</a>
        </div>
      </div>

    </div>
  </div>-->

</div>

<!--<div class="container" *ngIf="error">
  <div class="alert alert-danger">{{ 'DefaultError' | translate }}</div>
</div>-->
