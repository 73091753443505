{
  "name": "vocalimage-dash",
  "version": "0.1.27",
  "scripts": {
    "ng": "ng",
    "start": "ng serve -c local",
    "build": "ng build",
    "build-dev": "ng build --configuration development",
    "build-prod": "ng build --configuration production",
    "make-build-scp": "make scp",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.6",
    "@angular/common": "^17.3.6",
    "@angular/compiler": "^17.3.6",
    "@angular/core": "^17.3.6",
    "@angular/forms": "^17.3.6",
    "@angular/platform-browser": "^17.3.6",
    "@angular/platform-browser-dynamic": "^17.3.6",
    "@angular/router": "^17.3.6",
    "@fortawesome/angular-fontawesome": "^0.14.1",
    "@fortawesome/fontawesome-svg-core": "^6.5.2",
    "@fortawesome/free-brands-svg-icons": "^6.5.2",
    "@fortawesome/free-regular-svg-icons": "^6.5.2",
    "@fortawesome/free-solid-svg-icons": "^6.5.2",
    "@ng-bootstrap/ng-bootstrap": "^16.0.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@types/mixpanel-browser": "^2.49.0",
    "angular-svg-icon": "^17.0.0",
    "bootstrap": "^5.3.3",
    "highcharts": "^11.4.1",
    "highcharts-angular": "^4.0.0",
    "masonry-layout": "^4.2.2",
    "mixpanel-browser": "^2.52.0",
    "ng-rive": "^0.3.1",
    "ngx-countdown": "^17.1.1",
    "ngx-lottie": "^11.0.2",
    "ngx-masonry": "^14.0.1",
    "plyr": "^3.7.8",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.4"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.6",
    "@angular/cli": "^17.3.6",
    "@angular/compiler-cli": "^17.3.6",
    "@angular/localize": "^17.3.6",
    "@types/jasmine": "~5.1.4",
    "jasmine-core": "~5.1.2",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.4.5"
  }
}
