import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '../../_services/data.service';
import {Settings, User} from '../../_business';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Achieve} from '../../_business/achieve.model';
import {AchieveModalComponent} from './achieve-modal/achieve-modal.component';
import {cloneDeep, fullscreenOptions, install, isMobile} from "../../_helpers/lib";
import {AuthService} from "../../_services/auth.service";
import {environment} from "../../../environments/environment";

import Highcharts from 'highcharts/es-modules/masters/highcharts.src';
// import * as Highcharts from 'highcharts';
// import Exporting from 'highcharts/modules/exporting';

import {ProgressDetailsModalComponent} from "./progress-details-modal/progress-details-modal.component";
import {TranslateService} from "@ngx-translate/core";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, AfterViewInit, OnDestroy {

  public loading = true;
  public user: User = null;
  public achieves: Achieve[] = [];

  // public translates: any;
  public scroll = 0;
  public scrollWidth = 0;
  public scrollInnerWidth = 0;
  @ViewChild("graphs") graphs: ElementRef;

  detailsModal: NgbModalRef;
  achieveModal: NgbModalRef;

  public readonly helpLink = environment.helpLink;

  // public managerLink: string = null;

  public Highcharts: typeof Highcharts = Highcharts;
  public chartOptionsPlot: Highcharts.Options;
  public chartList = [];

  constructor(
    private auth: AuthService,
    private dataService: DataService,
    private modalService: NgbModal,
    public translate: TranslateService,
    private router: Router,
    private cd: ChangeDetectorRef,
  ) {
    // const
    //   date = new Date(),
    //   names = [];
    //
    // for (let i = 0; i < 10; i++) {
    //   date.setDate(
    //     date.getDate() - 1
    //   );
    //   names.push(
    //     date.toLocaleDateString(this.translate.currentLang, {month: "short", day: "numeric"})
    //   );
    // }
    //
    // names.reverse();

    this.chartOptionsPlot = {
      accessibility: {
        enabled: false
      },
      chart: {
        width: 270,
        height: 150,
        backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent background
        renderTo: 'container',
        type: 'line',
        margin: [5, 5, 30, 40],
      },
      title: {
        text: '',
      },
      subtitle: {
        text: '',
      },
      xAxis: {
        title: {
          text: null,
        },
        labels: {
          enabled: true, // Enable X-axis labels
          style: {
            color: 'var(--white-50)', // Set the color of X-axis labels
          },
        },
        minorTickLength: 0,
        tickLength: 0,
        visible: true,
        lineColor: 'rgba(0, 0, 0, 0)', // Transparent X-axis line
        lineWidth: 0, // Remove X-axis line
        gridLineColor: 'var(--bg-50)', // Set grid line color to #2B3242
        gridLineWidth: 1, // Set the width of vertical grid lines
        tickInterval: 3, // Set the interval between grid lines on x-axis
        // categories: names, // Replace x-axis labels with custom names,
        // max: names.length - 1, // Set the maximum value for x-axis,
        plotLines: [{
          value: -0.5, // Adjust the value to position the line properly
          width: 1, // Set the width of the line
          color: 'var(--bg-50)', // Set the color of the line
          zIndex: 4, // Set a higher zIndex to bring the line to the front
        }],
      },
      yAxis: {
        title: {
          text: null,
        },
        labels: {
          enabled: true, // Enable X-axis labels
          style: {
            color: 'var(--white-50)', // Set the color of X-axis labels
            margin: '0', // Set margin to 0
            padding: '0', // Set padding to 0
          },
          formatter: function() {
            return this.value + '%'; // Append '%' to the value
          },
        },
        visible: true,
        lineColor: 'rgba(0, 0, 0, 0)', // Transparent y-axis line
        lineWidth: 0, // Remove y-axis line
        gridLineColor: 'var(--bg-50)', // Set grid line color to #2B3242
        gridLineWidth: 1, // Set the width of horizontal grid lines
        tickInterval: 33, // Set the interval between grid lines on y-axis
        max: 99, // Set the maximum value of y-axis to the maximum value of data
        plotLines: [{
          value: 0, // Set the value to position the line at the lowest point
          width: 1, // Set the width of the line
          color: 'var(--bg-50)', // Set the color of the line
          zIndex: 4, // Set a higher zIndex to bring the line to the front
        }],
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        series: {
          marker: {
            enabled: true, // Disable markers for all points
          },
          // color: '#97B8FF'
          // lineColor: '#97B8FF', // Set the line color to red
        },
        area: {
          fillColor: {
            linearGradient: {x1: 0, y1: 0, x2: 0, y2: 1},
            stops: [
              [0, 'rgba(44,175,254,0.5)'],
              [1, 'rgba(44,175,254,0)'],
              // [0, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0.5).get('rgba')],
              // [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')],
            ],
          },
          marker: {
            enabled: true, // Disable markers for all points
          },
          lineWidth: 2,
          threshold: null,
        },
      },
      series: [{
        type: 'area',
        color: '#97B8FF',
        pointPlacement: 'off',
        showInLegend: false,
        enableMouseTracking: false,
        lineWidth: 2,
        // data: [],
        // data: this.data,
        zIndex: 1, // Set higher zIndex to bring the marker to front
        marker: {
          enabled: true, // Show marker for the last point only
          symbol: 'circle', // Set the marker shape to a circle
          radius: 4, // Set the marker radius
        },
      }],
    };
  }

  ngOnInit(): void {
    this.user = this.auth.user;

    if (!this.user.name)
      this.dataService.getSettings().subscribe(
        (settings: Settings) => {
          this.user.name = settings.name;
          this.auth.user = {...this.user};
        }
      )
        .add(() => this.loading = false);

    this.dataService.getAchieves().subscribe(
      (data) => {
        this.achieves = data;
      }
    )
      .add(() => this.loading = false);

    this.dataService.getMemberAnalysisStats().subscribe(
      (data) => {
        for (const item of data) {
          let d = [], names = [];

          if (item.results?.length) {
            for (const r of item.results) {
              names.push(r.x);
              d.push(r.y);
            }
          } else {
            d = Array(10).fill(0);

            const date = new Date();

            for (let i = 0; i < 10; i++) {
              date.setDate(
                date.getDate() - 1
              );
              names.push(
                date.toLocaleDateString(this.translate.currentLang, {month: "short", day: "numeric"})
              );
            }

            names.reverse();
          }

          // d[1] = 78;

          if (d.length) {
            // item.proc = Math.max(...d);
            item.proc = d.reduce((a, b) => (a + b)) / d.length;
          }

          // item.options = {...this.chartOptionsPlot};
          item.options = cloneDeep(this.chartOptionsPlot);

          const xAxis = item.options.xAxis as any;
          xAxis.categories = names;
          xAxis.max = names.length - 1;

          if (item.options.series[0].type === 'area')
            item.options.series[0].data = d;

          this.chartList.push(item);

          setTimeout(() => {
            this.scrollInnerWidth = this.graphs.nativeElement.lastChild.clientWidth;
          })
        }
      }
    );

/*    this.dataService.getSubscriptionManagerLink().subscribe(
      (data: any) => this.managerLink = data.url
    )
      .add(
        () => this.loading = false
      );*/
  }

  ngAfterViewInit() {
    this.onResize();
  }

  ngOnDestroy(): void {
    this.detailsModal && this.detailsModal.dismiss('close');
    this.achieveModal && this.achieveModal.dismiss('close');
  }

  public showProgressDetailsModal(item: any) {
    if (item.premium && !this.user.premium) {
      this.router.navigate(['/subscribe']);
      return;
    }

    this.detailsModal = this.modalService.open(
      ProgressDetailsModalComponent,
      fullscreenOptions
    );
    // delete item.options;
    this.detailsModal.componentInstance.item = cloneDeep(item);
    // this.detailsModal.componentInstance.item.options.yAxis.labels.formatter = item.options.yAxis.labels.formatter;

    // const options = {...this.chartOptionsPlot};
    // delete options.chart.width;
    // delete options.chart.height;
    // modalRef.componentInstance.chartOptionsPlot = options;
  }

/*  public toManager() {
    window.location.href = this.managerLink;
  }

  public toPaywall() {
    this.router.navigate(['/subscribe']);
  }*/

  public showAchieve(achieve: Achieve) {
    this.achieveModal = this.modalService.open(
      AchieveModalComponent,
      { centered: true, size: 'sm', modalDialogClass: 'modal-lg-md' }
    );
    this.achieveModal.componentInstance.achieve = achieve;

    return false;
  }

  protected readonly install = install;
  protected readonly onscroll = onscroll;

  onScroll(e: any) {
    // console.log(e);
    this.scroll = e.target.scrollLeft
  }

  onScrollButton(step: number) {
    // const childs = this.graphs.nativeElement.childNodes;
    const child = this.graphs.nativeElement.querySelector('.card' + (step < 0 ? ':first-child' : ':last-child'));
    child.scrollIntoView({ behavior: "smooth", block: "start" });
    this.scroll = step;
  }

  onResize() {
    this.scrollWidth = this.graphs.nativeElement.clientWidth;
  }
}
