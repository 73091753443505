<div class="modal-header">
  <button class="btn-close" aria-label="Close" (click)="activeModal.dismiss(0)"></button>
</div>
<div class="modal-body text-center pt-0">
  <canvas riv="training_error" artboard="training_error" class="d-block mw-100 mx-auto mb-4" width="350" height="200">
    <riv-state-machine name="training_error" play></riv-state-machine>
  </canvas>
  <p class="h1 text-center mb-4" translate>There was an error with uploading your recording</p>
  <p class="subheader" translate>Please check your internet connection and continue with your training</p>
  <button class="btn btn-warning w-100" translate (click)="onExit()">Reload</button>
</div>
