<div class="container">

  <button role="button" class="btn btn-secondary btn-close" (click)="activeModal.dismiss('Cross click')"></button>

  <h2 class="mb-4" translate>Questions to the coach</h2>

  <div class="row middle mb-4">
    <div class="col-xl-6 mb-1 mb-xl-0">
      <form [formGroup]="dataForm" (ngSubmit)="onSubmit()" class="position-relative">
        <em class="circle-user"><svg-icon src="/assets/img/icons/user.svg"></svg-icon></em>
        <textarea formControlName="content" class="form-control" placeholder="{{ 'Ask a coach...' | translate }}" [ngClass]="{ 'is-invalid': submitted && f.content.errors }"></textarea>
        <button *ngIf="f.content.value" type="submit" class="btn btn-send">
          <fa-icon [icon]="fasPaperPlane"></fa-icon>
        </button>
      </form>
    </div>
    <div class="col-xl-6">
      <a href="https://www.vocalimage.app/faq" target="_blank" class="btn btn-secondary w-100 btn-text" translate>
        <svg-icon src="/assets/img/icons/right.svg" class="float-end"></svg-icon>
        You are answered by a living person, be patient. Perhaps you will find the answer in the "Questions and Answers"
      </a>
    </div>
  </div>

  <div *ngIf="error" class="alert alert-danger" translate>{{ error | translate }}</div>

  <h4 translate>Comments</h4>

  <div class="list">
    <img width="50" height="50" src="/assets/img/loader.gif" alt="" *ngIf="loadingList"/>

    <div *ngFor="let comment of list; index as i">
      <button class="btn btn-right link-primary float-end" (click)="onDelete(comment, i)"><app-icon icon="delete"/></button>
<!--      <a href="javascript:void(0)" class="float-end del" (click)="onDelete(comment, i)"><svg-icon src="/assets/img/icons/trash.svg"></svg-icon></a>-->
      <p class="subtitle mb-1">{{ comment.created }}</p>
      <pre class="body mine">
        <em class="circle-user"><svg-icon src="/assets/img/icons/user.svg"></svg-icon></em>{{ comment.content }}
      </pre>
      <pre *ngIf="comment.answer" class="body answer">
        <em class="circle-user"><svg-icon src="/assets/img/icons/user.svg"></svg-icon></em>{{ comment.answer }}
      </pre>
    </div>

    <p class="text-center" *ngIf="(!list || !list.length) && !loadingList">{{ 'No questions' | translate }}</p>
  </div>

</div>
