import {Component} from '@angular/core';
import {User} from "../../../_business";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-foot',
  templateUrl: './foot.component.html',
  styleUrls: ['./foot.component.css']
})
export class FootComponent {

  public area: string = null;
  public user: User = null;
  public readonly helpLink = environment.helpLink;

  constructor(
  ) {
  }

  ngOnInit(): void {
  }

}
