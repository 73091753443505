import {ApplicationConfig, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Training, User} from "../../../_business";
import {Location} from "@angular/common";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {masonryOptions} from "../../../_helpers/lib";
import {NgxMasonryComponent} from "ngx-masonry";
import {DataService} from "../../../_services/data.service";
import {TrainingPractice, TrainingRecordings, TrainingSession} from "../../../_business/training-session.model";
import {BehaviorSubject, Subscription} from "rxjs";
import {AuthService} from "../../../_services/auth.service";
import {UploadingErrorModalComponent} from "../uploading-error-modal/uploading-error-modal.component";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import {Router} from "@angular/router";
import {MixpanelService} from "../../../_services/mixpanel.service";

@Component({
  selector: 'app-finish-training',
  templateUrl: './finish-training-modal.component.html',
  styleUrls: ['./finish-training-modal.component.css']
})
export class FinishTrainingModalComponent implements OnInit, OnDestroy {

  @Input() public training: Training;
  @Input() public duration: number;

  @ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent;

  public activeTab: "results" | "audios" = 'results';

  private _trainingSession: TrainingSession = null;
  private _completed: BehaviorSubject<boolean|null> = new BehaviorSubject(null);
  public testResult: any = null;
  public user: User = null;
  private modalUploadError: NgbModalRef;
  public progress = 0;
  private timerProgress: number;

  // lottieOptions: AnimationOptions = {
  //   path: '/assets/lottie/processing_bg.json',
  // };




  // @Input() private _recordings: BehaviorSubject<TrainingRecordings> = new BehaviorSubject(
  //   {
  //     before: null,
  //     after: null
  //   }
  // );
  //
  // public get recordings() : TrainingRecordings {
  //   return this._recordings.value;
  // }
  //
  // public set recordings(recordings: TrainingRecordings) {
  //   this._recordings.next(recordings);
  // }
  //
  // private _recordingsSubscription: Subscription = null;



  _recordings: TrainingRecordings;
  @Input()
  set recordings(recordings: TrainingRecordings) {
    console.log('set recordings', recordings)

    this._recordings = recordings;

    if (
      recordings.uploadedAfter
      && recordings.uploadedBefore
    ) {

      this._saveTrainingSession()
        .then(() => {
          console.log('training session saved', this._trainingSession)
          this.analyze();
        })
        .catch((err) => console.warn('training session save error', err));

      this.cd.detectChanges();

    } else if (recordings.skipped)
      this.testResult = this._getEmptyResult();
  }
  get recordings() { return this._recordings; }



  constructor(
    private auth: AuthService,
    private modalService: NgbModal,
    // public _location: Location,
    private dataService: DataService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private _mixpanelService: MixpanelService,
  ) {
  }

  ngOnInit(): void {
    this.user = this.auth.user;
    // this.user.premium = false;

    this._mixpanelService.track(
      'Training Finished',
      {
        'id' : this.training.id,
        'name' : this.training.name,
        'duration': this.duration,
      }
    );

    // console.log('recordings', this.recordings)

    // this._recordingsSubscription =
    //   this._recordings
    //     .subscribe((recordings) => {
    //       console.log('set recordings', recordings)
    //
    //       if (
    //         recordings.uploadedAfter
    //         && recordings.uploadedBefore
    //       ) {
    //
    //         this._saveTrainingSession()
    //           .then(() => {
    //             console.log('training session saved', this._trainingSession)
    //             this.analyze();
    //           })
    //           .catch((err) => console.warn('training session save error', err));
    //
    //         this.cd.detectChanges();
    //
    //       } else if (recordings.skipped)
    //         this.testResult = this._getEmptyResult();
    //     });

    if (this.recordings.skipped) {
      this.progress = 101;
      return;
    }

    let
      interval = 80, time = interval,
      pauses = 2 + Math.floor(Math.random() * 3),
      pause = 0;

    const startTimer = () => {
      this.timerProgress = setInterval(() => {
        this.progress++;

        if (this.progress > 100) {
          clearInterval(this.timerProgress);
        } else {
          if (!pause && pauses > 0) {
            pause = this.progress + Math.floor(Math.random() * Math.min(99 - this.progress, 30)) - 1;
            // console.log('pause', pause)
            pauses--;
          }

          if (pause && this.progress >= pause) {
            // console.log('paused')
            clearInterval(this.timerProgress);
            pause = 0;
            setTimeout(startTimer, 250 + Math.floor(Math.random() * 750));
          }
        }

        this.cd.detectChanges();
      }, time);
    };
    startTimer();
  }

  ngOnDestroy() {
    // this._recordingsSubscription.unsubscribe();
    this.modalUploadError && this.modalUploadError.dismiss('close');
  }

  private analyze() {
    this.dataService.analyzeTrainingSession(this._trainingSession)
      .subscribe({
        next : (item) => {
          this.testResult = item;
          this.completed = true;
          this._saveRating();
          this.cd.detectChanges();
        },
        error : (err) => {

          this.completed = false;

          console.warn('ViTrainingService Completed', err);

          this.modalUploadError = this.modalService.open(
            UploadingErrorModalComponent,
            {windowClass: 'modal-pop', centered: true, backdrop: 'static'}
          );

          this.modalUploadError.componentInstance.response.subscribe(() => this.analyze());

          this.modalUploadError.result.then((data) => {
            },
            (error) => {
              // on error/dismiss
              if (+error <= 1) {
                this.router.navigate(['/lessons']);
                // this.testResult = this._getEmptyResult();
                // this.cd.detectChanges();
              }
            }
          );

          document.querySelectorAll('.modal-backdrop.fade, .modal-pop.fade').forEach(item => item.classList.add('show'));

        }
      });
  }

  public onFinish() {
    // this.dataService.setTrainingRating(this.training.id, this.rating).subscribe(
    //   (data) => {}
    // );
    // this._location.back();
    this.router.navigate(['/lessons']);

    // const modalRef = this.modalService.open(
    //   FinishModalComponent,
    //   { backdrop: 'static', size: 'fn' }
    // );
    // modalRef.componentInstance.training = this.training;
  }

  protected readonly masonryOptions = masonryOptions;

  onChangeTab(e: any) {
    console.log('onChangeTab', this.activeTab, e);

//     if (e == 'audios') {
//       document.head.insertAdjacentHTML('beforeend',
// `<style id="audiosStyles">
// @media screen and (max-width: 991px) {
//     app-finish-training, .mob-height { height: 100%; display: flex; flex-direction: column; justify-content: space-between; }
// }
// </style>`
//       );
//     } else {
//       document.getElementById('audiosStyles').remove();
//     }

    this.masonry.layout();
    // setTimeout(() => this.masonry.layout(), 500);
  }



  public set completed(completed: boolean|null) {
    this._completed.next(completed);
  }

  private _saveTrainingSession(): Promise<void>
  {

    return new Promise<void>((resolve, reject) => {

      console.warn(this._trainingSession);

      const trainingSession: TrainingSession = {
        id: this._trainingSession?.id,
        training_id : this.training.id,
        training_type: this.training.training_type,
        before_member_audio_id: this.recordings.uploadedBefore?.id,
        after_member_audio_id: this.recordings.uploadedAfter?.id,
      }

      if (this._trainingSession?.training_practice_id)
        trainingSession.training_practice_id = this._trainingSession.training_practice_id;

      if (trainingSession.id) {

        this.dataService.saveTrainingSession(
          trainingSession
        )
          .subscribe({
            next: (trainingSession: TrainingSession) => {
              //console.log('save trainingSession', trainingSession);
              resolve();
            },
            error: (err) => {
              console.log('err _saveTrainingSession', err);
              reject(err);
            }
          });

      } else {

        this.dataService.addTrainingSession(
          trainingSession
        )
          .subscribe({
            next: (trainingSession) => {
              this._trainingSession = trainingSession;
              //console.log('ADD trainingSession', this._trainingSession);
              resolve();
            },
            error: (err) => {
              console.log('err addTrainingSession', err?.error);
              reject(err);
            }
          });

      }

    });

  }

  private _saveRating() {

    let trainingPractice = {
      training_id : this.training.id,
      intensive_id: this.training.intensive_id,
      rating: 0
    } as TrainingPractice;

    this.dataService.saveTrainingRating(
      trainingPractice
    )
      .subscribe({
        next: (trainingPractice: TrainingPractice) => {
          console.log('save rating', trainingPractice);
          this._trainingSession.training_practice_id = trainingPractice.id;
          this._saveTrainingSession()
            .then(() => {
              console.log('training session saved', this._trainingSession);
            })
            .catch((err) => console.warn('training session save error', err));

          // this._stateService.refresh = true;
        },
        error: (err) => console.log('err rating', err)
      });

  }

  private _getEmptyResult() {

    return {
      id: 0,
      female: 0,
      total: 0,
      male: 0,
      range: 0,
      offset: 0,
      average: 0,
      average_delta: 0,
      male_delta: 0,
      female_delta: 0,
      range_delta: 0,
      ai: {
        female: 0,
        confidence: 0,
        male: 0,
        weak: 0,
        monotonous: 0,
        male_delta: 0,
        female_delta: 0,
        confidence_delta: 0,
        weak_delta: 0,
        monotonous_delta: 0,
      },
      pitch: {
        average: 0,
        median: 0,
        high: 0,
        low: 0,
        average_delta: 0,
        median_delta: 0,
        high_delta: 0,
        low_delta: 0,
      },
      volume: {
        average: 0,
        median: 0,
        high: 0,
        low: 0,
        environment: 0,
        average_delta: 0,
        median_delta: 0,
        high_delta: 0,
        low_delta: 0,
        environment_delta: 0,
      },

    };

  }

  onPlayerInit() {
    this.masonry?.layout();
  }
}
