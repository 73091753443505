import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {RouterModule} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from './app-routing.module';
import {LocalizationInterceptor} from './_services/localization.interceptor';
import {StateService} from './_services/state.service';
import {AuthService} from './_services/auth.service';
import {GuestGuardService} from './_services/guest-guard.service';
import {UserInterceptor} from './_helpers/user.interceptor';
import {AuthGuardService} from './_services/auth-guard.service';
import {DataService} from './_services/data.service';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AuthComponent} from './promo/auth/auth.component';
import {HomeComponent} from './logged/home/home.component';
import {ReactiveFormsModule} from '@angular/forms';
import {NotfoundComponent} from './promo/not-found/not-found.component';
import {ConfirmComponent} from './promo/confirm/confirm.component';
import {ReconfirmComponent} from './promo/reconfirm/reconfirm.component';
import {SettingsComponent} from './logged/settings/settings.component';
import {DeleteComponent} from './logged/delete/delete.component';
import {ErrorInterceptor} from './_helpers/error.interceptor';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {environment} from '../environments/environment';
import {TermsComponent} from './promo/terms/terms.component';
import {AudioComponent} from './logged/audio/audio.component';
import {CoachComponent} from './logged/coach/coach.component';
import {StatisticsComponent} from './logged/statistics/statistics.component';
// import {PlyrModule} from 'ngx-plyr';
import {LessonsComponent} from './logged/lessons/lessons.component';
import {LessonComponent} from './logged/lessons/lesson/lesson.component';
import {ToAppComponent} from './promo/to-app/to-app.component';
import {PaywallComponent} from './logged/paywall/paywall.component';
import {PaymentComponent} from './logged/payment/payment.component';
import {CountdownModule} from 'ngx-countdown';
import {TokenComponent} from './promo/token/token.component';
import {UnsubscribeComponent} from './promo/unsubscribe/unsubscribe.component';
import {AudioShareComponent} from './logged/audio-share/audio-share.component';
import {MessagesComponent} from './logged/messages/messages.component';
import {CoursesComponent} from './logged/courses/courses.component';
import {CourseComponent} from './logged/courses/course/course.component';
import {PodcastsComponent} from './logged/podcasts/podcasts.component';
import {PodcastComponent} from './logged/podcasts/podcast/podcast.component';
import {CoursesHeadComponent} from './logged/courses/coursesHead/coursesHead.component';
import {LecturesComponent} from './logged/lectures/lectures.component';
import {LectureComponent} from './logged/lectures/lecture/lecture.component';
import {PlanComponent} from './logged/coach/plan/plan.component';
import {TrainingsComponent} from './logged/parts/trainings/trainings.component';
import {TrainingComponent} from './logged/training/training.component';
import {AudiosComponent} from './logged/parts/audios/audios.component';
import {AudiosModalComponent} from './logged/parts/audios-modal/audios-modal.component';
import {CommentsModalComponent} from './logged/parts/comments-modal/comments-modal.component';
import {FinishModalComponent} from './logged/training/finish-modal/finish-modal.component';
import {GoUnsubscribeComponent} from './promo/go-unsubscribe/go-unsubscribe.component';
import {GoConfirmComponent} from './promo/go-confirm/go-confirm.component';
import {PaywallDetailsComponent} from './logged/paywall/details/paywall-details.component';
import {nl2brPipe} from "./_helpers/nl2br.pipe";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {NgbDropdownModule, NgbNavModule, NgbTooltipConfig, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {PlyrDirective} from "./_helpers/plyr.directive";
import {NgOptimizedImage} from "@angular/common";
import packageJson from "../../package.json";
import {NgxMasonryModule} from "ngx-masonry";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FootLoggedComponent} from "./logged/parts/foot/foot.component";
import {ExploreTabsComponent} from "./logged/parts/explore-tabs/explore-tabs.component";
import {AchieveModalComponent} from "./logged/profile/achieve-modal/achieve-modal.component";
import {ProfileComponent} from "./logged/profile/profile.component";
import {ProgressDetailsModalComponent} from "./logged/profile/progress-details-modal/progress-details-modal.component";
import {HighchartsChartModule} from "highcharts-angular";
import {RIVE_FOLDER, RiveModule} from "ng-rive";
import {TrainingTextComponent} from "./logged/training/training-text/training-text.component";
import {RecordingTipsModalComponent} from "./logged/training/recording-tips-modal/recording-tips-modal.component";
import {SkipVoiceModalComponent} from "./logged/training/skip-voice-modal/skip-voice-modal.component";
import {FinishTrainingModalComponent} from "./logged/training/finish-training-modal/finish-training-modal.component";
import {BadgeDirective} from "./_helpers/badge.directive";
import {AudioTrainingComponent} from "./logged/training/finish-training-modal/audio-training/audio-training.component";
import {PendingChangesGuard} from "./_services/pending-changes.guard";
import {GraphsTooltipComponent} from "./logged/profile/graphs-tooltip/graphs-tooltip.component";
import {HeadComponent} from "./promo/parts/head/head.component";
import {FootComponent} from "./promo/parts/foot/foot.component";
import {DownloadComponent} from "./promo/parts/download/download.component";
import {RegComponent} from "./promo/reg/reg.component";
import {ResetComponent} from "./promo/reset/reset.component";
import {RestoreComponent} from "./promo/restore/restore.component";
import {SidebarComponent} from "./promo/parts/sidebar/sidebar.component";
import {SupportComponent} from "./promo/support/support.component";
import {SpecialComponent} from "./promo/special/special.component";
import {SpecialOfferPageComponent} from "./promo/special-offer-page/special-offer-page.component";
import {InstallModalComponent} from "./logged/parts/install-modal/install-modal.component";
import {VideoModalComponent} from "./logged/parts/video-modal/video-modal.component";
import {IconComponent} from "./promo/parts/icon/icon.component";
import {ConfirmModalComponent} from "./logged/parts/confirm-modal/confirm-modal.component";
import {LoadingComponent} from "./promo/parts/loading/loading.component";
import {TipLoadComponent} from "./logged/parts/tip-load/tip-load.component";
import {UploadingErrorModalComponent} from "./logged/training/uploading-error-modal/uploading-error-modal.component";
import {LottieComponent, provideLottieOptions} from "ngx-lottie";
import {SafePipe} from "./_helpers/safe.pipe";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    '/assets/i18n/',
    '.json?v=' + (environment.production ? packageJson.version : (new Date()).getTime())
  );
}

@NgModule({
  declarations: [
    AppComponent,
    HeadComponent,
    FootComponent,
    FootLoggedComponent,
    DownloadComponent,
    NotfoundComponent,
    HomeComponent,
    AuthComponent,
    RegComponent,
    ConfirmComponent,
    ReconfirmComponent,
    ResetComponent,
    RestoreComponent,
    SidebarComponent,
    SettingsComponent,
    DeleteComponent,
    TermsComponent,
    AudioComponent,
    CoachComponent,
    StatisticsComponent,
    SupportComponent,
    LessonsComponent,
    LessonComponent,
    ToAppComponent,
    PaywallComponent,
    PaymentComponent,
    TokenComponent,
    UnsubscribeComponent,
    AudioShareComponent,
    SpecialComponent,
    SpecialOfferPageComponent,
    MessagesComponent,
    CoursesComponent,
    CourseComponent,
    InstallModalComponent,
    PodcastsComponent,
    PodcastComponent,
    CoursesHeadComponent,
    LecturesComponent,
    LectureComponent,
    PlanComponent,
    AchieveModalComponent,
    VideoModalComponent,
    TrainingsComponent,
    TrainingComponent,
    AudiosComponent,
    AudiosModalComponent,
    CommentsModalComponent,
    FinishModalComponent,
    GoUnsubscribeComponent,
    GoConfirmComponent,
    PaywallDetailsComponent,
    nl2brPipe,
    SafePipe,
    IconComponent,
    ExploreTabsComponent,
    ProfileComponent,
    ProgressDetailsModalComponent,
    TrainingTextComponent,
    RecordingTipsModalComponent,
    SkipVoiceModalComponent,
    FinishTrainingModalComponent,
    BadgeDirective,
    AudioTrainingComponent,
    ConfirmModalComponent,
    GraphsTooltipComponent,
    TipLoadComponent,
    UploadingErrorModalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      // defaultLanguage: 'ru',
      useDefaultLang: false,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularSvgIconModule.forRoot(),
    ReactiveFormsModule,
    // PlyrModule,
    CountdownModule,
    FaIconComponent,
    NgbDropdownModule,
    PlyrDirective,
    NgOptimizedImage,
    LoadingComponent,
    NgxMasonryModule,
    NgbTooltipModule,
    NgbNavModule,
    HighchartsChartModule,
    RiveModule,
    LottieComponent
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: UserInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LocalizationInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    DataService,
    StateService,
    AuthService,
    GuestGuardService,
    AuthGuardService,
    PendingChangesGuard,
    NgbTooltipConfig,
    {
      provide: RIVE_FOLDER,
      useValue: 'assets/rive',
    },
    provideLottieOptions({
      player: () => import('lottie-web'),
    })
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
