import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-courses-head',
  templateUrl: './coursesHead.component.html',
  styleUrls: ['./coursesHead.component.css']
})
export class CoursesHeadComponent implements OnInit{

  @Input() title = 'All';

  constructor(
  ) {
  }

  ngOnInit(): void {
  }


}
