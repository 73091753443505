import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable()
export class GuestGuardService implements CanActivate {

  constructor(
    private router: Router,
    private userService: AuthService
  ) {
  }

  public canActivate() {

    if (!this.userService.user) {
      return true;
    } else {
      this.router.navigate(['/']);

      return false;
    }
  }
}
