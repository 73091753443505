<div class="container">
  <h2>{{ 'Page.NotFound.title' | translate }}</h2>
  <div class="header page-info">
    <p>{{ 'Page.NotFound.text' | translate }}</p>
  </div>

  <div class="form-block">
    <a href="#" (click)="install()" class="btn btn-primary w-100">{{ 'Page.NotFound.button' | translate }}</a>
  </div>
  <br />
<!--  <app-download></app-download>-->
</div>
