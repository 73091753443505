import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {AuthService} from './_services/auth.service';
import {User} from './_business';
import {Subscription} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {LanguageEnum} from './_business/language.enum';
import {environment} from '../environments/environment';
import {NgbModal, NgbTooltipConfig} from "@ng-bootstrap/ng-bootstrap";
import {AppModalComponent} from "./promo/parts/app-modal/app-modal.component";
import {isMobile} from "./_helpers/lib";
import {MixpanelService} from "./_services/mixpanel.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  public title = 'Vocal Image Dash';
  public user: User = null;
  public section: string = null;
  public area: string = null;
  public profile = true;

  private userSubscription: Subscription = null;
  private eventsSubscription: Subscription = null;

  constructor(
    private auth: AuthService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private cdref: ChangeDetectorRef,
    private modalService: NgbModal,
    private tooltipConfig: NgbTooltipConfig,
    // private _mixpanelService: MixpanelService,
  ) {

    // translate.addLangs(['en', 'ru']);
    // translate.setDefaultLang('en');
    // translate.use('ru');

    const browserLang = translate.getBrowserLang();
    const keys = Object.keys(LanguageEnum).join('|');
    const regex = new RegExp(keys);

    translate.use(
      localStorage.getItem('currentLang') || (
        browserLang.match(regex) ? browserLang : environment.defaultLanguage
      )
    );

    // localStorage.setItem('currentLang', translate.currentLang);

    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      localStorage.setItem('currentLang', translate.currentLang);
    });

    tooltipConfig.placement = 'bottom-end';

    if (isMobile())
      tooltipConfig.triggers = 'click';

    // this._mixpanelService.init();
  }

  ngOnInit(): void {
    this.user = this.auth.user;
    this.userSubscription = this.auth.getCurrentUser().subscribe((user: User) => this.user = user);

    this.eventsSubscription = this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (this.route.firstChild.snapshot.data) {
            this.area = this.route.firstChild.snapshot.data.area;
            this.section = this.route.firstChild.snapshot.data.section;
          }
        }
      });

    if (isMobile() && !localStorage.getItem('app-modal')) {
      const modalRef = this.modalService.open(
        AppModalComponent,
        {windowClass: 'modal-pop', centered: true}
      );
    }
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy app');
    this.userSubscription.unsubscribe();
    this.eventsSubscription.unsubscribe();
  }

  onActivate($event) {
    window.scroll(0, 0);
  }
}
