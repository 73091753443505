import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Training, User} from '../../../_business';
import {InstallModalComponent} from '../install-modal/install-modal.component';
import {Router} from '@angular/router';
import {VideoModalComponent} from '../video-modal/video-modal.component';
import {DataService} from '../../../_services/data.service';
import {AudiosModalComponent} from '../audios-modal/audios-modal.component';
import {CommentsModalComponent} from '../comments-modal/comments-modal.component';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import {faHeart} from "@fortawesome/free-regular-svg-icons";
import {faHeart as fasHeart} from "@fortawesome/free-solid-svg-icons";
import {fullscreenOptions, masonryOptions} from "../../../_helpers/lib";
import {AuthService} from "../../../_services/auth.service";

@Component({
  selector: 'app-trainings',
  templateUrl: './trainings.component.html',
  styleUrls: ['./trainings.component.css']
})
export class TrainingsComponent implements OnInit, OnDestroy {

  @Input() public list: Training[];
  @Output() response = new EventEmitter();

  public user: User = null;

  private modalRefComments: NgbModalRef;
  private modalRefAudios: NgbModalRef;

  protected readonly farHeart = faHeart;
  protected readonly fasHeart = fasHeart;

  constructor(
    private auth: AuthService,
    private router: Router,
    private modalService: NgbModal,
    private dataService: DataService,
    // private renderer: Renderer2,
  ) {
  }

  ngOnInit(): void {
    this.user = this.auth.user;
  }

  ngOnDestroy(): void {
    console.log('destroy');

    if (this.modalRefComments)
      this.modalRefComments.dismiss('close');

    if (this.modalRefAudios)
      this.modalRefAudios.dismiss('close');
  }

  public onFavourite(training: Training) {
    // this.toIntall();

    training.favorited = !training.favorited;

    this.response && this.response.next(training.favorited);

    if (training.favorited)
      this.dataService.addToFavourites(training.id)
        .subscribe({
          next: (res:any) => {
            console.log('added to fav')
          },
          error: err => console.log('counter err', err)
        });
    else
      this.dataService.deleteFromFavourites(training.id)
        .subscribe({
          next: (res:any) => {
            console.log('deleted from fav')
          },
          error: err => console.log('counter err', err)
        });
  }

  public onHoverTraining(training: Training) {
    if (training.paid)
      this.router.navigate(['/subscribe']);
    else
      // training.hovered = true;
    this.router.navigate(['/training', training.id]);
  }

  public toIntall() {
    // this.router.navigate(['/install']);
    const modalRef = this.modalService.open(
      InstallModalComponent,
      { centered: true }
    );
  }

  public onWatchTraining(training: Training) {
    if (training.movieUrl)
      this.onShowMoviePop(training);
    else
      this.dataService.getVideo(training.video_url).subscribe(
        (data) => {
          training.movieUrl = data.url;
          this.onShowMoviePop(training);
        }
      );
  }

  public onShowMoviePop(training: Training) {
    const modalRef = this.modalService.open(
      VideoModalComponent,
      fullscreenOptions
      // { backdrop: 'static', size: 'lg', centered: true }
    );
    modalRef.componentInstance.movieUrl = training.movieUrl;
    modalRef.componentInstance.name = training.name;
    modalRef.componentInstance.poster = training.images.main.url;
  }

  public onShowAudios(training: Training) {
    this.modalRefAudios = this.modalService.open(
      AudiosModalComponent,
      fullscreenOptions
      // { container: '.main', backdrop: false, windowClass: 'full' }
    );
    // this.showModalFull(this.modalRefAudios);
    this.modalRefAudios.componentInstance.title = training.name;

    this.dataService.getTrainingAudios(training.id).subscribe(
      (data) => {
        this.modalRefAudios.componentInstance.list = data;
      }
    ).add(() => this.modalRefAudios.componentInstance.loading = false);
  }

  public onShowComments(training: Training) {
    this.modalRefComments = this.modalService.open(
      CommentsModalComponent,
      fullscreenOptions
      // { container: '.main', backdrop: false, windowClass: 'full' }
    );
    this.modalRefComments.componentInstance.training = training;
    // this.showModalFull(this.modalRefComments);
  }

  // private showModalFull(modalRef: NgbModalRef) {
  //   this.renderer.removeClass(document.body, 'modal-open');
  //   this.renderer.addClass(document.body, 'modal-open-full');
  //
  //   modalRef.result.then((data) => {
  //       this.renderer.removeClass(document.body, 'modal-open-full');
  //     },
  //     (error) => {
  //       this.renderer.removeClass(document.body, 'modal-open-full');
  //     });
  // }

  protected readonly masonryOptions = masonryOptions;
}
