<div class="container with-tags">

<!--  <nav>
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home">{{ 'Home' | translate }}</a></li>
      <li class="breadcrumb-item active">{{ 'Page.Trainings.title' | translate }}</li>
    </ol>
  </nav>-->

<!--  <div class="float-end">
    <a href="javascript:void(0)" (click)="toIntall()" class="btn btn-secondary me-4">
      <fa-icon class="me-md-2" [icon]="fasHistory"></fa-icon>
      <span class="d-none d-md-inline">{{ 'Page.Trainings.history' | translate }}</span>
    </a>

    <a href="javascript:void(0)" (click)="toIntall()" class="btn btn-secondary">
      <fa-icon class="me-md-2" [icon]="fasHeart"></fa-icon>
      <span class="d-none d-md-inline">{{ 'Page.Trainings.favourite' | translate }}</span>
    </a>
  </div>-->

<!--  <button class="btn btn-sm question subtitle" ngbTooltip="{{'Page.Trainings.tooltip'|translate}}">
    <app-icon icon="question" size="md"/>
  </button>-->

  <app-tip-load code="trainings"/>

  <h1 class="d-none d-lg-block">{{ 'Page.Trainings.title' | translate }}</h1>

  <app-explore-tabs class="d-lg-none"/>

  <div *ngIf="!loading" class="d-lg-flex gap-lg-2 mb-lg-4 tags scrollable">
    <button *ngIf="user.premium && countFavorites" class="btn btn-sm text-nowrap fw-normal" [ngClass]="{'btn-light': selectedTagId == -1, 'btn-secondary': selectedTagId != -1}"
            (click)="onSelectTag(-1)"
    >
      {{ 'Favorites' | translate }}
    </button>
    <button *ngFor="let tag of tags" class="btn btn-sm text-nowrap fw-normal" [ngClass]="{'btn-light': selectedTagId == tag.id, 'btn-secondary': selectedTagId != tag.id}"
            (click)="onSelectTag(tag.id)"
    >
      {{ toFirstUp(tag.name) }}
    </button>
  </div>

<!--  <nav class="navbar navbar-expand-lg tags mb-4" ngbDropdown>
    <div class="navbar-toggler p-0 w-100">
      <button class="btn btn-secondary w-100 text-start" id="dropdownTags" ngbDropdownToggle>
        {{selectedTag ? selectedTag.name : 'Page.Trainings.all' | translate }}
      </button>
    </div>
    <div class="collapse navbar-collapse w-100" ngbDropdownMenu aria-labelledby="dropdownTags">
      <ul class="navbar-nav">
&lt;!&ndash;        <li class="nav-item">
          <a class="nav-link" routerLink="/lessons" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="onSelectTag()">{{ 'Page.Trainings.all' | translate }}</a>
        </li>&ndash;&gt;

        <li class="nav-item" *ngFor="let tag of tags">
          <a routerLink="/lessons" [queryParams]="{ tag: tag.id }" [ngClass]="tag.id == selectedTagId ? 'active' : null" ngbDropdownItem
             (click)="onSelectTag(tag.id)">{{ tag.name }}</a>
        </li>
      </ul>
    </div>
  </nav>-->

  <app-loading *ngIf="loading"/>

  <app-trainings [list]="dataList" (response)="onFavorite($event)"></app-trainings>

  <div *ngIf="error" class="alert alert-danger">{{ error | translate }}</div>
</div>
