<app-head class="head" *ngIf="!user"></app-head>

<div class="content" [ngClass]="{ 'logged': user }">
  <app-sidebar class="sidebar d-none d-lg-block"></app-sidebar>
<!--  <app-profile class="profile" *ngIf="user" [ngClass]="{ 'active': profile }" (response)="profile = $event"></app-profile>-->

  <main class="main">
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </main>
</div>

<app-foot *ngIf="!user" class="d-lg-none"></app-foot>
<app-foot-logged *ngIf="user" class="d-lg-none"></app-foot-logged>

<svg-icon src="/assets/img/icons/sprite.svg" class="d-none"></svg-icon>
