import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {Intensive} from '../../_business';
import {DataService} from '../../_services/data.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {

  private lastScroll = 0;

  public loading = true;
  public error;
  public intensives: Intensive[] = [];
  public selectedIntensive: Intensive = null;
  public id: number;

  constructor(
    private dataService: DataService,
    private titleService: Title,
    private metaService: Meta,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private _location: Location,
  ) {
    this.translate.get(['Training programs']).subscribe(
      res => {
        console.log(res);
        this.titleService.setTitle(res['Training programs']);
      }
    );
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.id = +params.id;
    });

    if (this.id) {
      this.dataService.getIntensiveById(this.id).subscribe(
        (data) => {
          this.selectedIntensive = data;
        }
      )
        .add(() => this.loading = false);
    } else {
      this.dataService.getIntensives().subscribe(
        (data) => {
          this.intensives = data;
        }
      )
        .add(() => this.loading = false);
    }
  }

  public onShowPop(intensive: Intensive) {
    if (intensive.paid)
      this.router.navigate(['/subscribe']);
    else {
      this.selectedIntensive = intensive;
      this.lastScroll = window.pageYOffset;
      window.scroll(0,0);
    }
  }

  public onClosePop() {
    if (this.id)
      this._location.back();
    else {
      this.selectedIntensive = null;
      window.scroll(0, this.lastScroll);
    }
  }

}
