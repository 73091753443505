<div class="container">
<!--  <img *ngIf="loading && !error" width="50" height="50" src="/assets/img/loader.gif" alt=""/>-->

  <h2>{{ 'Page.Settings.delete_account' | translate }}</h2>

  <p>{{ 'Page.Settings.delete_account_text' | translate }}</p>
  <p>- {{ 'Page.Settings.delete_account_point_1' | translate }}</p>
  <p>- {{ 'Page.Settings.delete_account_point_2' | translate }}</p>
  <p> {{ 'Page.Settings.delete_account_warning' | translate }}</p>

  <p class="mt-5">
    <a href="javascript:void(0)" class="btn btn-light" (click)="onDelete()">
      <svg-icon src="/assets/img/icons/delete.svg" class="i"></svg-icon>
      {{ 'Page.Settings.delete_account' | translate }}
    </a>
  </p>

  <div *ngIf="error" class="alert alert-danger mt-4">{{ error | translate }}</div>

</div>
