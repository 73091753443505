import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {Course, Training, User} from 'src/app/_business';
import {DataService} from 'src/app/_services/data.service';

import {InstallModalComponent} from '../../parts/install-modal/install-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {VideoModalComponent} from '../../parts/video-modal/video-modal.component';
import {confirmOptions, fullscreenOptions, masonryOptions} from "../../../_helpers/lib";
import {ConfirmModalComponent} from "../../parts/confirm-modal/confirm-modal.component";
import {Router} from "@angular/router";
import {AuthService} from "../../../_services/auth.service";
import {MixpanelService} from "../../../_services/mixpanel.service";

@Component({
  selector: 'app-lecture',
  templateUrl: './lecture.component.html',
  styleUrls: ['./lecture.component.css']
})
export class LectureComponent implements OnInit {

  @Input() course: Course = null;
  @Output() response = new EventEmitter();

  public movieUrl: string;
  public activeTab: "talks" | "overview" = 'talks';

  public user: User = null;

  constructor(
    private dataService: DataService,
    private titleService: Title,
    private metaService: Meta,
    private modalService: NgbModal,
    private router: Router,
    private auth: AuthService,
    private _mixpanelService: MixpanelService,
  ) {
  }

  ngOnInit(): void {
    this.user = this.auth.user;
    this.titleService.setTitle(this.course.name);

    this.metaService.addTags([
      {name: 'description', content: this.course.short_description},
    ]);

    this._mixpanelService.track(
      'Lecture Opened',
      {
        'id' : this.course.id,
        'name' : this.course.name,
      }
    );

    if (this.user.premium)
      this.dataService.getCourseVideo(this.course.id).subscribe(
        (data) => {
          this.movieUrl = data.url;
        }
      );

    this.dataService.getCourseTrainings(this.course.id).subscribe(
      (data) => {
        this.course.trainings = data;

        // for (const train of this.course.trainings)
        //   train.paid = true;
      }
    );
  }

  public onSelectTraining(training: Training, index: number = null) {
    if (training.paid) {
      this.router.navigate(['/subscribe']);
      // const modalRef = this.modalService.open(
      //   InstallModalComponent,
      //   {centered: true}
      // );
    } else {
      if (training.movieUrl)
        this.onShowMoviePop(training, index);
      else
        this.dataService.getVideo(training.video_url).subscribe(
          (data) => {
            training.movieUrl = data.url;
            this.onShowMoviePop(training, index);
          }
        );
    }
  }

  public onShowMoviePop(training?: Training, index?: number) {
    if (
      !training
      // && !this.movieUrl
    ) {
      let ind = this.course.trainings.findIndex(({done}) => !done);

      if (ind < 0 && this.course.trainings?.length)
        ind = 0;

      if (ind >= 0) {
        this.onSelectTraining(this.course.trainings[ind], ind);
        return;
      }
    }

    const modalRef = this.modalService.open(
      VideoModalComponent,
      fullscreenOptions
      // { backdrop: 'static', size: 'lg', centered: true }
    );

    modalRef.componentInstance.movieUrl = training?.movieUrl || this.movieUrl;
    modalRef.componentInstance.name = training?.name || this.course.name;
    modalRef.componentInstance.poster = training?.images.main.url || this.course.images.video?.url;

    if (index === this.course.trainings?.length - 1)
      modalRef.componentInstance.onEndResponse.subscribe((e: any) => {
        this._mixpanelService.track(
          'Lecture Finished',
          {
            'id' : this.course.id,
            'name' : this.course.name,
            'duration': e.detail.plyr.duration,
          }
        );
      });

    if (training)
      modalRef.componentInstance.response.subscribe(($e) => {
        if (index < this.course.trainings.length - 1) {
          const modalConfirmRef = this.modalService.open(
            ConfirmModalComponent,
            // confirmOptions
            { size: 'sm', centered: true, backdrop: 'static' }
          );
          modalConfirmRef.componentInstance.message = 'Would you like to move on to the next lecture?';
          modalConfirmRef.componentInstance.response.subscribe(($e) => {
            this.onSelectTraining(this.course.trainings[index + 1], index + 1);
          });
        }
      });
  }

  protected readonly masonryOptions = masonryOptions;
}
