import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {install} from "../../_helpers/lib";

@Component({
  selector: 'app-to-app',
  templateUrl: './to-app.component.html',
  styleUrls: ['./to-app.component.css']
})
export class ToAppComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    private titleService: Title,
    private metaService: Meta,
  ) {
    this.setMeta();
  }

  private setMeta() {

    this.translate.get('ToApp.title').subscribe(
      (res: string) => {
        this.titleService.setTitle(res);
      }
    );

    this.translate.get('ToApp.text').subscribe(
      (res: string) => {
        this.metaService.addTags([
          {name: 'description', content: res},
        ]);
      }
    );

  }

  ngOnInit(): void {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setMeta();
    });

  }

  protected readonly install = install;
}
