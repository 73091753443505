import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../_services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {MixpanelService} from "../../_services/mixpanel.service";

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.css']
})
export class DeleteComponent implements OnInit {

  error = '';
  confirmTxt = '';

  constructor(
    public translate: TranslateService,
    private auth: AuthService,
    private router: Router,
    private _mixpanelService: MixpanelService,
  ) {
    this.translate.get(['Settings.delete_account_account_confirm']).subscribe(
      res => {
        this.confirmTxt = res['Settings.delete_account_account_confirm'];
      }
    );
  }

  ngOnInit(): void {
  }

  onDelete() {
    if (window.confirm(this.confirmTxt)) {
      this.auth
        .delete().subscribe({
          next: () => {
            this.auth.logout();
            this.router.navigate(['/auth']);
          },
          error: err => {
            this.error = err.error;
          }
        });

      this._mixpanelService.track('Account Deleted');
    }
  }
}
