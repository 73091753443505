import {Component, OnInit} from '@angular/core';
import {DataService} from '../../_services/data.service';
import {Statistics} from '../../_business/statistics.model';
import {TranslateService} from '@ngx-translate/core';

import Highcharts from 'highcharts/es-modules/masters/highcharts.src';
// import * as Highcharts from 'highcharts';
// import Exporting from 'highcharts/modules/exporting';
// import variablePie from 'highcharts/modules/variable-pie';

// Exporting(Highcharts);
// variablePie(Highcharts);

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {

  private translations;
  private Highcharts: typeof Highcharts = Highcharts;

  public loading = true;
  public error: string;
  public bars: Statistics[];
  public totalExperience = 0;

  public chartOptionsPie: Highcharts.Options = {
    chart: {
      width: 340,
      backgroundColor: 'transparent',
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false
    },
    credits: {
      enabled: false
    },
    title: {
      align: 'center',
      verticalAlign: 'middle',
      y: 60,
      style: { color: '#7F8EA3' }
    },
    // tooltip: {
    //   pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    // },
    // accessibility: {
    //   point: {
    //     valueSuffix: '%'
    //   }
    // },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false
        },
        borderWidth: 3,
        borderColor: '#0a062e',
        colors: [ '#ff855f', '#aaff8b', '#f3afe6', '#9d51ff', '#fffa78', '#6dbff1', '#263560' ]
      }
    },
    series: [{
      type: 'pie',
      innerSize: '83%',
      data: []
    }]
  };

  constructor(
    private dataService: DataService,
    private translate: TranslateService,
  ) {
    this.translate.get(['Прогресс', 'Опыт', 'Осталось']).subscribe(translations => {
      console.log(translations);
      this.translations = translations;
    });
  }

  ngOnInit(): void {
    this.dataService.getStatisticBars().subscribe(
      (data: Statistics[]) => {
        this.bars = data;
        // this.totalExperience = data.length * 100;

        let amount = 0;
        const series = this.chartOptionsPie.series[0] as any;

        for (const obj of data) {
          this.totalExperience += +obj.total;
          amount += +obj.points;
          series.data.push(
            [ obj.name, +obj.points ]
          );
        }

        series.data.push({
          name: this.translations.Осталось,
          className: 'other',
          y: (this.totalExperience - amount)
        });

        series.name = this.translations.Прогресс;
        this.chartOptionsPie.title.text =
          '<tspan style="font-size: 64px; color: #fff;">' + amount + '</tspan><br>' +
          '<tspan dy="10px">' + this.translations.Опыт + '</tspan>';

        const chart = Highcharts.chart('pie', this.chartOptionsPie);
        this.loading = false;
      },
      err => {
        this.error = err.error;
        this.loading = false;
      }
    );
  }
}
