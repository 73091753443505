<div class="container">

  <button role="button" class="btn btn-lg btn-close" (click)="response.emit('close')"></button>

  <h1 class="d-none d-lg-block">{{ course.name }}</h1>

  <div class="card w-100 mb-lg-6">
    <div class="card-body page">
      <div class="row">
        <div class="col-xl-4 text-center">
          <img class="card-img" [attr.src]="course.images.main.url" alt="" width="570" height="320" />
          <h2 class="h1 mb-2 d-lg-none">{{ course.name }}</h2>
          <p class="subheader small">
            <app-icon icon="papers" class="i"/>{{
              course.training_count
                | i18nPlural: {'=0': 'Params.lectures.zero', '=1': 'Params.lectures.one', 'few': 'Params.lectures.few', 'other': 'Params.lectures.other'}
                | translate:{number: course.training_count}
            }}
          </p>
          <p class="mb-4 mb-xl-0">
            <button *ngIf="user.premium" class="btn btn-primary w-100" (click)="onShowMoviePop()">
              {{ 'Continue' | translate }}
            </button>
            <button *ngIf="!user.premium" routerLink="/subscribe" class="btn btn-premium w-100" translate>Start</button>
          </p>

          <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs mb-4 d-lg-none">
            <li ngbNavItem [ngbNavItem]="'talks'">
              <button ngbNavLink translate>Talks</button>
<!--              <ng-template ngbNavContent>First content</ng-template>-->
            </li>
            <li ngbNavItem [ngbNavItem]="'overview'">
              <button ngbNavLink translate>Overview</button>
<!--              <ng-template ngbNavContent>Second content</ng-template>-->
            </li>
          </ul>
<!--          <div [ngbNavOutlet]="nav"></div>-->
        </div>
        <div class="col-xl-8" [ngClass]="{'d-none d-lg-block': activeTab != 'overview'}">
          <h2 class="d-none d-lg-block" translate>Overview</h2>
          <pre class="p-0 mb-0 small subheader">{{ course.description }}</pre>
        </div>
      </div>
    </div>
  </div>

  <ngx-masonry [options]="masonryOptions" ordered="true" [ngClass]="{'d-none d-lg-block': activeTab != 'talks'}">
    <div *ngFor="let item of course.trainings; let i = index" ngxMasonryItem class="card grid-item" [ngClass]="{'paid': item.paid}" role="button" (click)="onSelectTraining(item, i)">
      <div class="card-title">
        <h2 class="overflow">{{ item.name }}</h2>
      </div>
      <button *ngIf="item.done" class="btn btn-xss btn-success card-sign" translate>Done</button>
      <button *ngIf="item.paid" routerLink="/subscribe" class="btn btn-xss btn-premium card-sign" translate>Premium</button>
      <div class="card-body pt-0">
        <img class="card-img" [attr.src]="item.images.main.url" alt="" width="570" height="320" />
        <p class="card-text subheader">{{ item.short_description }}</p>
        <button class="btn btn-md w-100" [ngClass]="{'btn-primary': !item.paid, 'btn-default subheader fw-normal': item.paid}">
<!--          <app-icon *ngIf="item.paid" icon="lock" class="i"></app-icon>-->
          {{ 'Watch Video' | translate }}
        </button>
      </div>
    </div>
  </ngx-masonry>

</div>
