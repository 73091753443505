<div class="container">
  <p class="mb-4">
    <small class="under">
      <span class="subtitle">{{ 'I already have an account?' | translate }}</span>&nbsp;
      <a routerLink="/auth">{{ 'Log in' | translate }}</a>
    </small>
  </p>

  <div class="media mb-3">
    <svg-icon src="/assets/img/icons/user.svg" class="i me-3" style="width: 48px; height: 48px;" [svgStyle]="{ 'width.px':48, 'height.px':48, 'fill': '#4d5ebf' }"></svg-icon>
    <div class="media-body">
      <h1 class="mb-0">{{ 'Sign Up' | translate }}</h1>
      <small>{{ "to get a personal training plan" | translate }}</small>
    </div>
  </div>

  <div class="row">
    <div class="col-lg form-block">
      <form [formGroup]="dataForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <input type="email" formControlName="email" maxlength="64" class="form-control" placeholder="E-mail" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">{{ 'Validator.require' | translate }}</div>
            <div *ngIf="f.email.errors.email">{{ 'Check the spelling of your email address' | translate }}</div>
          </div>
        </div>

        <div class="form-group position-relative">
          <svg-icon src="/assets/img/icons/eye.svg" class="eye" (click)="onViewPassword()"></svg-icon>
          <input [attr.type]="passwordType" formControlName="password" maxlength="64" class="form-control" placeholder="{{ 'New password' | translate }}" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">{{ 'Validator.require' | translate }}</div>
            <div *ngIf="f.password.errors.pattern">{{ 'Validator.passwordLatin' | translate }}</div>
            <div *ngIf="f.password.errors.minlength">{{ 'Validator.passwordMin' | translate }}</div>
          </div>
        </div>

        <div class="form-group position-relative">
          <svg-icon src="/assets/img/icons/eye.svg" class="eye" (click)="onViewPassword()"></svg-icon>
          <input [attr.type]="passwordType" formControlName="confirm" class="form-control" placeholder="{{ 'Password confirmation' | translate }}" [ngClass]="{ 'is-invalid': submitted && f.confirm.errors }" />
          <div *ngIf="submitted && f.confirm.errors" class="invalid-feedback">
            <div *ngIf="f.confirm.errors.required">{{ 'Validator.require' | translate }}</div>
            <div *ngIf="f.confirm.errors.matchPassword">{{ 'Validator.passwordMatch' | translate }}</div>
          </div>
        </div>

        <p class="mb-2">
          <button [disabled]="loading" type="submit" class="btn btn-primary w-100">
            {{ 'Sign Up' | translate }}
            <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
        </p>

        <small>
          <span class="subtitle">{{ 'By signing up to the application, you accept' | translate }}</span><br />
          <a href="https://www.vocalimage.app/terms" target="_blank" class="link subheader">{{ 'The terms and conditions agreement' | translate }}</a>
        </small>
      </form>

      <div *ngIf="error" class="alert alert-danger">{{ error | translate }}</div>
    </div>

    <div class="col-lg text-center mb-6 mb-lg-0 delimiter">
      <div class="d-flex h-100 flex-column">
        <div class="line"></div>
        <div class="or note">{{ 'or' | translate }}</div>
        <div class="line"></div>
      </div>
    </div>

    <div class="col-lg form-block">
      <div class="form-group">
        <a href="javascript:void(0)" class="btn btn-secondary w-100" (click)="goto('apple')">
          <img src="/assets/img/icons/apple.svg" alt="" width="14" height="16" class="i" style="position: relative; top: -2px;" />
          {{ 'Continue with Apple' | translate }}
          <span *ngIf="provider == 'apple'" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </a>
      </div>

      <div class="form-group">
        <a href="javascript:void(0)" class="btn btn-light w-100" (click)="goto('google')">
          <img src="/assets/img/icons/google.svg" alt="" width="16" height="16" class="i" />
          {{ 'Continue with Google' | translate }}
          <span *ngIf="provider == 'google'" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </a>
      </div>
    </div>
  </div>
</div>
