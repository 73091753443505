import {Component, Input, OnInit} from '@angular/core';
import {Audio} from '../../../_business';
import {ConfirmModalComponent} from "../confirm-modal/confirm-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DataService} from "../../../_services/data.service";

@Component({
  selector: 'app-audios',
  templateUrl: './audios.component.html',
  styleUrls: ['./audios.component.css']
})
export class AudiosComponent implements OnInit {

  @Input() public list: Audio[];
  @Input() public loading: boolean;
  @Input() public title: string;

  constructor(
    private modalService: NgbModal,
    private dataService: DataService,
  ) {
  }

  ngOnInit(): void {
  }

  onRemove(audio: Audio, index: number) {
    const modalConfirmRef = this.modalService.open(
      ConfirmModalComponent,
      // confirmOptions
      { size: 'sm', centered: true, backdrop: 'static' }
    );
    modalConfirmRef.componentInstance.type = 'danger';
    modalConfirmRef.componentInstance.icon = 'delete-open';
    modalConfirmRef.componentInstance.ok = 'Delete';
    modalConfirmRef.componentInstance.message = 'Do you really want to delete the audio?';
    modalConfirmRef.componentInstance.response.subscribe(($e) => {
      this.dataService.deleteAudio(audio).subscribe(
        () => {
          this.list.splice(index, 1);
        }
      );
    });
  }
}
