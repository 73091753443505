import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../_business';
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly userKeyName: string = 'user';
  private _user: BehaviorSubject<User> = new BehaviorSubject(null);
  private readonly API_SERVER: string = environment.api;
  protected _headers: HttpHeaders = null;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
  ) {
    this._restore();
  }

  private _restore() {
    const jsonedUser: string = localStorage.getItem(this.userKeyName);

    let user = null;

    if (jsonedUser) {
      user = JSON.parse(jsonedUser) as User;

      // user.premium = false;
    }

    this.user = user;
  }

  public get user() {
    return this._user.value;
  }

  public set user(user: User) {
    this._user.next(user);
    localStorage.setItem(this.userKeyName, JSON.stringify(user));
  }

  public getCurrentUser(): Observable<User> {
    return this._user.asObservable();
  }

  public confirm(id: string, code: string): Observable<any> {
    return this.httpClient.post<any>(this.API_SERVER + 'members/' + id + '/confirm', {
      code
    });
  }

  public goConfirm(uid: string): Observable<any> {
    return this.httpClient.get<any>(this.API_SERVER + 'web-members/confirm/' + uid);
  }

  public reset(id: string, password: string, code: string): Observable<any> {
    return this.httpClient.post<any>(this.API_SERVER + 'members/' + id + '/reset', {
      code,
      password,
    });
  }

  public unsubscribeEmail(id: string, code: string): Observable<any> {
    return this.httpClient.post<any>(this.API_SERVER + 'members/' + id + '/unsubscribe', {
      code
    });
  }

  public goUnsubscribeEmail(uid: string): Observable<any> {
    return this.httpClient.get<any>(this.API_SERVER + 'web-members/unsubscribe/' + uid);
  }

  public checkPremium(): Observable<any> {
    return this.httpClient.get<any>(this.API_SERVER + 'members/premium');
  }

  public reconfirm(email: string): Observable<any> {
    return this.httpClient.post<any>(this.API_SERVER + 'members/reconfirm', {
      email,
    });
  }

  public restore(email: string): Observable<any> {
    return this.httpClient.post<any>(this.API_SERVER + 'members/restore', {
        login: email,
    });
  }

  public signin(user: User) {
    return this.httpClient.post<User>(
      this.API_SERVER + 'members/signin',
      user,
      {headers: this._headers, observe: 'response'},
    ).pipe(map((res: HttpResponse<any>) => {
      const user = res.body as User;
      user.token = res.headers.get('X-Token');
      this.user = user;
      this.router.navigate([this.user.premium ? '/lessons' : '/subscribe']);
    }));
  }

  public signup(user: User) {
    return this.httpClient.put<User>(
      this.API_SERVER + 'members/',
      user,
      {headers: this._headers, observe: 'response'},
    ).pipe(map((res: HttpResponse<any>) => {
      this.signin(user).subscribe();
      // const user = res.body as User;
      // user.token = res.headers.get('X-Token');
      // this.user = user;
      // this.router.navigate([this.user.premium ? '/home' : '/subscribe']);
    }));
  }

  public oauth(provider: string) {
    return this.httpClient.get<any>(
      this.API_SERVER + 'members/oauth/' + provider
    ).pipe(map((res) => {
      // res.token
      location.href = res.url
    }));
  }

  public tokenAuth(token: string) {
    return this.httpClient.post<User>(
      this.API_SERVER + 'members/oauth/in/', {
      token },
      {headers: this._headers, observe: 'response'},
    ).pipe(map((res: HttpResponse<any>) => {
      const user = res.body as User;
      user.token = res.headers.get('X-Token');
      this.user = user;
      this.router.navigate([this.user.premium ? '/lessons' : '/subscribe']);
    }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(this.userKeyName);
    this._user.next(null);
  }

  public delete() {
    return this.httpClient.post<any>(
      this.API_SERVER + 'members/unsign', {}
    );
  }

}
