<app-tip-load [code]="item ? item.hint : 'analysys'" [placement]="item ? 'bottom-end' : 'auto'">
  <div class="my-5 position-relative">

    <div style="border-radius: 8px; width: 4px; height: 100%; position: absolute; left: 0; top: 0; background: linear-gradient(180deg, #46ECA6 0%, #46ECA6 26.56%, #F9DE69 36.46%, #F9DE69 64.58%, #E26564 76.56%, #E26564 100%);"></div>
    <strong style="position: absolute; top: -10px; left: 12px;">100%</strong>

    <div *ngFor="let level of translates?.['Page.Profile.graphs_tooltip.levels']; index as i" class="d-flex align-items-center gap-4">
      <div class="position-relative">
        <strong style="position: absolute; bottom: -10px; left: 12px;">{{toFloor(i)}}%</strong>
        <svg width="100" height="68" viewBox="0 0 100 68" xmlns="http://www.w3.org/2000/svg" [ngClass]="'text-' + levelColors[i]">
          <path d="M0 0H18.771C36.2243 0 53.1548 5.95748 66.7622 16.8871L75.6285 24.0085C81.8936 29.0408 89.533 32.0626 97.5452 32.6781L98.87 32.7799C99.5076 32.8288 100 33.3605 100 34C100 34.6395 99.5076 35.1712 98.87 35.2201L97.5452 35.3219C89.533 35.9374 81.8936 38.9592 75.6285 43.9914L66.7622 51.1129C53.1548 62.0425 36.2243 68 18.771 68H0V0Z" [attr.fill]="'url(#paint_' + i + ')'"/>
          <defs>
            <linearGradient [attr.id]="'paint_' + i" x1="0" y1="34" x2="100" y2="34" gradientUnits="userSpaceOnUse">
              <stop stop-color="#2B3242" stop-opacity="0"/>
              <stop offset="1" stop-color="currentColor" stop-opacity="0.4"/>
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div>
        <p class="mb-1 fw-bold" [ngClass]="'text-' + levelColors[i]">{{level[0]}}</p>
        <small class="note subheader" style="line-height: 16px;">{{level[1]}}</small>
      </div>
    </div>

  </div>

  <p class="mb-0" [innerHTML]="'Page.Profile.graphs_tooltip.bottom'|translate|nl2br"></p>
</app-tip-load>
